// Libraries
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
// Models
import { IParamsDownloadPdf } from '@models/DownloadPdf';
// Constants
import { CustomStyles } from '@constants/PrintPdf';

/**
 * These hooks are used to download a pdf file.
 */
export const usePrintPdf = (): {
    downloadPDF: (params: {
        html?: string;
        title: string;
        header: string[][];
        body: string[][];
        hasUserInfo?: Array<[string, string]>;
    }) => void;
    downloadBase64: (base: string, title: string) => void;
    downloadPDFMultipleTables: (title: string, data: Omit<IParamsDownloadPdf, 'title'>[]) => void;
} => {
    /**
     * Action to download a pdf file.
     */
    const downloadPDF = (params: IParamsDownloadPdf): void => {
        const size = 'A4';
        const doc = new jsPDF('landscape', 'pt', size);

        if (params.hasUserInfo) {
            autoTable(doc, {
                theme: 'plain',
                body: params.hasUserInfo,
                tableWidth: 'wrap',
                styles: { cellPadding: 0.5, fontSize: 8 },
                columnStyles: {
                    [0]: { fontStyle: 'bold' },
                    [1]: { halign: 'right' },
                },
            });
        }

        autoTable(doc, {
            ...CustomStyles,
            head: params.header,
            body: params.body,
        });

        doc.save(`${params.title}.pdf`);
    };

    const downloadPDFMultipleTables = (title: string, data: Omit<IParamsDownloadPdf, 'title'>[]): void => {
        const size = 'A4';
        const doc = new jsPDF('landscape', 'pt', size);

        data.forEach(item => {
            autoTable(doc, {
                ...CustomStyles,
                head: item.header,
                body: item.body,
            });
        });

        doc.save(`${title}.pdf`);
    };

    /**
     * Action to download pdf from base64
     */
    const downloadBase64 = (base: string, title: string): void => {
        const linkSource = `data:application/pdf;base64,${base}`;
        const downloadLink = document.createElement('a');
        const fileName = `${title}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };
    return { downloadPDF, downloadBase64, downloadPDFMultipleTables };
};
