// Libraries
import React from 'react';
// Components
import { Icon } from '@components/icon';
import { CheckBox } from '@components/input';
// Models
import { IItemEmployee } from '@models/Employee';
import { ITableEmployees } from '../models/EmployeeComponents';
// Constants
import { FIRST_CONTRACT } from '@pages/employees/constants/Employees';
// Styles
import './TableEmployees.scss';

export const TableEmployees: React.FC<ITableEmployees> = ({ data, activePlan, actionTable, handleSelectCheck }) => {
    return (
        <table data-testid="table_employee" className="table-employees">
            <thead className="table-employees__header">
                <tr className="header__row">
                    <th className="title-header" />
                    <th className="title-header">Nombres y apellidos</th>
                    <th className="title-header">N° de documento</th>
                    <th className="title-header">Tipo de contrato </th>
                    <th className="title-header">Término de contrato</th>
                    <th className="title-header" />
                </tr>
            </thead>
            <tbody className="table-employees__body">
                {data?.map((employee: IItemEmployee, index: number) => (
                    <tr key={`employee-${index}`} className="item__row">
                        <th className="column-checkbox">
                            <CheckBox
                                name="employee"
                                checked={employee?.check}
                                onClick={(): void => handleSelectCheck(employee.id, employee?.check)}
                            />
                        </th>
                        <th className="column">
                            <label className="column--text">
                                {employee?.names} {employee?.second_name} {employee?.last_names} {employee?.second_last_name}
                            </label>
                        </th>
                        <th className="column">
                            <label className="column--text">{employee?.document_number}</label>
                        </th>
                        <th className="column">
                            <label className="column--text">{employee?.contract[FIRST_CONTRACT]?.contractType?.name}</label>
                        </th>
                        <th className="column">
                            <label className="column--text">{employee?.contract[FIRST_CONTRACT]?.contractTerm?.name}</label>
                        </th>
                        <th className="column">
                            <div className="column__actions">
                                <Icon
                                    name="eyeGreen"
                                    hoverIcon="eyeGray"
                                    className="column--action"
                                    onClick={(): void => actionTable(employee.id, false, true)}
                                />
                                <Icon
                                    name="brownEditPencil"
                                    hoverIcon="grayEditPencil"
                                    className="icon--edit"
                                    onClick={(): boolean | void => activePlan && actionTable(employee.id, true)}
                                />
                            </div>
                        </th>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
