// Api
import AxiosClient from '@api/axiosClient';
// Urls
import { urls } from './urls';

/**
 * Get pila spreadsheet
 */
export const apiGetPilaSpreadSheet = <T>(body: { year_period_id: string }): Promise<T> =>
    AxiosClient.post(urls.reports.pila.getSpreadsheet, body);

/**
 * Get pila certification
 */
export const apiGetPilaCertification = <T>(body: { start_date: string; end_date: string }): Promise<T> =>
    AxiosClient.post(urls.reports.pila.getCertificate, body);

/**
 * Get data excel detail
 */
export const apiGetExcelDetail = <T>(yearPeriodId: string): Promise<T> =>
    AxiosClient.get(urls.reports.payroll.excelDetails(yearPeriodId));
