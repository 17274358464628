// Models
import { IOptionSelect } from '@components/input';
import { IGenericOptions } from '@models/Options';
import { IAdministrator, ICiius, ICiiusComplete, IRiskClass } from '@models/Utils';
import { CONTRACTS_CONTRIBUTOR, TypeAdministrator } from '@pages/employees/constants/Employees';
// Constants
import { GENERAL_DISEASE } from '@constants/OtherTexts';
import { AddressType } from '@models/AddressType';

/**
 * This function format options select in a generic case.
 * @constructor
 * @param options
 * @returns {IOptionSelect[]}
 */
export const formatOptions = (options: IGenericOptions[]): IOptionSelect[] => {
    const validateOptions = Array.isArray(options) ? options : [];
    return validateOptions?.map(({ id, name, country_state_id }) => ({ value: id, label: name, country_state_id }));
};
/**
 * This function format options select and the main value is search by a custom key.
 * @constructor
 * @param options
 * @param key
 * @returns {IOptionSelect[]}
 */
export const formatOptionValueByKey = ({ options, key }: { options: IGenericOptions[]; key: string }): IOptionSelect[] => {
    const validateOptions = Array.isArray(options) ? options : [];
    return validateOptions?.map(
        (item): IOptionSelect => ({ value: item[key], label: item.name, country_state_id: item.country_state_id, id: item.id })
    );
};

/**
 * This function format options select type funders
 * @constructor
 * @param options
 * @returns {IOptionSelect[]}
 */
export const formatOptionTypeFunders = (options: IGenericOptions[]): IOptionSelect[] => {
    const filterTypeFunders = options.filter(typeFunder => typeFunder.id === '76227B2C-34EC-445B-8F4F-A90A5085B500');
    return filterTypeFunders?.map(({ id, name, country_state_id }) => ({ value: id, label: name, country_state_id }));
};

/**
 * This function format options select ciius.
 * @constructor
 * @param {ICiius[]} options  - Current ciius without format.
 * @returns {IOptionSelect[]}
 */
export const formatOptionCiius = (options: ICiius[]): IOptionSelect[] => {
    return options?.map(({ name, code }) => ({ value: code, label: `${code} - ${name}` }));
};
/**
 * This function format options select ciius by name.
 * @constructor
 * @param {ICiius[]} options  - Current ciius without format.
 * @returns {IOptionSelect[]}
 */
export const formatOptionCiiusByName = (options: ICiius[]): IOptionSelect[] => {
    const filterEmpty = options?.filter(item => item.id);
    return filterEmpty?.map(({ name }) => ({ value: name, label: name }));
};

/**
 *
 * @param {ICiiusComplete[]} options - Current ciius complete without format.
 * @returns {IOptionSelect[]}
 */
export const formatOptionCiiusComplete = (options: ICiiusComplete[]): IOptionSelect[] => {
    return options?.map(element => ({
        value: element.ciiu_complete,
        label: `${element.ciiu_complete} - ${element.description}`,
    }));
};

/**
 * This function format options select risk class.
 * @constructor
 * @param {IRiskClass[]} options  - Current risk class without format.
 * @returns {IOptionSelect[]}
 */
export const formatOptionsRiskClass = (options: IRiskClass[]): IOptionSelect[] => {
    return options?.map(element => ({
        value: element.id,
        label: `${element.level} - ${element.class} - ${element.risk_rate} %`,
    }));
};

/**
 * This function format option select risk class by rate.
 * @constructor
 * @param {IRiskClass[]} options  - Current risk class without format.
 * @returns {IOptionSelect[]}
 */
export const formatOptionsRiskByRate = (options: IRiskClass[]): IOptionSelect[] => {
    const getNumberRiskClass = (value: string): string => {
        const splitValue = value.split(' ');
        return splitValue.pop() || '';
    };

    return options?.map(element => ({
        value: element.risk_rate.toString(),
        label: `${element.level} - ${element.class} - ${element.risk_rate} %`,
        other_value: getNumberRiskClass(element.class),
    }));
};

/**
 * This function format options select arl.
 * @constructor
 * @param {IAdministrator[]} options  - Current arl without format.
 * @returns {IOptionSelect[]}
 */
export const formatOptionsArl = (options: IAdministrator[]): IOptionSelect[] => {
    const listArl: IOptionSelect[] = [];
    options?.map(element => {
        if (element.type === TypeAdministrator.ARL) {
            listArl.push({
                label: element?.name_ael || '',
                value: element?.id || '',
            });
        }
        return element;
    });
    return listArl;
};

/**
 * This function format options select in a generic case with custom field.
 * @constructor
 * @param {IGenericOptions[]} options - List options receive function
 * @param {string} field - Field that you use from label
 * @returns {IOptionSelect[]}
 */
export const formatOptionsCustom = (options: IGenericOptions[], field: string): IOptionSelect[] => {
    if (!Array.isArray(options)) {
        return [];
    }
    return options?.map((item: IGenericOptions) => ({ value: item?.id, label: item[field] }));
};

/**
 * Validate if exist option with this id
 * @param {IOptionSelect[]} options - List options
 * @param {string} id - Id to search
 * @returns verified existence
 */
export const existOption = (options: IOptionSelect[], id: string): IOptionSelect | undefined => {
    return options?.find(element => element.value === id);
};

export const filterOption = (options: IOptionSelect[]): string =>
    options.filter(({ label }) => label.toLowerCase().includes(GENERAL_DISEASE))[0].value;

/**
 * This function format options select direction.
 * @constructor
 * @param {AddressType[]} options  - Current arl without format.
 * @returns {IOptionSelect[]}
 */
export const formatOptionsAddressType = (options: AddressType[], senSeleccione = false): IOptionSelect[] => {
    if (!Array.isArray(options)) {
        return [];
    }

    const listDirection: IOptionSelect[] = [];
    options?.forEach(element =>
        listDirection.push({
            label: `${element?.description} (${element?.code})`,
            value: element?.code,
        })
    );
    if (senSeleccione)
        listDirection?.unshift({
            label: 'Seleccione ...',
            value: '',
        });

    return listDirection;
};

export const filterContractsByTypeContributor = (contributorId: string, contracts: IOptionSelect[]): IOptionSelect[] => {
    const contractsByContributorId = CONTRACTS_CONTRIBUTOR.find(contributor =>
        contributor.contributorIds.some(contributorEquivalentId => contributorEquivalentId === contributorId)
    );

    if (!contractsByContributorId) return contracts;

    const contractsFilter = contracts.filter(contract =>
        contractsByContributorId.contractIds.some(contractId => contractId === contract.value)
    );

    return contractsFilter;
};
