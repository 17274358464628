import axios from '@utils/Axios';
import { AxiosRequestConfig, Method } from 'axios';

class AxiosClient {
    baseUrl = `${process.env.REACT_APP_BASE_URL}`;

    formatUrl(uri: string): string {
        return `${this.baseUrl}/${uri}`;
    }

    sendForm<T>(method: Method, uri: string, data: unknown, isFile?: boolean): Promise<T> {
        const url = this.formatUrl(uri);

        let request: AxiosRequestConfig = {
            method,
            url,
            data,
        };

        if (isFile) {
            request = {
                ...request,
                responseType: 'arraybuffer',
            };
        }

        return axios(request)
            .then(res => res.data)
            .catch(err => err.response.data);
    }

    get<T>(uri: string, data?: unknown): Promise<T> {
        return this.sendForm('GET', uri, data, false);
    }

    post<T>(uri: string, data: unknown, isFile = false): Promise<T> {
        return this.sendForm('POST', uri, data, isFile);
    }

    put<T>(uri: string, data: unknown): Promise<T> {
        return this.sendForm('PUT', uri, data, false);
    }

    patch<T>(uri: string, data: unknown): Promise<T> {
        return this.sendForm('PATCH', uri, data, false);
    }

    delete<T>(uri: string, data?: unknown): Promise<T> {
        return this.sendForm('DELETE', uri, data, false);
    }
}

export default new AxiosClient();
