// Redux
import { AppThunk } from '@redux/configureStore';
import { createSlice } from '@reduxjs/toolkit';
// Api
import { apiGetPeriodsByYear } from '@api/utils';
import { apiGetPilaCertification, apiGetPilaSpreadSheet } from '@api/pilaReport';
// Models
import { IPeriods, Period } from '@models/Periods';
import { IReportsState, PayrollReport } from '@models/Reports';
import { IPilaSpreadSheet } from '@models/PilaSpreadSheet';
import { IPaySlipsPeriod } from './types';

export const payslipsPeriodInitialState: IPaySlipsPeriod = {
    currentPeriod: {
        code: '',
        id: '',
        name: '',
        start_date: '',
        end_date: '',
        created_at: '',
        updated_at: '',
        period: {} as Period,
        periodicity_id: '',
        year_month_id: '',
        yearPeriods: [],
        indexMonth: 0,
        sharePeriodicity: '',
        year_id: '',
    } as IPeriods,
};

export const payrollReportInitialState: PayrollReport = {
    period: '',
    activeEmployees: [],
    inactiveEmployees: [],
    employeesContractInfo: {
        new_incomes: 0,
        pay_off_employees: 0,
    },
};

const initialState: IReportsState = {
    periods: [],
    error: '',
    hasYear: false,
    currentYear: '',
    pilaReport: [],
    data: [],
    pilaCertificate: [],
    openModal: false,
    payrollReport: payrollReportInitialState,
    payslipsPeriod: payslipsPeriodInitialState,
};

const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        setPeriods: (state, action) => {
            state.periods = action.payload;
        },
        setHasYear: (state, action) => {
            state.hasYear = action.payload;
        },
        setPayrollReport: (state, action) => {
            state.payrollReport = action.payload;
        },
        setCurrentYear: (state, action) => {
            state.currentYear = action.payload;
        },
        setResetAllData: state => {
            state.periods = [];
            state.payrollReport = {
                period: '',
                activeEmployees: [],
                inactiveEmployees: [],
                employeesContractInfo: {
                    new_incomes: 0,
                    pay_off_employees: 0,
                },
            };
            state.pilaReport = [];
            state.pilaCertificate = [];
            state.hasYear = false;
            state.currentYear = '';
        },
        setPilaReport: (state, action) => {
            state.pilaReport = action.payload;
        },
        setPilaCertificate: (state, action) => {
            state.pilaCertificate = action.payload;
        },
        setData: (state, action) => {
            state.data = action.payload;
        },
        setModal: (state, action) => {
            state.openModal = action.payload;
        },
        failedRequest: (state, action) => {
            state.error = action.payload;
        },
        setPayslipsPeriod: (state, action) => {
            state.payslipsPeriod = action.payload;
        },
    },
});

export const getPeriodsByYear = (year: string): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        try {
            const { periods } = getState().reports;
            const response = await apiGetPeriodsByYear<IPeriods[]>(year);

            if (!Array.isArray(response)) {
                dispatch(setPeriods([]));
                return;
            }

            if (
                periods &&
                response &&
                response?.every(
                    (item, i) => item?.code === periods[i]?.code && item?.id === periods[i]?.id && item?.name === periods[i]?.name
                )
            )
                return;
            dispatch(setPeriods(response));
        } catch (error) {
            dispatch(failedRequest(error));
        }
    };
};

export const getPilaSpreadSheet = (body: { year_period_id: string }): AppThunk => {
    const MAIN_INDEX = 0;
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetPilaSpreadSheet<IPilaSpreadSheet[]>(body);
            if (!Array.isArray(response) || response.length === 0) {
                dispatch(setPilaReport([]));
                return;
            }
            if (response[MAIN_INDEX].Liquidaciones.length === 0) {
                dispatch(setPilaReport([]));
                return;
            }
            dispatch(setPilaReport(response[MAIN_INDEX].Liquidaciones[MAIN_INDEX].DetallesLiquidacionEmpleados));
        } catch (error) {
            dispatch(failedRequest(error));
        }
    };
};

export const getPilaCertification = (body: { start_date: string; end_date: string }): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetPilaCertification(body);
            if (!Array.isArray(response)) {
                dispatch(setPilaReport([]));
                return;
            }
            const employeesHasCertification = response.filter(item => item.contribution_certificate);
            if (!employeesHasCertification.length) {
                dispatch(setPilaCertificate([]));
                dispatch(setModal(true));
                return;
            }
            dispatch(setPilaCertificate(employeesHasCertification));
        } catch (error) {
            dispatch(failedRequest(error));
        }
    };
};

export default reportsSlice.reducer;

export const {
    setPeriods,
    setHasYear,
    setCurrentYear,
    setResetAllData,
    setPayrollReport,
    setPilaReport,
    setPilaCertificate,
    setData,
    setModal,
    failedRequest,
    setPayslipsPeriod,
} = reportsSlice.actions;
