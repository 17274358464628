// Libraries
import React, { useState } from 'react';
import { Form, Formik } from 'formik';
// Components
import { ModalForm, ModalImportantInfo } from '@components/modal';
import { Button, ButtonIcon, ButtonSectionIcon } from '@components/button';
import { StepFour, StepOne, StepThree, StepTwo } from './components';
import { Routes } from '@components/paths';
// Hooks
import { useEmployee } from '@pages/employees/hooks/useEmployee';
import { useCreateDetails } from '@pages/employees/hooks/useCreateDetails';
import { useCreateEmployee } from '@pages/employees/hooks/useCreateEmployee';
import { useValidate } from '@pages/employees/hooks/useValidate';
import { useDefaultStep } from '@pages/employees/hooks/useNextStepTwo';
import { useEmployeeStack } from '@pages/employees/hooks/useEmployeeStack';
import { useKeyDownEnter } from '@hooks/useKeyDownEnter';
// Models
import { ICreateEmployeeProps } from '../models/EmployeeComponents';
import { IValidateSalary } from '@pages/employees/models/Employee';
// Constants
import {
    FIRST_CONTRACT,
    STEP_ONE,
    STEP_THREE,
    STEP_TWO,
    TAXPAYER_DEPENDENT,
    countryColombiaId,
} from '@pages/employees/constants/Employees';
// Utils
import { getRoute } from '@utils/Paths';
// Styles
import './CreateEmployee.scss';
import { IInitEmployee } from '@models/Employee';

export const CreateEmployee: React.FC<ICreateEmployeeProps> = ({
    edit = false,
    showModal,
    actionModal,
    employee,
    nameAction = '',
    isEditLiquidator = false,
    defaultStep = 1,
    handleModal = (): void => {},
    viewEmployee,
}) => {
    const {
        currentStep,
        showMessage,
        setCurrentStep,
        handleBackStep,
        handleNextStep,
        setShowMessage,
        employeesExceeded,
        getContractStartDate,
        showPermanentChangeSalary,
        handleShowPermanentChangeSalary,
        handleNextStepFourth,
        handleValidateExistEmployeeByEmail,
        handleValidationChangeDateContract,
    } = useEmployee();
    useDefaultStep(defaultStep, setCurrentStep, showModal);
    const { employeeSchema, validateCustomForm, validateContractTerm, validateSelectionContractTerm } = useValidate();
    const {
        documentTypes,
        compensationBox,
        eps,
        arl,
        pensions,
        layoffs,
        onlyContractTerm,
        contractTerm,
        contractType,
        salaryType,
        handleSubmit,
        riskClass,
        validateTypeAndNumberDocument,
        subtypeContributor,
        ciuus,
        handleTypeContributorChange,
    } = useCreateEmployee();
    const {
        createDepartment,
        createPosition,
        createCostCenter,
        listDepartments,
        listPositions,
        costCenter,
        typeFunders,
        compensationId,
        modalDelete,
        editElementCostCenter,
        editElementPosition,
        editElementDepartment,
        handleActionElement,
        handleActionDepartment,
        handleActionPosition,
        handleActionCostCenter,
        handleModalDelete,
        setSelectedElement,
        handleDelete,
        modalRepeatDetail,
        setModalRepeatDetail,
        departmentEmployer,
        municipalityEmployer,
        addressEmployer,
        arlIdEmployer,
        companyExemptedFromParafiscalPaymentsEmployer,
    } = useCreateDetails();

    const { dataEmployeeStack, handleCloseModalError, showModalError, messageError } = useEmployeeStack();

    const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);

    const { handleKeyDown } = useKeyDownEnter();

    const openCreateModal = edit || viewEmployee || !employeesExceeded;

    return (
        <>
            {employeesExceeded && !openCreateModal && (
                <ModalImportantInfo
                    isOpen={showModal}
                    handleBack={handleModal}
                    customButton={
                        <ButtonSectionIcon
                            iconName="moneyLightGreen"
                            color="green-light-3"
                            onClick={(): void => {
                                window.open(`${getRoute(Routes.PLANS)}`, '_blank');
                                handleModal();
                            }}
                        >
                            Adquirir nuevo plan
                        </ButtonSectionIcon>
                    }
                >
                    Se ha superado el número de empleados registrados en el plan seleccionado, te invitamos a adquirir el nuevo
                    paquete para poder registrar más empleados.
                </ModalImportantInfo>
            )}

            {openCreateModal && (
                <ModalForm
                    className="modal-employee"
                    classNameModal="modal-employee__custom-modal"
                    isOpen={showModal}
                    onRequestClose={(): void => {
                        actionModal();
                        setCurrentStep(1);
                    }}
                    title={
                        <div>
                            {!edit && !employee && (
                                <p className="title__modal" data-testid="title-create-employee">
                                    Nuevo <span className="title__modal--bold">Empleado</span>
                                </p>
                            )}
                            {edit && employee && (
                                <p className="title__modal" data-testid="title-create-employee">
                                    Editar <span className="title__modal--bold">Empleado</span>
                                </p>
                            )}
                            {!edit && employee && (
                                <p className="title__modal" data-testid="title-create-employee">
                                    Empleado <span className="title__modal--bold">Registrado</span>
                                </p>
                            )}
                        </div>
                    }
                >
                    <div className="create-employee">
                        <ModalImportantInfo
                            isOpen={showMessage}
                            handleBack={(): void => setShowMessage(!showMessage)}
                            confirm={false}
                        >
                            <div className="container__message" data-testid="modal-required">
                                <p className="message">Recuerda que todos los datos son de diligenciamiento obligatorio</p>
                            </div>
                        </ModalImportantInfo>
                        <ModalImportantInfo
                            isOpen={modalDelete}
                            hiddenBackButton={false}
                            handleBack={handleModalDelete}
                            mainAction={handleDelete}
                            confirm
                        >
                            <p>Estás seguro que deseas eliminar este elemento.</p>
                        </ModalImportantInfo>

                        <div className="container__height">
                            <div className="step__container">
                                <div className={`step-border${currentStep === STEP_ONE ? '' : '--disabled'}`}>
                                    <div className={`step-circle${currentStep === STEP_ONE ? '' : '--disabled'}`}>1</div>
                                </div>
                                <div className={`step-border${currentStep === STEP_TWO ? '' : '--disabled'}`}>
                                    <div className={`step-circle${currentStep === STEP_TWO ? '' : '--disabled'}`}>2</div>
                                </div>
                                <div className={`step-border${currentStep === STEP_THREE ? '' : '--disabled'}`}>
                                    <div className={`step-circle${currentStep === STEP_THREE ? '' : '--disabled'}`}>3</div>
                                </div>
                                <div className={`step-border${currentStep === 4 ? '' : '--disabled'}`}>
                                    <div className={`step-circle${currentStep === 4 ? '' : '--disabled'}`}>4</div>
                                </div>
                            </div>
                            <div className="container__formik">
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        names: employee?.names || dataEmployeeStack?.name || '',
                                        second_name: employee?.second_name || dataEmployeeStack?.second_name || '',
                                        last_names: employee?.last_names || dataEmployeeStack?.last_name || '',
                                        second_last_name: employee?.second_last_name || dataEmployeeStack?.second_last_name || '',
                                        document_type_id: employee?.document_type_id || dataEmployeeStack?.document_type_id || '',
                                        document_number: employee?.document_number || dataEmployeeStack?.document_number || '',
                                        birthdate: employee?.birthdate || '',
                                        email: employee?.email || dataEmployeeStack?.email || '',
                                        colombian_resident_abroad:
                                            employee?.colombian_resident_abroad ||
                                            dataEmployeeStack?.colombian_resident_abroad ||
                                            false,
                                        filing_date_in_ext:
                                            employee?.filing_date_in_ext || dataEmployeeStack?.date_of_filing_in_ext || '',
                                        department_id: employee?.department_id || '',
                                        position_id: employee?.position_id || '',
                                        cost_center_id: employee?.cost_center_id || '',
                                        cost_center_name: '',
                                        pension_fund_id: employee?.pension_fund_id || dataEmployeeStack?.afp_id || '',
                                        cesantias_fund_id: employee?.cesantias_fund_id || '',
                                        eps_id: employee?.eps_id || dataEmployeeStack?.eps_id || '',
                                        country_id: employee?.country_id || countryColombiaId,
                                        ciiu_code: employee?.ciiu_code || '',
                                        state_id: employee?.state_id || departmentEmployer || '',
                                        city_id: employee?.city_id || municipalityEmployer || '',
                                        postal_code: employee?.postal_code || '',
                                        phone: employee?.phone || dataEmployeeStack?.phone || '',
                                        employer_id: employee?.employer_id || '',
                                        type_funder_id: employee?.type_funder_id || dataEmployeeStack?.type_contributor_id || '',
                                        contributor_subtype:
                                            employee?.contributor_subtype || dataEmployeeStack?.subtype_contributor_id || '',
                                        compensation_box_id:
                                            employee?.compensation_box_id === null &&
                                            employee?.type_funder_id !== TAXPAYER_DEPENDENT
                                                ? ''
                                                : employee?.compensation_box_id || compensationId || '',
                                        contract_type_id: employee?.contract_type_id || '',
                                        contract_term_id: employee?.contract_term_id || '',
                                        start_date: getContractStartDate({ edit, viewEmployee, dataEmployeeStack, employee }),
                                        salary: employee?.salary || dataEmployeeStack?.salary || '',
                                        transportation_assistance: employee?.transportation_assistance || false,
                                        finish_date: employee?.finish_date || dataEmployeeStack?.date_end || '',
                                        salary_type_id: employee?.salary_type_id || dataEmployeeStack?.type_salary_id || '',
                                        risk_class_id: employee?.risk_class_id || '',
                                        name_branch: employee?.name_branch || dataEmployeeStack?.name_branch || '',
                                        work_center: employee?.work_center || dataEmployeeStack?.work_center || '',
                                        high_risk_rate_pension:
                                            employee?.contract[FIRST_CONTRACT].high_risk_rate_pension || false,
                                        address: employee?.address || addressEmployer || '',
                                        has_salary_endowment: employee?.has_salary_endowment ? 'SI' : 'NO',
                                        salary_endowment_value: employee?.salary_endowment_value || '',
                                        payment_method_id: employee?.payment_method_id || '',
                                        bank_id: employee?.bank_id || null,
                                        bank_account_type_id: employee?.bank_account_type_id || null,
                                        account_number: employee?.account_number || '',
                                        optional_ccf_contribution: employee?.optional_ccf_contribution || false,
                                        has_sustaining_support: employee?.has_sustaining_support || false,
                                        arl_id: employee?.arl_id || dataEmployeeStack?.arl_id || arlIdEmployer || '',
                                        old_salary: employee?.salary,
                                        exempt_from_parafiscal_taxes:
                                            employee?.exempt_from_parafiscal_taxes ||
                                            dataEmployeeStack?.exempt_from_parafiscal_taxes ||
                                            companyExemptedFromParafiscalPaymentsEmployer ||
                                            false,
                                        ...(edit && { permanent_salary_change: false }),
                                        ...(edit && { start_date_new_salary: '' }),
                                        ...(edit && { finish_date_new_salary: '' }),
                                    }}
                                    validationSchema={employeeSchema}
                                    validate={(values): IValidateSalary | object => validateCustomForm(values)}
                                    onSubmit={(): void => {
                                        setOpenModalConfirm(true);
                                    }}
                                >
                                    {({ values, setTouched }): JSX.Element => (
                                        <>
                                            <Form onKeyDown={handleKeyDown}>
                                                {currentStep === 1 && (
                                                    <StepOne
                                                        disabled={!edit && !!employee}
                                                        documentTypes={documentTypes}
                                                        departments={listDepartments}
                                                        positions={listPositions}
                                                        costCenter={costCenter}
                                                        createDepartment={createDepartment}
                                                        createPosition={createPosition}
                                                        createCostCenter={createCostCenter}
                                                        editElementPosition={editElementPosition}
                                                        editElementCostCenter={editElementCostCenter}
                                                        editElementDepartment={editElementDepartment}
                                                        handleActionElement={handleActionElement}
                                                        handleActionDepartment={handleActionDepartment}
                                                        handleActionPosition={handleActionPosition}
                                                        handleActionCostCenter={handleActionCostCenter}
                                                        setSelectedElement={setSelectedElement}
                                                        handleModalDelete={handleModalDelete}
                                                        values={values}
                                                        validateTypeAndNumberDocument={validateTypeAndNumberDocument}
                                                        edit={edit}
                                                        employee={employee}
                                                    />
                                                )}
                                                {currentStep === 2 && (
                                                    <StepTwo
                                                        disabled={!edit && !!employee}
                                                        typeContributor={typeFunders}
                                                        eps={eps}
                                                        arl={arl}
                                                        pensions={pensions}
                                                        layoffs={layoffs}
                                                        compensationBox={compensationBox}
                                                        values={values}
                                                        riskClass={riskClass}
                                                        subtypeContributor={subtypeContributor}
                                                        ciuus={ciuus}
                                                        handleTypeContributorChange={handleTypeContributorChange}
                                                    />
                                                )}
                                                {currentStep === 3 && (
                                                    <StepThree
                                                        edit={edit}
                                                        disabled={!edit && !!employee}
                                                        onlyContractTerm={onlyContractTerm}
                                                        contractTerm={contractTerm}
                                                        contractType={contractType}
                                                        salaryType={salaryType}
                                                        values={values}
                                                        validateContractTerm={validateContractTerm}
                                                        validateSelectionContractTerm={validateSelectionContractTerm}
                                                        showPermanentChangeSalary={showPermanentChangeSalary}
                                                        handleNextStepFourth={handleNextStepFourth}
                                                        handleShowPermanentChangeSalary={handleShowPermanentChangeSalary}
                                                    />
                                                )}
                                                {currentStep === 4 && <StepFour disabled={!edit && !!employee} />}
                                                <div className="footer_container">
                                                    {currentStep !== STEP_ONE && (
                                                        <ButtonIcon
                                                            type="button"
                                                            onClick={handleBackStep}
                                                            color="green"
                                                            iconName="backArrowWhite"
                                                        >
                                                            Volver
                                                        </ButtonIcon>
                                                    )}
                                                    {currentStep === 1 && (
                                                        <Button
                                                            color="green-light-3"
                                                            type="button"
                                                            onClick={async (): Promise<void> => {
                                                                const originalEmail =
                                                                    employee?.email || dataEmployeeStack?.email || '';
                                                                if (values.email !== originalEmail) {
                                                                    const result = await handleValidateExistEmployeeByEmail(
                                                                        values
                                                                    );
                                                                    if (result) return;
                                                                }

                                                                handleNextStep(values);
                                                                setTouched({
                                                                    pension_fund_id: false,
                                                                    cesantias_fund_id: false,
                                                                    eps_id: false,
                                                                    type_funder_id: false,
                                                                    compensation_box_id: false,
                                                                    contract_type_id: false,
                                                                    contract_term_id: false,
                                                                    start_date: false,
                                                                    salary: false,
                                                                    transportation_assistance: false,
                                                                    finish_date: false,
                                                                    salary_type_id: false,
                                                                });
                                                            }}
                                                        >
                                                            Siguiente
                                                        </Button>
                                                    )}
                                                    {currentStep === 2 && (
                                                        <Button
                                                            color="green-light-3"
                                                            onClick={(): void => {
                                                                handleNextStep(values);
                                                                setTouched({
                                                                    contract_type_id: false,
                                                                    contract_term_id: false,
                                                                    start_date: false,
                                                                    salary: false,
                                                                    transportation_assistance: false,
                                                                    finish_date: false,
                                                                    salary_type_id: false,
                                                                });
                                                            }}
                                                        >
                                                            Siguiente
                                                        </Button>
                                                    )}
                                                    {currentStep === 3 && (
                                                        <Button
                                                            type="button"
                                                            color="green-light-3"
                                                            onClick={async (): Promise<void> => {
                                                                if (
                                                                    edit &&
                                                                    values.salary?.toString() !== values.old_salary?.toString() &&
                                                                    !(values as IInitEmployee).permanent_salary_change_id
                                                                ) {
                                                                    handleShowPermanentChangeSalary();
                                                                    return;
                                                                }

                                                                if (
                                                                    edit &&
                                                                    (await handleValidationChangeDateContract(values, employee))
                                                                ) {
                                                                    return;
                                                                }

                                                                handleNextStep(values);
                                                                setTouched({
                                                                    payment_method_id: false,
                                                                    bank_id: false,
                                                                    bank_account_type_id: false,
                                                                    account_number: false,
                                                                });
                                                            }}
                                                        >
                                                            Siguiente
                                                        </Button>
                                                    )}
                                                    {currentStep === 4 && !(!edit && employee) && (
                                                        <Button
                                                            type="submit"
                                                            color="green-light-3"
                                                            onClick={(): void => handleNextStep(undefined)}
                                                        >
                                                            {edit && employee ? 'Actualizar' : 'Crear'}
                                                        </Button>
                                                    )}
                                                </div>
                                                <ModalImportantInfo
                                                    isOpen={openModalConfirm}
                                                    hiddenBackButton={false}
                                                    handleBack={(): void => setOpenModalConfirm(false)}
                                                    confirm
                                                    mainAction={(): void => {
                                                        handleSubmit(values, edit, actionModal, nameAction, isEditLiquidator);
                                                        setCurrentStep(1);
                                                        setOpenModalConfirm(false);
                                                    }}
                                                >
                                                    <div className="container--info-modal">
                                                        <div className="text-info-modal">
                                                            <p className="text-modal">
                                                                Antes de continuar verifica que la información del empleado sea
                                                                correcta.
                                                            </p>
                                                            <p className="text-modal text-bold">
                                                                Si estás seguro de que la información es correcta selecciona la
                                                                opción confirmar.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </ModalImportantInfo>
                                                <ModalImportantInfo
                                                    isOpen={modalRepeatDetail}
                                                    hiddenBackButton={false}
                                                    handleBack={(): void => setModalRepeatDetail(false)}
                                                >
                                                    <div className="container--info-modal">
                                                        <div className="text-info-modal">
                                                            <p className="text-modal">Ya existe esta opción</p>
                                                        </div>
                                                    </div>
                                                </ModalImportantInfo>
                                            </Form>
                                        </>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </ModalForm>
            )}

            <ModalImportantInfo isOpen={showModalError} handleBack={handleCloseModalError} confirm={false}>
                <p className="text-modal-error">{messageError}</p>
            </ModalImportantInfo>
        </>
    );
};
