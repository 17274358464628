export const ONLY_LIQUIDATION = 0;
export const FULL_NAME_FIELD = 'NombreCompleto';
export const TITLE_FILE = 'Aportes_Seguridad_Social';
export const STRING = 'string';
export const OBJECT = 'object';
export const ONE_NUMBER = 1;
export const ERROR = 'error';
export const TYPE_ERROR = 'Error';
export const SUCCESS_USER_PILA = 'Aportante registrado con éxito.';
export const ERROR_KEY = 'El número de planilla no es válido o corresponde a un aportante que no está asociado al usuario.';
