// Api
import AxiosClient from '@api/axiosClient';
// Models
import { IEmployerStatus, IGeneratePayroll, IRequestCompany, ISaveInformation } from '@models/ElectronicPayroll';
import { IYearPeriodId } from '@models/PayrollAdjuster';
import { ICompanyInformation } from '@models/Company';
// Urls
import { urls } from './urls';

// POST
export const apiPostSaveInformation = (data: ISaveInformation): Promise<ICompanyInformation> =>
    AxiosClient.post(urls.electronicPayroll.saveInformation, data);
export const apiPostGeneratePayroll = (data: IYearPeriodId): Promise<unknown> =>
    AxiosClient.post(urls.electronicPayroll.generatePayroll, data);
// GET
export const apiGetProcessPayroll = (payrollId: string): Promise<IGeneratePayroll> =>
    AxiosClient.get(urls.electronicPayroll.processPayroll(payrollId));
export const apiGetPayroll = <T>(yearPeriodId: string): Promise<T> =>
    AxiosClient.get(urls.electronicPayroll.getPayroll(yearPeriodId));
export const apiGetCheckEmployerStatus = (data: IRequestCompany, transmit?: boolean): Promise<IEmployerStatus | string> =>
    AxiosClient.post(urls.electronicPayroll.checkEmployerStatus(transmit), data);
export const apiGetElectronicPayrollToken = (): Promise<unknown> => AxiosClient.get(urls.electronicPayroll.getToken);
export const apiGetYearPeriodTransmit = (): Promise<unknown> => AxiosClient.get(urls.electronicPayroll.getYearPeriodTransmit);
