// Models
import { IBaseValues, IGetContractLiquidationInfo } from '@models/ResumeLiquidation';
import { IValuesLiquidationContract } from '../models/LiquidationContract';

export const RETENTIONS_TEXT = 'Retenciones y deducciones';
export const INCOMES_TEXT = 'Ingresos adicionales';

export const INITIAL_VALUES: IValuesLiquidationContract = {
    end_date: new Date(),
    liquidation_reason_id: '',
    vacation_days: '',
};

export const getInitialValues = (baseValues: IBaseValues): IValuesLiquidationContract => {
    return {
        end_date: new Date(),
        liquidation_reason_id: baseValues.liquidation_reason_id || '',
        vacation_days: baseValues.vacation_days.toString() || '',
    };
};

export const INITIAL_VALUES_LIQUIDATION_CONTRACT: IGetContractLiquidationInfo = {
    accruedSalary: 0,
    compensation: 0,
    daysCompensation: 0,
    hasCompensation: false,
    incomes: [],
    totalIncomes: 0,
    retentions: [],
    salary: 0,
    hasSocialBenefits: false,
    socialBenefits: [],
    totalRetentions: 0,
    totalSocialBenefits: 0,
    transportationValue: 0,
    vacations: 0,
    workedDays: 0,
};

export const rowKeyUserInfo: string[] = [
    'Nombre',
    'Documento',
    'Fecha de ingreso',
    'Tipo de contrato',
    'Motivo de liquidación',
    'Fecha finalización de contrato',
];

export const TOOLTIP_BASE_VALUE =
    'Este campo corresponde a la base para el cálculo de vacaciones, teniendo en cuenta que no capturamos la información antes de que se ingresará en la plataforma, es necesario verificar que el valor sea correcto ya que este será el que se tendrá en cuenta para el cálculo de las vacaciones en esta liquidación de contrato.';
