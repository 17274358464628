export enum LoginConstants {
    USER_TOKEN = 'USER_TOKEN',
    USER_DATA = 'USER_DATA',
    USER_ID = 'USER_ID',
    TOKEN_TIMEOUT_MESSAGE = 'Token has expired',
    REQUEST_WITHOUT_TOKEN = 'The token could not be parsed from the request',
    EMPLOYER_ID = 'EMPLOYER_ID',
}

export enum HeaderConstants {
    CONTENT_TYPE = 'Content-Type',
    AUTHORIZATION = 'authorization',
    EMPLOYER_ID = 'employer-id',
    USER_ID = 'user-id',
}

export enum Roles {
    SUPPORT = 'support',
    USER = 'user',
}

export enum SESSION_LOCATION {
    COUNTRY = 'country',
    DEPARTMENT = 'department',
}
