// Libraries
import React from 'react';
import ModalBase, { Props } from 'react-modal';
// Components
import { Icon } from '@components/icon';
import { Button, ButtonIcon, ButtonSectionIcon } from '@components/button';
// Models
import { IModalProps } from './models/Modal';
// Styles
import './Modal.scss';

export const Modal: React.FC<Props & IModalProps> = ({
    isOpen,
    onAfterOpen,
    onRequestClose,
    handleBack,
    children,
    className,
    closeIcon = true,
    disabledOverlay = false,
}) => {
    return (
        <ModalBase
            ariaHideApp={false}
            isOpen={isOpen}
            onAfterOpen={onAfterOpen}
            onRequestClose={onRequestClose}
            className={`modal__container ${className}`}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
            style={{
                overlay: {
                    ...(disabledOverlay && { backgroundColor: 'transparent' }),
                },
            }}
        >
            <div className="modal__section">
                <div className={`modal__header ${handleBack ? 'modal__header--actions' : ''}`}>
                    {handleBack && <Icon name="backArrowGreen" className="close-button" onClick={handleBack} />}
                    {closeIcon && <Icon name="close" className="close-button" onClick={onRequestClose} />}
                </div>
                <div className="modal__body">{children}</div>
            </div>
        </ModalBase>
    );
};

export const ModalForm: React.FC<Props & IModalProps> = ({
    isOpen,
    onAfterOpen,
    handleBack,
    title,
    onRequestClose,
    closeIcon = true,
    children,
    className,
    classNameModal,
}) => {
    return (
        <ModalBase
            isOpen={isOpen}
            onAfterOpen={onAfterOpen}
            onRequestClose={onRequestClose}
            ariaHideApp={false}
            className={`modal__container ${classNameModal}`}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <div className={`modal__form-section ${className}`}>
                <div className={`modal__form-header ${handleBack ? 'modal__form-header--actions' : ''}`}>
                    <div>{handleBack && <Icon name="backArrowRoundWhite" className="close-button" onClick={handleBack} />}</div>
                    <div className="title">{title}</div>
                    {closeIcon && <Icon name="closeWhite" className="close-button" onClick={onRequestClose} />}
                </div>
                <div className="modal__body">{children}</div>
            </div>
        </ModalBase>
    );
};

export const ModalError: React.FC<Props & IModalProps> = ({
    isOpen,
    onAfterOpen,
    onRequestClose,
    message,
    closeModal,
    className,
    textButton = 'Cerrar',
}) => {
    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={onAfterOpen}
            onRequestClose={onRequestClose}
            ariaHideApp={false}
            className={className}
        >
            {message && <div className="modal__error--message">{message}</div>}
            <div className="container__button">
                <Button onClick={closeModal}>{textButton}</Button>
            </div>
        </Modal>
    );
};

export const ModalDelete: React.FC<Props & IModalProps> = ({
    isOpen,
    onRequestClose,
    mainAction,
    message,
    textCancel,
    textConfirm,
}) => {
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
            <React.Fragment>
                <div className="modal__content_delete">
                    <p className="description__modal">{message ? message : 'Estás seguro que deseas eliminar este elemento.'}</p>
                    <div className="actions__modal">
                        <span className="color__gray" onClick={onRequestClose}>
                            {textCancel ? textCancel : 'No'}
                        </span>
                        <span className="color__green" onClick={mainAction}>
                            {textConfirm ? textConfirm : 'Si'}
                        </span>
                    </div>
                </div>
            </React.Fragment>
        </Modal>
    );
};

export const ModalPaymentNotification: React.FC<Props & IModalProps> = ({ isOpen, onRequestClose, textNotification }) => {
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
            {textNotification && <div className="modal-payment-notification">{textNotification}</div>}
        </Modal>
    );
};

export const ModalImportantInfo: React.FC<Props & IModalProps> = ({
    isOpen,
    onAfterOpen,
    onRequestClose,
    handleBack,
    children,
    title = 'Importante',
    isPay = false,
    confirm = false,
    disabledConfirm = false,
    className,
    mainAction,
    hiddenBackButton = false,
    customButton,
    portalClassName,
}) => {
    return (
        <ModalBase
            isOpen={isOpen}
            onAfterOpen={onAfterOpen}
            onRequestClose={onRequestClose}
            ariaHideApp={false}
            className="modal__container"
            portalClassName={portalClassName}
        >
            <div className="modal__important-section">
                <div className="modal__important-section--header">
                    <p>{title}</p>
                </div>
                <div className={`modal__important-section--body ${className}`}>
                    <div className="content">{children}</div>
                </div>
                <div className="modal__important-section--footer">
                    {!hiddenBackButton && (
                        <ButtonIcon iconName="backArrowWhite" color="green" onClick={handleBack}>
                            Volver
                        </ButtonIcon>
                    )}

                    {isPay && (
                        <ButtonSectionIcon iconName="moneyLightGreen" onClick={mainAction}>
                            Pagar
                        </ButtonSectionIcon>
                    )}
                    {confirm && (
                        <Button color="green-light-3" onClick={mainAction} disabled={disabledConfirm}>
                            Confirmar
                        </Button>
                    )}
                    {customButton === undefined ? null : customButton}
                </div>
            </div>
        </ModalBase>
    );
};
