// Libraries
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
// Models
import { IInitEmployee } from '@models/Employee';
import { IOptionSelect } from '@components/input';
// Constants
import {
    colombianResidentAbroad,
    countryColombiaId,
    countryId,
    filingDateInExt,
    foreignerNotRequiredToPayPensionContributions,
    internationalTypeOFDocuments,
    localTypesDocument,
    TypesDocumentIds,
} from '../constants/Employees';
// Hooks
import { useAppSelector } from '@hooks/redux';
// Redux
import { RootState } from '@redux/rootReducer';
import { formatOptions } from '@utils/Options';

export const useStepOne = (): {
    cities: IOptionSelect[];
    departmentsOptions: IOptionSelect[];
    countryOptions: IOptionSelect[];
} => {
    const {
        utils: { county_state_city, country_state, country },
    } = useAppSelector((state: RootState) => state);

    const { values, setFieldValue } = useFormikContext<IInitEmployee>();

    const { document_type_id, colombian_resident_abroad } = values;
    useEffect(() => {
        if (localTypesDocument.some(type => (TypesDocumentIds as { [key: string]: string })[type] === document_type_id)) {
            setFieldValue(foreignerNotRequiredToPayPensionContributions, false);
        }

        if (
            internationalTypeOFDocuments.some(type => (TypesDocumentIds as { [key: string]: string })[type] === document_type_id)
        ) {
            setFieldValue(colombianResidentAbroad, false);
            setFieldValue(filingDateInExt, '');
        }
    }, [document_type_id]);

    useEffect(() => {
        if (!colombian_resident_abroad) {
            setFieldValue(filingDateInExt, '');
            setFieldValue(countryId, countryColombiaId);
        } else {
            setFieldValue(countryId, '');
        }
    }, [colombian_resident_abroad]);

    return {
        cities: formatOptions(county_state_city || []),
        departmentsOptions: formatOptions(country_state || []),
        countryOptions: formatOptions(country || []),
    };
};
