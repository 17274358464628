export const GENERAL_DISEASE = 'enfermedad general';
export const PARAFISCAL_CONTRIBUTIONS = 'Realizar Aportes Parafiscales en días de incapacidad.';
export const FULL_SALARY = 'Pagar nómina y cotizar los días de incapacidad en seguridad social con el 100% del salario.';
export const HAS_FULL_PAY_DAYS = 'Pagar y cotizar en seguridad social los dos primeros días de la incapacidad con el 100%.';
export const ENTER = 'Enter';
export const STRING_ERROR = 'confirm';
export const USER = 0;
export const NOVELTY = 0;
export const ERROR_NOVELTY = 'error with novelty date';
export const OBJECT = 'object';
export const FUNCTION = 'function';
export const DAY_TEN = '10';
export const INITIAL_INPUT_VALUE = 'Seleccione...';
export const FIELD_IS_REQUIRED = '* El campo es requerido';
export const GET_PLANS_API = 'payment/get-plans';
export const GENERAL_ERROR = 'En este momento no podemos procesar la información, intente más tarde';
export const SOON_AVAILABLE = 'Próximamente disponible';
export const SOON = 'Próximamente';
