// Libraries
import moment from 'moment';
import { AppThunk } from '@redux/configureStore';
import { createSlice } from '@reduxjs/toolkit';
// Types
import { IPayslipsState } from './types';
// Constants
import { TypesSocialBenefits } from '@constants/TypesSocialBenefits';
// Api
import {
    apiGetBonusService,
    apiGetSeverancePay,
    apiGetInterestSeverancePay,
    apiCheckLiquidation,
    apiPostWithdrawReason,
    apiGetPayrollSlipReport,
} from '@api/payslips';
import { apiGetCurrentPeriod } from '@api/utils';
import { getHomeInformation } from '@redux/company/company.slice';
// Models
import {
    IPayrollSlipReportResponse,
    IPaymentSlipRequest,
    IRequestSocialBenefits,
    IServiceBonus,
    ISeverancePay,
} from '@models/payslips';
import { ICurrentYearPeriod } from '@models/Utils';
import { IAnticipatedLiquidation } from '@pages/severance-pay/components/models/AnticipatedLiquidation';
import { IPeriods, Period } from '@models/Periods';

const initialState: IPayslipsState = {
    servicesBonusList: [],
    severancePayList: [],
    interestSeveranceList: [],
    paymentSlipEmployees: [],
    hasLiquidation: [],
    hasLIquidationFortnightly: false,
    currentYearPeriod: {
        id: '',
        code: '',
        periodicity_id: '',
        start_date: '',
        end_date: '',
        liquidated: true,
    },
    allCurrentYearPeriods: [],
    yearPeriodId: '',
    error: '',
    isLastPayslipsPeriod: true,
    isPaymentSlipEmployeesLoaded: false,
};

const payslipsSlice = createSlice({
    name: 'payslips',
    initialState,
    reducers: {
        setServicesBonusList: (state, action) => {
            state.servicesBonusList = action.payload;
        },
        setSeverancePayList: (state, action) => {
            state.severancePayList = action.payload;
        },
        setInterestSeveranceList: (state, action) => {
            state.interestSeveranceList = action.payload;
        },
        setPaymentSlipEmployees: (state, action) => {
            state.paymentSlipEmployees = action.payload;
        },
        setHasLiquidation: (state, action) => {
            state.hasLiquidation = action.payload;
        },
        setHasLiquidationFortnightly: (state, action) => {
            state.hasLIquidationFortnightly = action.payload;
        },
        setCurrentYearPeriod: (state, action) => {
            state.currentYearPeriod = action.payload;
        },
        setYearPeriodId: (state, action) => {
            state.yearPeriodId = action.payload;
        },
        setAllCurrentYearPeriods: (state, action) => {
            state.allCurrentYearPeriods = action.payload;
        },
        failedRequest: (state, action) => {
            state.error = action.payload;
        },
        setIsLastPayslipsPeriod: (state, action) => {
            state.isLastPayslipsPeriod = action.payload;
        },
        setIsPaymentSlipEmployeesLoaded: (state, action) => {
            state.isPaymentSlipEmployeesLoaded = action.payload;
        },
    },
});

/**
 * Action to get current year period id
 */
export const getCurrentYearPeriod = (type: string, year: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const checkAllPeriods = (data: ICurrentYearPeriod[]): boolean => Array.isArray(data);
            const getCurrentPeriod = (
                data: ICurrentYearPeriod[] | ICurrentYearPeriod
            ): ICurrentYearPeriod[] | ICurrentYearPeriod =>
                data && checkAllPeriods(data as ICurrentYearPeriod[])
                    ? (data as ICurrentYearPeriod[])?.find(item => moment().isBetween(item.start_date, item.end_date)) || []
                    : data;

            const response = await apiGetCurrentPeriod<ICurrentYearPeriod | ICurrentYearPeriod[]>(type, year);
            const currentPeriod = getCurrentPeriod(response);

            if ((currentPeriod as ICurrentYearPeriod)?.liquidated) {
                const response = await apiGetCurrentPeriod<ICurrentYearPeriod | ICurrentYearPeriod[]>(
                    type,
                    moment().year().toString()
                );
                dispatch(setCurrentYearPeriod(getCurrentPeriod(response)));

                if ((response as ICurrentYearPeriod)?.id) {
                    dispatch(setAllCurrentYearPeriods([response]));
                } else {
                    dispatch(setAllCurrentYearPeriods(checkAllPeriods(response as ICurrentYearPeriod[]) ? response : []));
                }
            } else {
                dispatch(setCurrentYearPeriod(currentPeriod));
                if ((response as ICurrentYearPeriod)?.id) {
                    dispatch(setAllCurrentYearPeriods([response]));
                } else {
                    dispatch(setAllCurrentYearPeriods(checkAllPeriods(response as ICurrentYearPeriod[]) ? response : []));
                }
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get current and last period
 */
export const getCurrentLastPeriods = async (
    getTwoPeriods: boolean
): Promise<{ currentPeriod: ICurrentYearPeriod; lastPeriod?: ICurrentYearPeriod | null }> => {
    let lastPeriod = null;
    const currentPeriod = await apiGetCurrentPeriod<ICurrentYearPeriod>(
        TypesSocialBenefits.SEVERANCE,
        moment().year().toString()
    );

    if (getTwoPeriods) {
        lastPeriod = await apiGetCurrentPeriod<ICurrentYearPeriod>(
            TypesSocialBenefits.SEVERANCE,
            moment().subtract(1, 'year').year().toString()
        );
    }

    return {
        currentPeriod,
        lastPeriod,
    };
};

/**
 * Action to get all period of severance pay
 */
export const getPeriodSeverancePay = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        dispatch(setAllCurrentYearPeriods([]));
        try {
            const validDate = moment().format('YYYY-MM-DD') > moment(`${moment().year()}-02-28`).format('YYYY-MM-DD');
            const { currentPeriod, lastPeriod } = await getCurrentLastPeriods(!validDate);
            const periods = validDate ? [currentPeriod] : [lastPeriod, currentPeriod];

            dispatch(setAllCurrentYearPeriods(periods));
            dispatch(setCurrentYearPeriod(validDate ? currentPeriod : lastPeriod));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get all period of severance pay
 */
export const getPeriodsSeveranceInterest = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        dispatch(setAllCurrentYearPeriods([]));
        try {
            const validateDate = moment().format('YYYY-MM-DD') > moment(`${moment().year()}-02-14`).format('YYYY-MM-DD');
            const { currentPeriod, lastPeriod } = await getCurrentLastPeriods(!validateDate);
            const periods = validateDate ? [currentPeriod] : [lastPeriod, currentPeriod];

            dispatch(setAllCurrentYearPeriods(periods));
            dispatch(setCurrentYearPeriod(validateDate ? currentPeriod : lastPeriod));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get services bonus list
 */
export const getAllServicesBonus = (wasEdited: boolean, flagSave: boolean, request?: IRequestSocialBenefits): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            payslips: { currentYearPeriod, servicesBonusList },
        } = getState();

        try {
            let formatResponse: IServiceBonus[] = [];
            const response = await apiGetBonusService<IServiceBonus[]>(currentYearPeriod.id, flagSave, request);

            if (Array.isArray(response)) {
                if (servicesBonusList?.length > 0 && wasEdited) {
                    servicesBonusList?.map(element => {
                        const editEmployee = response?.find(employeeEdited => employeeEdited.id === element.id);

                        if (editEmployee) {
                            formatResponse.push({ ...editEmployee, was_edited: true });
                            return element;
                        } else {
                            formatResponse.push(element);
                            return element;
                        }
                    });
                } else {
                    formatResponse = response;
                }
            }

            dispatch(setServicesBonusList(formatResponse));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get services bonus list by period
 */
export const getAllServicesBonusByPeriod = (id: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetBonusService<IServiceBonus[]>(id, false);
            dispatch(setServicesBonusList(Array.isArray(response) ? response : []));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get severance pay list
 */
export const getAllSeverancePay = (
    wasEdited: boolean,
    flagSave: boolean,
    request?: IRequestSocialBenefits,
    yearPeriodSelected?: ICurrentYearPeriod
): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        let {
            payslips: { currentYearPeriod },
        } = getState();
        const {
            payslips: { severancePayList },
        } = getState();
        if (yearPeriodSelected) {
            currentYearPeriod = yearPeriodSelected;
        }
        try {
            let formatResponse: ISeverancePay[] = [];
            const response = await apiGetSeverancePay<ISeverancePay[]>(currentYearPeriod.id, flagSave, request);

            if (Array.isArray(response)) {
                if (severancePayList?.length > 0 && wasEdited) {
                    severancePayList?.map(element => {
                        const editEmployee = response?.find(employeeEdited => employeeEdited.id === element.id);

                        if (editEmployee) {
                            formatResponse.push({ ...editEmployee, was_edited: true });
                        } else {
                            formatResponse.push(element);
                        }
                    });
                } else {
                    formatResponse = response;
                }
            }

            dispatch(setSeverancePayList(formatResponse));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get severance pay list by year
 */
export const getAllSeverancePayByYear = (id: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetSeverancePay<ISeverancePay[]>(id, false);
            dispatch(setSeverancePayList(Array.isArray(response) ? response : []));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const getCurrentHomePeriod = (type: string, year: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetCurrentPeriod(type, year);
            const { id } = response as ICurrentYearPeriod;
            dispatch(setCurrentYearPeriod(response));
            if (id) {
                dispatch(getHomeInformation(id));
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get severance pay list for withdrawal
 */
export const getAllSeverancePayWithdrawal = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            payslips: { currentYearPeriod },
        } = getState();
        try {
            const response = await apiGetSeverancePay<ISeverancePay[]>(currentYearPeriod.id, false);
            dispatch(setSeverancePayList(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get interest severance pay list
 */
export const getAllInterestSeverancePay = (saveInterest = false): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            payslips: { currentYearPeriod },
        } = getState();

        try {
            const response = await apiGetInterestSeverancePay<ISeverancePay[]>(currentYearPeriod.id, saveInterest);
            dispatch(setInterestSeveranceList(Array.isArray(response) ? response : []));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get interest severance pay list
 */
export const getAllInterestSeverancePayByYear = (id: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetInterestSeverancePay<ISeverancePay[]>(id);
            dispatch(setInterestSeveranceList(Array.isArray(response) ? response : []));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get interest severance pay list
 */
export const getPaymentSlipEmployees = (data: IPaymentSlipRequest, action?: (payload?: unknown) => void): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetPayrollSlipReport<IPayrollSlipReportResponse>(data.year_period);
            dispatch(setPaymentSlipEmployees(response));
            action && action(response);
            dispatch(setIsPaymentSlipEmployeesLoaded(true));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to check if there is liquidation.
 */
export const getCheckLiquidation = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            periods: { currentPeriod },
        } = getState();
        try {
            if (currentPeriod?.period?.id) {
                const response = await apiCheckLiquidation(currentPeriod?.period?.id);
                dispatch(setHasLiquidation(response));
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const getCheckLiquidationByFortnight = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            periods: { currentPeriod },
        } = getState();
        try {
            const [firstFortnight, secondFortnight] = await Promise.all(
                currentPeriod?.yearPeriods?.map(async item => {
                    const response = await apiCheckLiquidation<[]>(item.id);
                    return !!response.length;
                })
            );
            const resultValidation = validateShowInfoSocialSecurity(currentPeriod.period, currentPeriod);
            if (!resultValidation) {
                dispatch(setHasLiquidationFortnightly(resultValidation));
                return;
            }
            dispatch(setHasLiquidationFortnightly(firstFortnight && secondFortnight));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to create withdrawal reason
 */
export const postWithdrawalReason = (employee: ISeverancePay, withdrawReason: IAnticipatedLiquidation): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            payslips: { currentYearPeriod },
        } = getState();
        try {
            await apiPostWithdrawReason({
                employeeId: employee.id,
                yearPeriodId: currentYearPeriod.id,
                conceptTypeId: withdrawReason.reason_for_withdrawal,
            });
            dispatch(getAllSeverancePay(false, false));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to validate if the current period is the last period
 */
export const validateIsTheLastPeriod = (selectedPeriod: Period): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        try {
            const {
                periods: { currentPeriod },
            } = getState();

            const resultValidation = validateShowInfoSocialSecurity(selectedPeriod, currentPeriod);
            dispatch(setIsLastPayslipsPeriod(resultValidation));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const validateShowInfoSocialSecurity = (selectedPeriod: Period, currentPeriod: IPeriods): boolean => {
    const idLastYearPeriod = currentPeriod?.yearPeriods?.at(-1)?.id;
    const idCurrentPeriod = selectedPeriod?.id;
    let result = true;
    if (idLastYearPeriod !== idCurrentPeriod) {
        result = false;
    }
    return result;
};

export const {
    setServicesBonusList,
    setSeverancePayList,
    setInterestSeveranceList,
    setPaymentSlipEmployees,
    setHasLiquidation,
    setCurrentYearPeriod,
    setYearPeriodId,
    setHasLiquidationFortnightly,
    setAllCurrentYearPeriods,
    failedRequest,
    setIsLastPayslipsPeriod,
    setIsPaymentSlipEmployeesLoaded,
} = payslipsSlice.actions;

export default payslipsSlice.reducer;
