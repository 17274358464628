// Libraries
import { useMemo } from 'react';
import { useLocation } from 'react-router';

/**
 * This interface describe active link props
 * @constructor
 * @param {boolean} active - Current active link
 */
interface IUseActiveLink {
    active: boolean;
}

/**
 * Action to change active link
 * @constructor
 * @param {string} route - Current route
 * @param {string[]} subRoutes - Sub routes for active link
 * @returns {IUseActiveLink}
 */
export const useActiveLink = (route: string, subRoutes?: string[]): IUseActiveLink => {
    const { pathname } = useLocation();

    const active = useMemo(() => {
        return pathname === route || (pathname.includes(route) && subRoutes?.some(item => pathname.includes(item))) || false;
    }, [pathname]);

    return {
        active,
    };
};
