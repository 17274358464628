// Libraries
import { useState } from 'react';
// Redux
import { RootState } from '@redux/rootReducer';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
// Api
import { getProcessPayroll, postSaveInformation } from '@redux/electronic-payroll/electronicPayroll.slice';
// Models
import { CurrentInformation } from '../constants/InformationImportant';
import { IOptionsInformation, OptionSelected, Options } from '../components/models/ModalInformationImportant';
import { useTransmitElectronicPayroll } from '@pages/electronic-payroll/hooks/useTransmitElectronicPayroll';
import { IPeriods } from '@models/Periods';

export const useModalInformationImportant = (
    currentDate?: IPeriods
): {
    options: IOptionsInformation;
    showModalImportant: boolean;
    currentInformation: string;
    optionSelected: OptionSelected | null;
    handleShowModalImportant: () => void;
    handleSelectTypeInformation: (currentInformation: CurrentInformation) => void;
    handleSelectOption: (currentOption: Options | null) => void;
    handleConfirmOption: () => void;
    handleMainAction: (currentInformation: string) => Promise<void>;
} => {
    const {
        company: { information },
        electronicPayroll: { payroll, token },
    } = useAppSelector((state: RootState) => state);
    const { handleGeneratePayroll } = useTransmitElectronicPayroll();
    const dispatch = useAppDispatch();
    const [showModalImportant, setShowModalImportant] = useState<boolean>(false);
    const [currentInformation, setCurrentInformation] = useState<string>(CurrentInformation.ENABLE_SERVICE);
    const [optionSelected, setOptionSelected] = useState<OptionSelected | null>(null);
    const [options, setOptions] = useState<IOptionsInformation>({
        prima: information.severance || null,
        cesantias: information.service_bonus || null,
    });

    /**
     * Action to open modal information important
     */
    const handleShowModalImportant = (): void => {
        setShowModalImportant(!showModalImportant);
    };

    /**
     * Action to select type information
     * @param {CurrentInformation} currentInformation - Select current type information to show
     */
    const handleSelectTypeInformation = (currentInformation: CurrentInformation): void => {
        setCurrentInformation(currentInformation);
    };

    /**
     * Action to select option inside modal
     * @param {Options | null} currentOption - Option selected from modal information
     */
    const handleSelectOption = (currentOption: Options | null): void => {
        setOptionSelected(currentOption);
    };

    /**
     * Action to confirm selected option
     */
    const handleConfirmOption = async (): Promise<void> => {
        if (
            currentInformation === CurrentInformation.GENERATE_PRIMA ||
            currentInformation === CurrentInformation.GENERATE_CESANTIAS
        ) {
            if (optionSelected) {
                const newOption = {
                    ...options,
                    ...(currentInformation === CurrentInformation.GENERATE_PRIMA
                        ? { prima: optionSelected.toLowerCase() }
                        : { cesantias: optionSelected.toLowerCase() }),
                };
                setOptions(newOption);
                handleSelectOption(null);
                setCurrentInformation(
                    currentInformation === CurrentInformation.GENERATE_PRIMA ? CurrentInformation.GENERATE_CESANTIAS : ''
                );
                if (currentInformation !== CurrentInformation.GENERATE_PRIMA) handleShowModalImportant();
                if (currentInformation === CurrentInformation.GENERATE_CESANTIAS) {
                    dispatch(
                        postSaveInformation({
                            service_bonus: optionSelected.toLowerCase(),
                            severance: options.prima || '',
                        })
                    );
                }
            }
            await handleGeneratePayroll();
        }
    };

    /**
     * Return main action from type modal
     * @param {string} currentInformation - State to get current type modal
     */
    const handleMainAction = async (currentInformation: string): Promise<void> => {
        if (currentInformation === CurrentInformation.CONFIRM_TRANSMISSION) {
            payroll && currentDate && (await dispatch(getProcessPayroll(payroll?.id, currentDate.period.id)));
            handleSelectOption(null);
            handleShowModalImportant();
        } else if (
            currentInformation === CurrentInformation.CHECK_DETAIL ||
            currentInformation === CurrentInformation.CHECK_HISTORIC
        ) {
            window.open(`${process.env.REACT_APP_ELECTRONIC_PAYROLL_DETAIL}${token}`, '_blank', 'noreferrer');
            handleShowModalImportant();
        } else {
            handleConfirmOption();
        }
    };

    return {
        options,
        showModalImportant,
        currentInformation,
        optionSelected,
        handleShowModalImportant,
        handleSelectTypeInformation,
        handleSelectOption,
        handleConfirmOption,
        handleMainAction,
    };
};
