// Libraries
import React from 'react';
// Models
import { ITabPage } from '@components/tabs/models/Tabs';
// Components
import { EnablePayroll, TransmitElectronicPayroll } from '../components';

/**
 * All tabs name.
 */
export enum CurrentTabs {
    ENABLE_PAYROLL = 'Habilitación de nómina',
    TRANSMIT_ELECTRONIC_PAYROLL = 'Transmitir nómina electrónica',
}

/**
 * This action return all the page's tabs.
 * @constructor
 * @param {{
 *   provisionsProps: IProvisionsProps;
 * }} params - Current props for the components.
 * @returns {ITabPage}
 */
export const TabsPage = ({ selected, disabledTab }: { selected: string; disabledTab: boolean }): ITabPage => ({
    [CurrentTabs.ENABLE_PAYROLL]: {
        name: CurrentTabs.ENABLE_PAYROLL,
        Component: <EnablePayroll />,
        currentSelected: selected === CurrentTabs.ENABLE_PAYROLL,
    },
    [CurrentTabs.TRANSMIT_ELECTRONIC_PAYROLL]: {
        name: CurrentTabs.TRANSMIT_ELECTRONIC_PAYROLL,
        Component: <TransmitElectronicPayroll />,
        currentSelected: selected === CurrentTabs.TRANSMIT_ELECTRONIC_PAYROLL,
        disable: disabledTab,
    },
});
