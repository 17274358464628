// Libraries
import React from 'react';
// Components
import { Button, ButtonWithTooltip } from '@components/button';
import { TableElectronicPayroll, ModalMistakes } from '../../components';
import { ModalInformationImportant } from '@pages/electronic-payroll/components';
// Models
import { CurrentInformation } from '@pages/electronic-payroll/constants/InformationImportant';
// Constants
import { transmitText } from '../../constants/TextTooltip';
// Hooks
import { useGetActivePlan } from '@hooks/useGetActivePlan';
import { useTransmitElectronicPayroll } from '@pages/electronic-payroll/hooks/useTransmitElectronicPayroll';
import { useModalInformationImportant } from '@pages/electronic-payroll/hooks/useModalInformationImportant';
// Styles
import './TransmitElectronicPayroll.scss';
import { CarouselPeriods } from '@components/carousel';
import { ModalImportantInfo } from '@components/modal';
import { PILA_MESSAGE_ERROR } from '@pages/electronic-payroll/constants/TextModal';
import { TEXT_ELECTTRONIC_PAYROLL, TEXT_HOME } from '@pages/electronic-payroll/constants/Text';

export const TransmitElectronicPayroll: React.FC = () => {
    const { userPlan } = useGetActivePlan();
    const {
        periods,
        currentDate,
        dataPayroll,
        confirmed,
        errorModal,
        transmitConfirmed,
        dataErrorModal,
        errorGeneratePayrollModal,
        handleGeneratePayroll,
        handleOpenModalError,
        handleOpenModalErrorGenerate,
        handleChangeCard,
        handleGetDateModal,
    } = useTransmitElectronicPayroll();
    const {
        options,
        showModalImportant,
        currentInformation,
        optionSelected,
        handleShowModalImportant,
        handleSelectTypeInformation,
        handleSelectOption,
        handleMainAction,
    } = useModalInformationImportant(currentDate);

    return (
        <div className="transmit-electronic-payroll">
            <ModalInformationImportant
                isOpen={showModalImportant}
                onRequestClose={handleShowModalImportant}
                date={handleGetDateModal()}
                handleBack={(): void => handleShowModalImportant()}
                mainAction={(): Promise<void> => handleMainAction(currentInformation)}
                currentInformation={currentInformation}
                optionSelected={optionSelected}
                handleOption={handleSelectOption}
            />
            <ModalMistakes
                data={dataErrorModal}
                isOpen={errorModal}
                onRequestClose={handleOpenModalError}
                handleBack={handleOpenModalError}
            />
            <ModalImportantInfo
                isOpen={errorGeneratePayrollModal}
                onRequestClose={handleOpenModalErrorGenerate}
                confirm={true}
                mainAction={handleOpenModalErrorGenerate}
                hiddenBackButton={true}
            >
                <>
                    <p>{PILA_MESSAGE_ERROR}</p>
                </>
            </ModalImportantInfo>

            <div className="transmit-electronic-payroll__body">
                <div className="container__link">
                    <label
                        className="link--custom"
                        onClick={(): void => {
                            handleSelectTypeInformation(CurrentInformation.CHECK_HISTORIC);
                            handleShowModalImportant();
                        }}
                    >
                        Ver histórico
                    </label>
                </div>
                <div className="body__container">
                    <p className="description">
                        {currentInformation === CurrentInformation.CONFIRM_TRANSMISSION ? TEXT_ELECTTRONIC_PAYROLL : TEXT_HOME}
                    </p>
                    <div>
                        <CarouselPeriods
                            data={periods}
                            handleChange={handleChangeCard}
                            handlePeriod={(): void => {}}
                            currentDate={currentDate}
                        />
                    </div>
                    <div className="container__table">
                        <TableElectronicPayroll
                            data={transmitConfirmed ? dataPayroll : []}
                            transmit={confirmed}
                            handleModalDetail={(): void => {
                                handleSelectTypeInformation(CurrentInformation.CHECK_DETAIL);
                                handleShowModalImportant();
                            }}
                        />
                    </div>
                    <div className="container__buttons">
                        {!transmitConfirmed ? (
                            <ButtonWithTooltip
                                tooltip
                                disabled={!userPlan?.is_active}
                                className={`${
                                    !userPlan?.is_active ? 'container__buttons--disabled' : 'container__buttons--active'
                                }`}
                                textTooltip={transmitText}
                                tooltipPosition="top-end"
                                tooltipSize="small"
                                onClick={async (): Promise<void> => {
                                    if (!options.prima || !options.cesantias) {
                                        await handleShowModalImportant();
                                        await handleSelectTypeInformation(
                                            CurrentInformation[!options.prima ? 'GENERATE_PRIMA' : 'GENERATE_CESANTIAS']
                                        );
                                        return;
                                    }
                                    handleGeneratePayroll();
                                }}
                            >
                                Transmitir
                            </ButtonWithTooltip>
                        ) : (
                            <Button
                                disabled={confirmed || !userPlan?.is_active}
                                className={`${
                                    !userPlan?.is_active ? 'container__buttons--disabled' : 'container__buttons--active'
                                }`}
                                onClick={(): void => {
                                    handleSelectTypeInformation(CurrentInformation.CONFIRM_TRANSMISSION);
                                    handleShowModalImportant();
                                }}
                            >
                                Confirmar transmisión
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
