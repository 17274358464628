// Models
import { ICodeError, ITypePlan } from '@models/Plans';

export const LIMIT_EMPLOYERS = '15';

export const MAXIMUM_EMPLOYEE_PLAN = 3;

export const MODAL_PAYMENT_FISRT_STEP = 0;

export const MODAL_PAYMENT_SECOND_STEP = 1;

export const INITIAL_SELECTED_PLAN: ITypePlan = {
    id: '0',
    title: '',
    discount: '0',
    value_electronic_payroll_with_check: 0,
    type: '',
    value: 0,
    discounted_value: 0,
    value_with_vat: 0,
    electronic_payroll: false,
    max_employees: 0,
    periodicity_id: '0',
    value_electronic_payroll: 0,
    min_employees: 0,
    values_without_taxes: {
        value: 0,
        value_electronic_payroll: 0,
        value_electronic_payroll_with_check: 0,
    },
    apply_taxes: false,
    iva: 0,
};

export const MONTHLY_PLAN = 0;

export const CONFIRM = 'Aprobada';

export const REJECTED = 'Rechazada';

export const NOT_TRANSACTION = 'This employer does not yet have a transaction';

export const UNPROCESSED = 'SinProcesar';

export const DISABLED_SERVICE = 'Este servicio no está disponible. Consulte más tarde';

export const INVALID_CODE = 'Sorry, the code is no longer valid.';

export const TEXT_ERROR_CODE: ICodeError = {
    'Code is invalid.': 'El código no es válido',
    'Sorry, the code is no longer valid.': 'El código no está disponible',
};

export const UTILS_REQUEST: string[] = ['county_state_city', 'country_state', 'document_type', 'country'];

export const plans = [
    {
        title: 'De 1 a 4',
        price: '24.000',
        image: 'bg-card-one',
    },
    {
        title: 'De 5 a 10',
        price: '66.666',
        image: 'bg-card-two',
    },
    {
        title: 'De 11 a 15',
        price: '100.000',
        image: 'bg-card-three',
    },
    {
        title: '15',
        price: '125.000',
        image: 'bg-card-four',
    },
];

export const INVALID_PLAN_MESSAGE =
    'Recuerda que el plan que selecciones  debe contemplar  la cantidad de empleados que estén registrados en el portal. Vuelve a seleccionar un plan que se ajuste a esa cantidad';

export const PERIODICITIES_PLANS = new Map();
PERIODICITIES_PLANS.set('Mensual', 1);
PERIODICITIES_PLANS.set('Trimestral', 2);
PERIODICITIES_PLANS.set('Semestral', 3);
PERIODICITIES_PLANS.set('Anual', 4);
