/**
 * Function to verify if having an error as string in the create absence request.
 * @constructor
 * @param {string} error - Error to verify
 * @return {{ message: string; isError: boolean }}
 */
export const checkErrorByString = (error: string): { message: string; isError: boolean } => {
    const decodeError = decodeURIComponent(error);

    return {
        message: decodeError,
        isError: true,
    };
};
