import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../baseQuery';
import { urls } from '@api/urls';
import { IPeriods } from '@models/Periods';
import { keepDataPerSeconds } from '@redux/constants/queryConfig';

export type IPeriodsRequest = {
    start_date: string;
    end_date: string;
    periodicity_id: string;
};

export const payslipPeriodApi = createApi({
    baseQuery,
    reducerPath: 'payslipsPeriods',
    keepUnusedDataFor: keepDataPerSeconds.payslipPeriodApi,
    tagTypes: ['PayslipsPeriods'],
    endpoints: build => ({
        getCarouselPeriods: build.query<IPeriods[], IPeriodsRequest>({
            query: ({ start_date, end_date, periodicity_id }) => {
                const queryString = new URLSearchParams({
                    start_date,
                    end_date,
                    periodicity_id,
                }).toString();

                return {
                    url: `${urls.utils.periodicity.getAllPeriodsInYearByPeriodicicity}?${queryString}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: IPeriods[]) => response,
        }),
    }),
});

export const { useGetCarouselPeriodsQuery } = payslipPeriodApi;
