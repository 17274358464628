// Libraries
import { AppThunk } from '@redux/configureStore';
import { createSlice } from '@reduxjs/toolkit';
// Api
import {
    apiGetDetailEmployee,
    apiGetPositions,
    apiGetDepartments,
    apiPostEmployee,
    apiPostDepartment,
    apiPostPosition,
    apiPutDepartment,
    apiPutPosition,
    apiPutCostCenter,
    apiDeleteDepartment,
    apiDeletePosition,
    apiDeleteCostCenter,
    apiGetCostCenters,
    apiPostCostCenter,
    apiPutEmployee,
    apiDeleteEmployees,
    apiGetEmployees,
    apiGetContributor,
    apiGetContributorBase,
    apiGetActiveEmployees,
    apiPostExistByDocumentNumber,
    apiPostExistByEmail,
} from '@api/employees';
// Models
import {
    ICreateDetail,
    IEmployeeData,
    IEmployeesDelete,
    IErrorUserPila,
    IPayloadContributor,
    IResponseBase,
    IResponseDeleteEmployee,
    TExistEmployee,
} from '@models/Employee';
import { IEmployeeState } from './types';
// Redux
import { getTableEmployeeLiquidator } from '@redux/payroll-adjuster/payrollAdjuster.slice';
import { getProvisionsTable } from '@redux/provisions/provisions.slice';
import { getApiCarouselPeriods, setCurrentPeriod, setPeriods } from '@redux/periods/periods.slice';
// Constants
import {
    errorContributorNotRegistered,
    errorContributor,
    errorTypeDocument,
    errorBase,
    existEmployeeMessage,
    existEmployeeEsMessage,
    MESSAGE_UNABLE_CREDENTIALS,
} from '@constants/Api';
import { EMPLOYEE_EXIST_DOCUMENT_NUMBER } from '@pages/employees/constants/Employees';
import { GENERIC_ERROR_MESSAGE } from '@constants/error';
import { apiPostValidateChange } from '@api/novelty';
import { TResult } from '@models/Result';
import { IValidateChangeNoveltyContract } from '@models/Novelty';

const initialState: IEmployeeState = {
    employees: [],
    activeEmployees: [],
    employee: null,
    departments: [],
    positions: [],
    cost_center: [],
    department: null,
    position: null,
    cost_center_selected: null,
    disabled_fields: false,
    employee_stack: null,
    modal_error: {
        message: '',
        open_modal: false,
    },
    flagContriburor: false,
    validateContributor: false,
    error: '',
};

const employeeSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        setEmployees: (state, action) => {
            state.employees = action.payload;
        },
        setActiveEmployees: (state, action) => {
            state.activeEmployees = action.payload;
        },
        setEmployee: (state, action) => {
            state.employee = action.payload;
        },
        setDepartments: (state, action) => {
            state.departments = action.payload;
        },
        setPositions: (state, action) => {
            state.positions = action.payload;
        },
        setCostCenter: (state, action) => {
            state.cost_center = action.payload;
        },
        setDepartment: (state, action) => {
            state.department = action.payload;
        },
        setPosition: (state, action) => {
            state.position = action.payload;
        },
        setCostCenterSelected: (state, action) => {
            state.cost_center_selected = action.payload;
        },
        setDisableFields: (state, action) => {
            state.disabled_fields = action.payload;
        },
        setEmployeeStack: (state, action) => {
            state.employee_stack = action.payload;
        },
        setModalError: (state, action) => {
            state.modal_error = action.payload;
        },
        setFlagContributor: (state, action) => {
            state.flagContriburor = action.payload;
        },
        setValidateContributor: (state, action) => {
            state.validateContributor = action.payload;
        },
        failedRequest: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const getPeriods = (periodicity?: string): AppThunk<object> => {
    return async (dispatch, getState) => {
        const {
            company: {
                information: { periodicity_id },
            },
            periods: { currentPeriod },
        } = getState();
        const currentPeriodicity = periodicity ? periodicity : periodicity_id || '';

        try {
            const { period, periods } = await getApiCarouselPeriods(currentPeriodicity, currentPeriod);
            dispatch(setPeriods(periods));
            dispatch(setCurrentPeriod(period));
            return period;
        } catch (err) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to create employee
 */
export const postCreateEmployee = (data: IEmployeeData, actionModal = (): void => {}): AppThunk => {
    return async (dispatch, getState): Promise<void | unknown> => {
        const {
            payrollAdjuster: { inPayroll },
            periods: { currentPeriod },
            company: { id: employerId },
        } = getState();
        try {
            const response = await apiPostEmployee(data);

            if ((response as IErrorUserPila)['error']) {
                dispatch(
                    setModalError({
                        message: (response as IErrorUserPila)['error'],
                        open_modal: true,
                    })
                );
                return;
            }

            if (typeof (response as string) === 'string') {
                dispatch(
                    setModalError({
                        message: response === existEmployeeMessage ? existEmployeeEsMessage : response,
                        open_modal: true,
                    })
                );
                return;
            }

            actionModal();

            await dispatch(getActiveEmployees());
            inPayroll &&
                dispatch(
                    getTableEmployeeLiquidator({
                        employer_id: employerId,
                        start_date: currentPeriod?.period?.start_date,
                        year_period_id: currentPeriod?.period?.id,
                    })
                );
            dispatch(setFlagContributor(false));
            dispatch(setValidateContributor(false));
            return response;
        } catch (error) {
            dispatch(setModalError({ message: GENERIC_ERROR_MESSAGE, open_modal: true }));
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to create employee in provisions table.
 */
export const postCreateEmployeeInProvisions = (data: IEmployeeData, actionModal = (): void => {}): AppThunk => {
    return async (dispatch, getState): Promise<void | unknown> => {
        const {
            periods: { currentPeriod },
            company: { id },
        } = getState();
        try {
            const response = await apiPostEmployee(data);

            if ((response as IErrorUserPila)['error']) {
                dispatch(
                    setModalError({
                        message: (response as IErrorUserPila)['error'],
                        open_modal: true,
                    })
                );
                return;
            }

            actionModal();

            id &&
                dispatch(
                    getProvisionsTable({
                        employer_id: id,
                        year_period_id: currentPeriod.period.id,
                        employees_id: [],
                        is_save: false,
                    })
                );
            dispatch(setValidateContributor(false));
            return response;
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to update employee
 */
export const putUpdateEmployee = <T>(
    id: string,
    data: IEmployeeData | T,
    isEditLiquidator = false,
    actionModal = (): void => {}
): AppThunk => {
    return async (dispatch, getData): Promise<void | unknown> => {
        try {
            const {
                company: { id: idCompany },
                periods: { currentPeriod },
            } = getData();
            const response = await apiPutEmployee(id, data);

            if ((response as IErrorUserPila)['error']) {
                dispatch(
                    setModalError({
                        message: (response as IErrorUserPila)['error'],
                        open_modal: true,
                    })
                );
                return;
            }

            actionModal();

            await dispatch(getActiveEmployees());
            isEditLiquidator &&
                (await dispatch(
                    getTableEmployeeLiquidator({
                        employer_id: idCompany,
                        start_date: currentPeriod?.period?.start_date,
                        year_period_id: currentPeriod?.period?.id,
                    })
                ));
            return response;
        } catch (error) {
            dispatch(setModalError({ message: GENERIC_ERROR_MESSAGE, open_modal: true }));
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get detail data employee
 */
export const getDetailEmployee = (employeeId: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetDetailEmployee(employeeId);
            dispatch(setEmployee(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get detail data employee
 */
export const getDepartments = (): AppThunk => {
    return async (dispatch, state): Promise<void> => {
        const {
            company: { id },
        } = state();
        try {
            const response = id && (await apiGetDepartments(id));
            dispatch(setDepartments(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to create a department
 */
export const postDepartment = (data: ICreateDetail): AppThunk => {
    return async (dispatch, state): Promise<void> => {
        const {
            company: { id },
        } = state();
        try {
            const response = await apiPostDepartment({ name: data.name, employer_id: id });
            dispatch(setDepartment(response));
            dispatch(getDepartments());
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to update department
 */
export const putDepartment = (id: string, data: ICreateDetail): AppThunk => {
    return async (dispatch, state): Promise<void> => {
        const {
            company: { id: employer_id },
        } = state();
        try {
            const response = await apiPutDepartment(id, { name: data.name, employer_id: employer_id });
            dispatch(setDepartment(response));
            dispatch(getDepartments());
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to delete department
 */
export const deleteDepartment = (id: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            await apiDeleteDepartment(id);
            dispatch(setDepartment(null));
            dispatch(getDepartments());
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get detail data employee
 */
export const getPositions = (): AppThunk => {
    return async (dispatch, state): Promise<void> => {
        const {
            company: { id },
        } = state();
        try {
            const response = id && (await apiGetPositions(id));
            dispatch(setPositions(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to create a position
 */
export const postPosition = (data: ICreateDetail): AppThunk => {
    return async (dispatch, state): Promise<void> => {
        const {
            company: { id },
        } = state();
        try {
            const response = await apiPostPosition({ name: data.name, employer_id: id });
            dispatch(setPosition(response));
            dispatch(getPositions());
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to update position
 */
export const putPosition = (id: string, data: ICreateDetail): AppThunk => {
    return async (dispatch, state): Promise<void> => {
        const {
            company: { id: employer_id },
        } = state();
        try {
            const response = await apiPutPosition(id, { name: data.name, employer_id: employer_id });
            dispatch(setPosition(response));
            dispatch(getPositions());
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to delete position
 */
export const deletePosition = (id: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            await apiDeletePosition(id);
            dispatch(setDepartment(null));
            dispatch(getPositions());
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get cost center data employee
 */
export const getCostCenters = (): AppThunk => {
    return async (dispatch, state): Promise<void> => {
        const {
            company: { id },
        } = state();
        try {
            const response = id && (await apiGetCostCenters(id));
            dispatch(setCostCenter(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to create cost center
 */
export const postCostCenter = (data: ICreateDetail): AppThunk => {
    return async (dispatch, state): Promise<void> => {
        const {
            company: { id },
        } = state();
        try {
            const response = await apiPostCostCenter({ name: data.name, employer_id: id });
            dispatch(setCostCenterSelected(response));
            dispatch(getCostCenters());
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to update cost center
 */
export const putCostCenter = (id: string, data: ICreateDetail): AppThunk => {
    return async (dispatch, state): Promise<void> => {
        const {
            company: { id: employer_id },
        } = state();
        try {
            const response = await apiPutCostCenter(id, { name: data.name, employer_id: employer_id });
            dispatch(setCostCenterSelected(response));
            dispatch(getCostCenters());
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to delete cost center
 */
export const deleteCostCenter = (id: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            await apiDeleteCostCenter(id);
            dispatch(setCostCenterSelected(null));
            dispatch(getCostCenters());
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get employee
 */
export const getEmployees = (): AppThunk => {
    return async (dispatch, state): Promise<void> => {
        const {
            company: { id },
        } = state();
        try {
            const response = await apiGetEmployees(id);
            dispatch(setEmployees(Array.isArray(response) ? response : []));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get active employees
 */
export const getActiveEmployees = (): AppThunk => {
    return async (dispatch, state): Promise<void> => {
        const {
            company: { id },
        } = state();
        try {
            const response = await apiGetActiveEmployees({ employer_id: id, is_active: true });
            dispatch(setActiveEmployees(Array.isArray(response) ? response : []));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to delete employee
 */
export const deleteEmployee = (employees: IEmployeesDelete): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const res = (await apiDeleteEmployees(employees)) as IResponseDeleteEmployee;
            if (res && res?.error) {
                dispatch(
                    setModalError({
                        message: res?.message,
                        open_modal: true,
                    })
                );
                return;
            }
            dispatch(getActiveEmployees());
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action get contributor
 */
export const getContributor = (values: IPayloadContributor): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            dispatch(setDisableFields(true));
            dispatch(setValidateContributor(true));

            const employee: TExistEmployee = {
                document_number: values.documentNumber,
                document_type: values.documentType,
            };

            const responseExist = await apiPostExistByDocumentNumber(employee);
            if (typeof responseExist === 'boolean' && responseExist) {
                dispatch(
                    setModalError({
                        message: EMPLOYEE_EXIST_DOCUMENT_NUMBER,
                        open_modal: true,
                    })
                );
                return;
            }

            const response = await apiGetContributor(values);

            const { documentType, documentNumber } = values;
            const documents = `${documentType} ${documentNumber}`;

            const parseErrorContributorNotRegistered = `${errorContributorNotRegistered} ${documents}`;
            const parseErrorTypeDocument = `${errorTypeDocument} ${documents}`;

            if ([parseErrorContributorNotRegistered, errorContributor].some(error => response === `${error}`)) {
                const responseBase = (await apiGetContributorBase({
                    documentNumber: documentNumber,
                    typeDocument: documentType,
                })) as IResponseBase;
                if (responseBase.error === errorBase) {
                    dispatch(setDisableFields(false));
                    dispatch(
                        setEmployeeStack({
                            TipoDocumento: values.documentType,
                            NumeroDocumento: values.documentNumber,
                        })
                    );
                    dispatch(
                        setModalError({
                            message: responseBase.error,
                            open_modal: true,
                        })
                    );
                    return;
                }
                dispatch(setDisableFields(false));
                dispatch(setEmployeeStack(responseBase.data[0]));
                return;
            }

            if (response === parseErrorTypeDocument || response === MESSAGE_UNABLE_CREDENTIALS) {
                dispatch(setDisableFields(false));
                setModalError({
                    message: response,
                    open_modal: true,
                });
                return;
            }

            dispatch(setFlagContributor(true));
            dispatch(setEmployeeStack(response));
            dispatch(setDisableFields(false));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to validate if the employee exist by email
 */
export const postExistEmployeeByEmail = async (values: TExistEmployee): Promise<boolean> => {
    try {
        const response = await apiPostExistByEmail(values);
        if (typeof response === 'boolean') return response;
        return false;
    } catch (error) {
        return false;
    }
};

/**
 * Action to validate if the change of contract is valid
 */
export const postValidateChangeContract = async (values: IValidateChangeNoveltyContract): Promise<TResult> => {
    try {
        const response = await apiPostValidateChange(values);
        return response;
    } catch (error) {
        return {
            error: false,
            message: '',
        };
    }
};

export const {
    setEmployees,
    setActiveEmployees,
    setEmployee,
    setDepartments,
    setPositions,
    setCostCenter,
    setDepartment,
    setPosition,
    setCostCenterSelected,
    setDisableFields,
    setEmployeeStack,
    setModalError,
    setFlagContributor,
    setValidateContributor,
    failedRequest,
} = employeeSlice.actions;

export default employeeSlice.reducer;
