// Libraries
import * as Yup from 'yup';
// Models
import { IValidateSalary } from '@pages/employees/models/Employee';
import { IDataCompany } from '../models/DataCompany';
// Hooks
import { useAppSelector } from '@hooks/redux';
import { RootState } from '@redux/rootReducer';
import { useValidateDocumentNumber } from '@hooks/useValidateDocumentNumber';
// Utils
import { ValidationMessage } from '@utils/Validations';

export const useValidateDataCompany = (): {
    validationSchema: Yup.SchemaOf<IDataCompany>;
    validateNit: (values: IDataCompany) => IValidateSalary | object;
} => {
    const {
        utils: { document_type },
    } = useAppSelector((state: RootState) => state);
    const { validateDocumentNumber } = useValidateDocumentNumber();

    /**
     * Validate complete form
     * @param {IDataCompany} values - Values form
     * @returns {IValidateSalary | object} error from validation
     */
    const validateNit = (values: IDataCompany): IValidateSalary | object => {
        const errors: IValidateSalary = {};
        const typeNIT = document_type?.find(type => type.code === 'NIT');

        if (!values.document_type_id) {
            errors.document_type_id = '* Campo requerido';
        } else if (!values.document_number) {
            errors.document_number = '* Campo requerido';
        } else if (values.document_type_id === typeNIT?.id && !values?.dv) {
            errors.dv = '*';
        }
        const documentNumberErrors = validateDocumentNumber(values.document_number, values.document_type_id);

        return { ...errors, ...documentNumberErrors };
    };

    const validationSchema = Yup.object().shape({
        company_name: Yup.string().required(ValidationMessage.required),
        document_type_id: Yup.string().required(ValidationMessage.required),
        document_number: Yup.string().required(ValidationMessage.required),
        dv: Yup.string().optional(),
    });

    return {
        validationSchema,
        validateNit,
    };
};
