// Libraries
import React from 'react';
// Components
import { ModalImportantInfo } from '@components/modal';
// Models
import { IModalMistakeProps } from '../models/ModalMistake';
// Styles
import './ModalMistakes.scss';

export const ModalMistakes: React.FC<IModalMistakeProps> = ({ data, isOpen, onRequestClose, handleBack }) => {
    return (
        <ModalImportantInfo isOpen={isOpen} className="modal-mistakes" onRequestClose={onRequestClose} handleBack={handleBack}>
            <div className="content__information">
                <p className="description">
                    Hemos detectado las siguientes inconsistencias en tu nómina electrónica, por favor corríjelos para poder
                    realizar la transmisión:
                </p>
                <div className="container__mistakes">
                    <table className="table-mistake">
                        <thead className="table-mistake__header">
                            <tr className="header__row">
                                <th className="column">
                                    <label className="column--title">Descripción error</label>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="table-mistake__body">
                            {data?.map((currentError, index) => (
                                <tr className="item__row" key={`${index}-error`}>
                                    <td className="column">
                                        <label className="column--text">{currentError?.error || ''}</label>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </ModalImportantInfo>
    );
};
