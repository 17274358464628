import { TEXT_ERROR_CODE } from '@constants/Plans';

export const lowerCase = (word: string): string => word?.toLowerCase();

export const upperCase = (word: string): string => word?.toUpperCase();

export const capitalizeCase = ([word, ...rest]: string, lowerRest = false): string =>
    word.toUpperCase() + (lowerRest ? rest.join('').toLowerCase() : rest.join(''));

export const capitalizeFirstLetter = (word: string): string => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase();

export const deleteSpaces = (word: string): string => word?.replace(/ /g, '');

export const getIconName = (name: string): string => {
    let result = '';
    for (let i = 0; i < name.length; i++) {
        const letter = name[i];
        result += letter === upperCase(letter) ? `-${lowerCase(letter)}` : letter;
    }
    return result;
};

export const regexContainWords = (text: string): boolean => {
    return /[a-zA-Z]/.test(text);
};

export const regexContainNumber = (text: string): boolean => {
    return /\d+/.test(text);
};

export const validateEmail = (value: string): RegExpMatchArray | null => {
    return String(value)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const addCharacter = (text: string, character: string, position: number): string => {
    let newText = '';
    const textLength = text?.length;
    for (let i = 0; i < textLength; i += position) {
        if (i + position < textLength) {
            newText += text.substring(i, i + position) + character;
        } else {
            newText += text.substring(i, textLength);
        }
    }
    return newText;
};

export const formatNitNumber = (number: string): string => {
    return addCharacter(number, '-', 9);
};

export const camelize = (str: string): string => {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, '');
};

export const startCase = (str: string): string =>
    capitalizeCase(
        str
            .split('')
            .map(letter => (letter === letter.toLocaleUpperCase() ? ` ${letter}` : letter))
            .join('')
    );

export const removeAccents = (text: string): string => {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const getIdentificationNumberNitWithOutDv = (number: string): string => {
    let newText = '';
    const index = number.indexOf('-');
    if (index === -1) return number;

    newText = number.substring(0, index).trim();
    return newText;
};

export const getMessageErrorRedeemCode = (codeError: string): string => {
    if (codeError.includes('Sorry, the code is not yet available')) {
        const indexInitDays = codeError.indexOf('(');
        const indexEndDays = codeError.indexOf(')');
        const days = codeError.substring(indexInitDays + 1, indexEndDays);
        const messageError = `El código estará disponible en ${days} días`;
        return messageError;
    }

    return TEXT_ERROR_CODE[codeError];
};
