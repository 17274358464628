export const formatNumber = (value: string | number, fixedNumber = false): string => {
    const options: Intl.NumberFormatOptions = {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: fixedNumber ? 2 : 0,
        maximumFractionDigits: fixedNumber ? 2 : 0,
    };
    return formatNumberBase(value, options);
};

export const formatCurrencyNoSymbol = (value: string | number, fixedNumber = false): string => {
    const options: Intl.NumberFormatOptions = {
        currency: 'COP',
        minimumFractionDigits: fixedNumber ? 2 : 0,
        maximumFractionDigits: fixedNumber ? 2 : 0,
    };
    return formatNumberBase(value, options);
};

export const formatNumberBase = (value: string | number, options: Intl.NumberFormatOptions): string => {
    return new Intl.NumberFormat('es-CO', options).format(Number(value));
};

export const formatNumberWithoutRound = (value: number): string => {
    const [integerPart, decimalPart] = value.toFixed(2).split('.');
    let formattedIntegerPart = '';
    let count = 0;

    for (let i = integerPart.length - 1; i >= 0; i--) {
        if (count > 0 && count % 3 === 0) {
            formattedIntegerPart = '.' + formattedIntegerPart;
        }
        formattedIntegerPart = integerPart[i] + formattedIntegerPart;
        count++;
    }

    const formattedDecimalPart = decimalPart.slice(0, 2);
    return `$ ${formattedIntegerPart},${formattedDecimalPart}`;
};

export const formatNumberPDF = (value: string | number, fixedNumber = false): string => {
    if (value && value !== 0) {
        return formatNumber(value, fixedNumber);
    }
    if (value === undefined) {
        return '';
    }
    return '$ -';
};

export const parseValue = (value: string | number): number => {
    if (typeof value === 'string') {
        if (!value.includes(',')) {
            return Number(value.split('.').join(''));
        }
        return Number(value.split('.').join('').split(',').join('.'));
    }
    return Number(value);
};

export const formatDocument = (value: string | number): string => {
    return new Intl.NumberFormat('es-CO').format(Number(value));
};

export const leadingZeros = (value: string): boolean => value?.substring(0, 1) === '0';
