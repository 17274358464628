// Libraries
import React from 'react';
// Assets
import loader from '@assets/icons/spinner-white.gif';
// Styles
import './ModalWaitResponse.scss';

export const ModalWaitResponse: React.FC = () => {
    return (
        <div className="modal-wait-response">
            <div className="modal-wait-response--container">
                <div className="custom-loader">
                    <img src={loader} alt="loader" />
                </div>
                <div className="information">
                    <p>Estamos en proceso de validación de la información registrada. </p>
                    <p>Intenta recargar la página en tu explorador o iniciar sesión nuevamente.</p>
                </div>
            </div>
        </div>
    );
};
