// Libraries
import { Dispatch, SetStateAction } from 'react';
import { type FieldInputProps, useFormikContext } from 'formik';
// Models
import { IOptionSelect } from '..';

export const useSelectInput = (): {
    getCurrentValue: (options: IOptionSelect[], value: string) => IOptionSelect;
    onChangeSelect: ({
        field,
        option,
        setSearch,
        isSearchable,
    }: {
        field: FieldInputProps<string>;
        option: IOptionSelect;
        setSearch: Dispatch<SetStateAction<string>>;
        isSearchable: boolean;
    }) => void;
    getOptions: ({
        isSearchable,
        search,
        options,
    }: {
        isSearchable: boolean;
        search: string;
        options: IOptionSelect[];
    }) => IOptionSelect[];
    handleSearchInput: (e: React.ChangeEvent<HTMLInputElement>, setSearch: Dispatch<SetStateAction<string>>) => void;
} => {
    const { setFieldValue } = useFormikContext();
    const getCurrentValue = (options: IOptionSelect[], value: string): IOptionSelect => {
        const find = options?.find(option => option.value === value);
        return find ? find : ({} as IOptionSelect);
    };

    const onChangeSelect = ({
        field,
        option,
        isSearchable,
        setSearch,
    }: {
        field: FieldInputProps<string>;
        option: IOptionSelect;
        setSearch: Dispatch<SetStateAction<string>>;
        isSearchable: boolean;
    }): void => {
        if (isSearchable) {
            setSearch('');
        }
        setFieldValue(field.name, option.value);
    };

    const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>, setSearch: Dispatch<SetStateAction<string>>): void => {
        setSearch(e.target.value);
    };

    const getOptions = ({
        isSearchable,
        search,
        options,
    }: {
        isSearchable: boolean;
        search: string;
        options: IOptionSelect[];
    }): IOptionSelect[] => {
        if (!isSearchable) {
            return options;
        }

        return options.filter(item => item.label.toLowerCase().includes(search.toLowerCase()));
    };

    return {
        getCurrentValue,
        onChangeSelect,
        getOptions,
        handleSearchInput,
    };
};
