export default {
    get: (key: string): string => localStorage[key],
    set: (key: string, value: string): void => {
        localStorage[key] = value;
    },
    getObject: (key: string): object => (localStorage[key] ? JSON.parse(localStorage[key]) : undefined),
    setObject: (key: string, value: string | object): void => {
        localStorage[key] = JSON.stringify(value);
    },
    clearKey: (key: string): void => {
        localStorage.removeItem(key);
    },
};
