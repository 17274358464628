// Libraries
import React from 'react';
// Components
import { ModalImportantInfo } from '@components/modal';
// Constants
import { CurrentInformation, getModalInformation } from '@pages/electronic-payroll/constants/InformationImportant';
// Models
import { IModalInformationImportantProps, Options } from '../models/ModalInformationImportant';
// Styles
import './ModalInformationImportant.scss';

export const ModalInformationImportant: React.FC<IModalInformationImportantProps> = ({
    isOpen,
    onRequestClose,
    handleBack,
    mainAction,
    handleOption,
    currentInformation = CurrentInformation.ENABLE_SERVICE,
    optionSelected,
    date,
    disabledConfirm = false,
}) => {
    return (
        <ModalImportantInfo
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            handleBack={handleBack}
            mainAction={mainAction}
            confirm={currentInformation !== CurrentInformation.ERROR_TRANSMISSION}
            disabledConfirm={
                ((currentInformation === CurrentInformation.GENERATE_PRIMA ||
                    currentInformation === CurrentInformation.GENERATE_CESANTIAS) &&
                    !optionSelected) ||
                disabledConfirm
            }
            className="modal-information-important"
        >
            <div className="content__information">
                {getModalInformation(date)[currentInformation]}
                {(currentInformation === CurrentInformation.GENERATE_CESANTIAS ||
                    currentInformation === CurrentInformation.GENERATE_PRIMA) && (
                    <div className="container__options">
                        <div
                            className={optionSelected === Options.MONTHLY ? 'option' : 'option--disabled'}
                            onClick={(): void => handleOption(Options.MONTHLY)}
                        >
                            Mensual
                        </div>
                        <div
                            className={optionSelected === Options.EFFECTIVE_PAYMENT ? 'option' : 'option--disabled'}
                            onClick={(): void => handleOption(Options.EFFECTIVE_PAYMENT)}
                        >
                            {currentInformation === CurrentInformation.GENERATE_PRIMA
                                ? 'Fecha efectiva de pago'
                                : 'Fecha de corte'}
                        </div>
                    </div>
                )}
            </div>
        </ModalImportantInfo>
    );
};
