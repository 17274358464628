// Libraries
import React from 'react';
import { Link } from 'react-router-dom';
// Styles
import './Remembrance.scss';
// Models
import { IRemembranceProps } from './models/Remembrance';
// Utils
import { getDaysFromTwoDates } from '@utils/Dates';
// Constants
import { DAYS_COUNTER, FIRST_DAY, MAX_DAYS, PLAN_TYPE } from './constants/Remembrance';

export const Remembrance: React.FC<IRemembranceProps> = ({ bgColor, days = 0, finishDate = '', isActive }) => {
    const daysResult = getDaysFromTwoDates('', finishDate);
    const validation = daysResult <= MAX_DAYS;

    if (!validation) return null;
    return (
        <section className={`main ${bgColor}`}>
            {isActive && daysResult > 0 ? (
                <p className="main__description">
                    En {`${DAYS_COUNTER[daysResult]} ${daysResult === FIRST_DAY ? 'día' : 'días'}`} finalizará tu plan{' '}
                    {PLAN_TYPE[days] || ''}. Recuerda hacer el pago del plan de tu preferencia nuevamente, para poder seguir
                    haciendo uso de todas las funcionalidades de la plataforma.
                </p>
            ) : (
                <p className="main__description">
                    Tu plan {PLAN_TYPE[days]} se ha vencido, para poder seguir haciendo uso de todas las funcionalidades de
                    nuestra plataforma es necesario adquirir uno nuevo
                    <Link className="button-remembrance" to="/plans">
                        Adquirir plan
                    </Link>
                </p>
            )}
        </section>
    );
};
