// Libraries
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride';
// Models
import { ITour } from '@models/Tour';
// Utils
import { getRoute } from '@utils/Paths';
import { Routes } from '@components/paths';
// Constants
import {
    STEPS,
    STEP_DROPDOWN_MENU,
    STEP_TOOLTIP,
    backSteps,
    backStepsRedirect,
    mediaQuery,
    responsiveBackRedirect,
    stepsRedirect,
} from '@constants/TourSteps';
// Redux
import { useAppDispatch, useAppSelector } from './redux';
import { setActiveTour, setGuideTour, setLoginUser } from '@redux/session/session.slice';
import { apiPutLastLogin } from '@api/session';
import { RootState } from '@redux/rootReducer';
import { showLoader } from '@redux/utils/utils.slice';

export const useTour = (): {
    tour: ITour;
    setTour: React.Dispatch<React.SetStateAction<ITour>>;
    handleCallback: (data: CallBackProps) => void;
    handleClickStart: () => void;
} => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
        session: { loginUser },
    } = useAppSelector((state: RootState) => state);
    const [tour, setTour] = useState<ITour>({
        run: false,
        stepIndex: 0,
        steps: STEPS,
        tourActive: false,
    });

    useEffect(() => {
        dispatch(setActiveTour(tour.tourActive));
    }, [tour.tourActive]);

    /**
     * Return route from step
     * @param {number} index - Index step
     */
    const handleGetRouteByIndex = (index: number, action: string): string => {
        switch (index) {
            case 2:
                return action === ACTIONS.NEXT ? getRoute(Routes.EMPLOYEES) : window.location.pathname;
            case 3:
                return action === ACTIONS.NEXT ? getRoute(Routes.PAYROLL_ADJUSTER) : getRoute(Routes.COMPANY);
            case 4:
                return getRoute(Routes.EMPLOYEES);
            case 10:
                return getRoute(Routes.PILA);
            case 11:
                return action === ACTIONS.NEXT ? getRoute(Routes.ELECTRONIC_PAYROLL) : getRoute(Routes.PAYROLL_ADJUSTER);
            case 12:
                return action === ACTIONS.NEXT ? getRoute(Routes.REPORTS) : getRoute(Routes.PILA);
            case 13:
                return action === ACTIONS.NEXT ? getRoute(Routes.HOME) : getRoute(Routes.ELECTRONIC_PAYROLL);
            case 14:
                return getRoute(Routes.REPORTS);
            case 16:
                return getRoute(Routes.COMPANY);
            case 17:
                return getRoute(Routes.HOME);
            default:
                return window.location.pathname;
        }
    };

    /**
     * Action to management steps
     * @param {CallBackProps} data - Data callback from react joyride
     */
    const handleCallback = async (data: CallBackProps): Promise<void> => {
        const { action, index, type, status } = data;
        const dateLogin = moment().format('YYYY-MM-DD hh:mm:ss');
        const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
        const menu = mediaQuery ? document.getElementById('menu__button_responsive') : document.getElementById('menu__button');
        const backTooltip = document.getElementsByClassName('label-tooltip-input')?.item(0) as HTMLElement;

        if (
            type === EVENTS.STEP_AFTER &&
            ((!stepsRedirect.includes(index) && action === ACTIONS.NEXT) ||
                (backSteps.includes(index) && action === ACTIONS.PREV))
        ) {
            if (
                nextStepIndex === STEP_DROPDOWN_MENU ||
                (mediaQuery && responsiveBackRedirect.includes(index) && action === ACTIONS.PREV)
            ) {
                menu?.click();
            }

            if (nextStepIndex === STEP_TOOLTIP && action === ACTIONS.PREV) backTooltip?.click();
            setTour({ ...tour, stepIndex: nextStepIndex });
        } else if (
            type === EVENTS.STEP_AFTER &&
            ((stepsRedirect.includes(index) && action === ACTIONS.NEXT) ||
                (backStepsRedirect.includes(index) && action === ACTIONS.PREV))
        ) {
            setTour(prevState => ({ ...prevState, run: false }));

            setTimeout(() => {
                navigate(handleGetRouteByIndex(index, action));
                if ((action === ACTIONS.PREV && nextStepIndex === STEP_DROPDOWN_MENU) || mediaQuery) menu?.click();
                setTimeout(() => {
                    const tooltip = document.getElementsByClassName('label-tooltip-input')?.item(0) as HTMLElement;
                    if (nextStepIndex === STEP_TOOLTIP && action === ACTIONS.NEXT) tooltip?.click();

                    setTour(prevState => ({ ...prevState, run: true, stepIndex: nextStepIndex }));
                }, 500);
            }, 400);
        } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
            setTour(prevState => ({ ...prevState, stepIndex: nextStepIndex }));
        } else if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
            setTour(prevState => ({ ...prevState, run: false, tourActive: false, stepIndex: 0 }));
            const response = await apiPutLastLogin<{ user_id: string }>(loginUser?.userId || '', dateLogin);
            dispatch(showLoader(true));
            dispatch(
                setLoginUser({
                    userId: response?.user_id,
                    lastLogin: dateLogin,
                })
            );
            dispatch(setGuideTour(true));
            setTimeout(() => {
                location.reload();
                dispatch(showLoader(false));
            }, 1500);
        }
    };

    /**
     * Action to start joyride
     */
    const handleClickStart = (): void => {
        navigate(getRoute(Routes.COMPANY));
        setTimeout(() => {
            setTour(prevState => ({ ...prevState, run: true, tourActive: true }));
        }, 400);
    };

    return {
        tour,
        setTour,
        handleCallback,
        handleClickStart,
    };
};
