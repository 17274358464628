// Api
import AxiosClient from '@api/axiosClient';
// Models
import {
    IPayloadOvertimeSurcharge,
    IPayloadVacationDisabilityLeaves,
    IPayloadIncomeAndDeductions,
    FormatGetTable,
    FormatNoveltyByEmployee,
    IYear,
    IRequestConcept,
    IRequestEmployeeId,
    ISavePaymentInformation,
    ICreateLiquidation,
    IYearPeriodId,
    IAdditionalUpc,
    IPayrollPaymentDate,
    IDataGetUpc,
} from '@models/PayrollAdjuster';
// Urls
import { urls } from './urls';

export const apiGetOvertimeList = <T>(): Promise<T> => AxiosClient.get(urls.utils.conceptType.type.extra_hour, {});
export const apiGetSurchargesList = <T>(): Promise<T> => AxiosClient.get(urls.utils.conceptType.type.surcharge_hour, {});
export const apiGetIncapacityList = <T>(): Promise<T> => AxiosClient.get(urls.utils.conceptType.type.incapacity, {});
export const apiGetLicenseList = <T>(): Promise<T> => AxiosClient.get(urls.utils.conceptType.type.license, {});
export const apiGetVacationList = <T>(): Promise<T> => AxiosClient.get(urls.utils.conceptType.type.vacation, {});
export const apiGetWageIncomeList = <T>(): Promise<T> => AxiosClient.get(urls.utils.conceptType.type.wageIncome, {});
export const apiGetNonWageIncomeList = <T>(): Promise<T> => AxiosClient.get(urls.utils.conceptType.type.nonWageIncome, {});
export const apiGetDeductionsList = <T>(): Promise<T> => AxiosClient.get(urls.utils.conceptType.type.deduction, {});
export const apiGetLoansList = <T>(): Promise<T> => AxiosClient.get(urls.utils.conceptType.type.loan, {});

export const apiPostOvertimeSurcharges = <T>(data: IPayloadOvertimeSurcharge[]): Promise<T> =>
    AxiosClient.post(urls.novelty.extra_Hour, data, false);

export const apiPostVacationDisabilityLeaves = <T>(data: IPayloadVacationDisabilityLeaves[]): Promise<T> =>
    AxiosClient.post(urls.novelty.absence, data, false);

export const apiPostOtherIncome = <T>(data: IPayloadIncomeAndDeductions[]): Promise<T> =>
    AxiosClient.post(urls.novelty.other_income, data, false);

export const apiPostDeductions = <T>(data: IPayloadIncomeAndDeductions[]): Promise<T> =>
    AxiosClient.post(urls.novelty.deduction, data, false);
export const apiGetAllPeriodicity = <T>(periodicityId: string): Promise<T> =>
    AxiosClient.get(urls.utils.periodicity.getByPeriodicity(periodicityId));
export const apiPostPeriodicityByYear = <T>(data: IYear): Promise<T> =>
    AxiosClient.post(urls.utils.periodicity.periodsByYear, data);
export const apiPostPayrollAdjusterTable = <T>(data: FormatGetTable): Promise<T> =>
    AxiosClient.post(urls.payrollAdjuster.payslipByPeriod, data);

// Novelties
export const apiGetNoveltyYearPeriod = (yearPeriodId: string): Promise<unknown> =>
    AxiosClient.get(urls.novelty.get(yearPeriodId), null);

export const apiGetNoveltyByEmployee = <T>(data: FormatNoveltyByEmployee): Promise<T> =>
    AxiosClient.post(urls.novelty.noveltyByEmployee, data, false);

export const apiUpdateNovelty = (
    data: IPayloadOvertimeSurcharge | IPayloadVacationDisabilityLeaves | IPayloadIncomeAndDeductions | { value: number },
    typeNovelty: string,
    idNovelty: string
): Promise<unknown> => {
    return AxiosClient.put(urls.novelty.delete(typeNovelty, idNovelty), data);
};

export const apiDeleteNovelty = (typeNovelty: string, idNovelty: string): Promise<unknown> =>
    AxiosClient.delete(urls.novelty.delete(typeNovelty, idNovelty), {});

export const apiPostCreateIncome = <T>(data: IRequestConcept): Promise<T> => AxiosClient.post(urls.utils.conceptType.post, data);

export const apiPutConcept = <T>(data: IRequestConcept, typeId: string): Promise<T> =>
    AxiosClient.put(urls.utils.conceptType.put(typeId), data);

export const apiDeleteConcept = <T>(data: IRequestConcept, typeId: string): Promise<T> =>
    AxiosClient.delete(urls.utils.conceptType.delete(typeId), data);

export const apiPostRangeDatesNovelties = <T>(periodId: string, data: IRequestEmployeeId): Promise<T> =>
    AxiosClient.post(urls.novelty.rangeDates(periodId), data);

export const apiPostCreateLiquidation = <T>(data: ICreateLiquidation): Promise<T> =>
    AxiosClient.post(urls.payslip.liquidation.createLiquidation, data, false);

export const apiPostSavePaymentInformation = <T>(data: ISavePaymentInformation): Promise<T> =>
    AxiosClient.post(urls.payslip.liquidation.savePaymentInformation, data, false);

export const apiPostSocialBenefits = <T>(data: IYearPeriodId): Promise<T> =>
    AxiosClient.post(urls.payrollAdjuster.socialBenefits, data, false);

export const apiGetUpcEmployee = <T>(data: IDataGetUpc): Promise<T> => AxiosClient.post(urls.novelty.getUpc, data, false);

export const apiPostAdditionalUpc = <T>(data: IAdditionalUpc): Promise<T> => AxiosClient.post(urls.novelty.upc, data, false);

export const apiGetPayrollPaymentDate = <T>(yearPeriodId: string): Promise<T> =>
    AxiosClient.get(urls.payrollAdjuster.mainPaymentDateById(yearPeriodId));

export const apiPostPayrollPaymentDate = <T>(data: IPayrollPaymentDate): Promise<T> =>
    AxiosClient.post(urls.payrollAdjuster.mainPaymentDate, data, false);

export const apiPutPayrollPaymentDate = <T>(yearPeriodId: string, data: IPayrollPaymentDate): Promise<T> =>
    AxiosClient.patch(urls.payrollAdjuster.mainPaymentDateById(yearPeriodId), data);
