// Redux
import { AppThunk } from '@redux/configureStore';
import { createSlice } from '@reduxjs/toolkit';
import { setPlan } from '@redux/company/company.slice';
// Types
import { IInitialPlansState, IResponseError, IResponseErrorMessage, IResponseSuccess, IResponseTransactionProps } from './types';
// Api
import {
    apiGetActivePlans,
    apiPostCreateTransaction,
    apiGetTransaction,
    apiGetPlansEmployee,
    apiPostCreateLead,
    apiPostRedeemCode,
    apiGetPromotion,
} from '@api/plans';
// Models
import { IActiveEmployeesPlans, ICreateLead, IFormikValues, IPlanEmployer, IRedeemCodeState } from '@models/Plans';
import { DataProcessing } from '@models/Onboarding';
//Pages
import { INITIAL_SELECTED_PLAN, INVALID_CODE } from '@constants/Plans';
// Utils
import { formatCreateTransactionData, formatPaymentSaveData, refactorRequestData } from './utils';
import { getTypeDocumentName } from '@utils/TypeDocuments';
import { apiPostApprovalStatus } from '@api/onboarding';
import { validateIsArray } from '@utils/Array';

const initialState: IInitialPlansState = {
    activePlan: null,
    activePlans: [],
    codeResponse: null,
    codeResponseError: '',
    plansEmployee: [],
    periodicityPlans: [],
    selectedPlan: INITIAL_SELECTED_PLAN,
    statusTransaction: '',
    showPlanLoader: false,
    showTransactionStatus: false,
    electronicPayrollConditions: [],
    paymentUrl: '',
    error: '',
};

const plansSlice = createSlice({
    name: 'plans',
    initialState,
    reducers: {
        setActivePlans: (state, action) => {
            state.activePlans = action.payload;
        },
        setPlansEmployee: (state, { payload }) => {
            state.plansEmployee = payload;
            state.activePlan = payload.find((item: IPlanEmployer) => item.is_active) || null;
        },
        setPeriodicityPlans: (state, action) => {
            state.periodicityPlans = action.payload;
        },
        setSelectedPlan: (state, action) => {
            state.selectedPlan = action.payload;
        },
        setStatusTransaction: (state, action) => {
            state.statusTransaction = action.payload;
        },
        setShowPlanLoader: (state, action) => {
            state.showPlanLoader = action.payload;
        },
        setShowTransactionStatus: (state, action) => {
            state.showTransactionStatus = action.payload;
        },
        setElectronicPayrollConditions: (state, action) => {
            state.electronicPayrollConditions = action.payload;
        },
        failedRequest: (state, action) => {
            state.error = action.payload;
        },
        setCodeResponse: (state, action) => {
            state.codeResponse = action.payload;
        },
        setPaymentUrl: (state, action) => {
            state.paymentUrl = action.payload;
        },
        setCodeResponseError: (state, action) => {
            state.codeResponseError = action.payload;
        },
    },
});

export const getActivePlans = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        const response = await apiGetActivePlans<IActiveEmployeesPlans[]>();
        dispatch(setActivePlans(refactorRequestData(response)));
    };
};

export const getPayments = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        const response = await apiGetPlansEmployee<IPlanEmployer[]>();
        const currentPlan = response.find(plan => plan.is_active);
        dispatch(setPlan(currentPlan || {}));
    };
};

export const getPlansEmployee = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        const response = await apiGetPlansEmployee<IPlanEmployer[]>();

        dispatch(setPlansEmployee(response));
    };
};

export const getStatusTransaction = async (): Promise<string | undefined> => {
    try {
        const response: string | IResponseTransactionProps = await apiGetTransaction();
        const checkResponse = response && typeof response === 'object' ? response.estado : response;
        return checkResponse;
    } catch (error) {
        throw new Error(`${error}`);
    }
};

export const createTransaction = ({
    formValues,
    action,
    actionFreeSubscription,
    ip,
}: {
    formValues: IFormikValues;
    action?: () => void;
    actionFreeSubscription?: () => void;
    ip: string;
}): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            plans: { selectedPlan, codeResponse },
            company: { information },
        } = getState();

        try {
            const response = await apiPostCreateTransaction<{ url: string; is_free_subscription: boolean }>(
                formatCreateTransactionData({
                    promotionId: codeResponse?.id || '',
                    data: formValues,
                    ip,
                    selectedPlan,
                    dataPayment: formatPaymentSaveData({ formValues, selectedPlan, information }),
                })
            );

            if (response.is_free_subscription) {
                actionFreeSubscription && actionFreeSubscription();
            }

            if (response.url) {
                dispatch(setPaymentUrl(response.url));
                action && action();
            }
        } catch (error) {
            failedRequest(error);
        }
    };
};

export const getElectronicPayrollConditions = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            company: { information },
        } = getState();
        try {
            const response: DataProcessing[] = await apiPostApprovalStatus({
                business_name: information.name ?? '',
                document_number: information.document_number ?? '',
                type_document: getTypeDocumentName(information.document_type_id || '') ?? '',
            });
            dispatch(setElectronicPayrollConditions(validateIsArray(response) ? response : []));
        } catch (error) {
            failedRequest(error);
        }
    };
};

export const createLead = (data: ICreateLead, action?: () => void): AppThunk => {
    return async (): Promise<void> => {
        try {
            await apiPostCreateLead(data);
            action && action();
        } catch (error) {
            failedRequest(error);
        }
    };
};

export const getPromotion = (periodicityId: string): AppThunk => {
    return async (): Promise<string | undefined> => {
        try {
            const data = (await apiGetPromotion(periodicityId)) as { code: string };
            if (data?.code) return data?.code;
        } catch (error) {
            failedRequest(error);
        }
    };
};

export const redeemCode = ({
    code,
    plan_id,
    setValidateStep,
    periodicity_id,
    setHandleValidateStep,
    handleOpenModalCoupon,
    handleOpenConfirmPlan,
    isCustomerDiscount = false,
}: IRedeemCodeState): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            if ((typeof isCustomerDiscount === 'boolean' && !isCustomerDiscount) || setValidateStep) {
                handleOpenModalCoupon();
                handleOpenConfirmPlan();
                return;
            }

            if (code.length === 0) {
                handleOpenModalCoupon();
                handleOpenConfirmPlan();
                return;
            }

            const response: IResponseSuccess | IResponseErrorMessage | IResponseError = await apiPostRedeemCode({
                code,
                plan_id,
                periodicity_id,
                customer_discount: typeof isCustomerDiscount === 'boolean' && isCustomerDiscount,
            });
            if (typeof response === 'string') {
                dispatch(setCodeResponseError(response));
                dispatch(setCodeResponse(''));
                setHandleValidateStep(false);
                return response;
            }
            if ('errors' in response) {
                dispatch(setCodeResponseError(INVALID_CODE));
                dispatch(setCodeResponse(''));
                setHandleValidateStep(false);
                return;
            }

            dispatch(setCodeResponse(response));
            dispatch(setCodeResponseError(''));
            setHandleValidateStep(true);
        } catch (error) {
            throw new Error(`${error}`);
        }
    };
};

export const {
    setActivePlans,
    setCodeResponse,
    setCodeResponseError,
    setPlansEmployee,
    setPeriodicityPlans,
    setSelectedPlan,
    setShowPlanLoader,
    setShowTransactionStatus,
    failedRequest,
    setStatusTransaction,
    setElectronicPayrollConditions,
    setPaymentUrl,
} = plansSlice.actions;

export default plansSlice.reducer;
