/**
 * Interface return data from validate
 * @constructor
 * @param {string} [key: string] - Value from object validation
 */
export interface IValidateSalary {
    [key: string]: string;
}

/**
 * This enum contains the codes of the document types.
 */
export enum CodesDocumentTypes {
    CD = 'CD',
    CE = 'CE',
    PT = 'PT',
    TI = 'TI',
    PA = 'PA',
    SC = 'SC',
    PE = 'PE',
    CC = 'CC',
    RC = 'RC',
    NIT = 'NIT',
}
