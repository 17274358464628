export * from './Icon';

export type ITypeExtension = 'png' | 'svg';
export type IconsNames =
    | 'checkWhiteIcon'
    | 'assistant'
    | 'addGreen'
    | 'addGreenCircle'
    | 'addWhiteCircle'
    | 'addYellowCircle'
    | 'addGreenLightCircle'
    | 'addBrownCircle'
    | 'alertMenu'
    | 'alertMenuWhite'
    | 'arrowDown'
    | 'arrowDownGray'
    | 'arrowDownWhite'
    | 'arrowDownOrange'
    | 'arrowLeftGreenLight'
    | 'arrowRightGreenLight'
    | 'backArrow'
    | 'backArrowGreen'
    | 'backArrowRoundWhite'
    | 'backArrowWhite'
    | 'brownEditPencil'
    | 'blog'
    | 'blogWhite'
    | 'buildingGreen'
    | 'buildingMenu'
    | 'buildingMenuWhite'
    | 'call'
    | 'callWhite'
    | 'calendarGray'
    | 'calendarGreen'
    | 'calendarGreenLight'
    | 'closeWhite'
    | 'calendarPriceGreen'
    | 'calendarPriceWhite'
    | 'calendarWhite'
    | 'check'
    | 'close'
    | 'closeAssistant'
    | 'closeTooltip'
    | 'closeWhite'
    | 'downloadLightGreen'
    | 'deleteDocument'
    | 'deleteDocument'
    | 'deleteDocumentGray'
    | 'downloadGreen'
    | 'downloadWhite'
    | 'documentGreen'
    | 'documentGreenLight'
    | 'documentWhite'
    | 'electronicPayrollWhite'
    | 'electronicPayrollGreen'
    | 'electronicPayrollNavGray'
    | 'electronicPayrollNavGreen'
    | 'electronicPayrollNavWhite'
    | 'email'
    | 'employeesGreen'
    | 'employeesNavGray'
    | 'employeesNavWhite'
    | 'eyeRed'
    | 'eyeGray'
    | 'eyeGreen'
    | 'eyeOrange'
    | 'eyeLightGreen'
    | 'grayEditPencil'
    | 'greenEditPencil'
    | 'homeNavGray'
    | 'homeNavGreen'
    | 'homeNavWhite'
    | 'info'
    | 'informative'
    | 'informativeGreen'
    | 'informativeGreenLight'
    | 'informativeWhite'
    | 'leave'
    | 'logoNavGreen'
    | 'logoutMenu'
    | 'logoutMenuWhite'
    | 'menuResponsiveGreen'
    | 'moneyLightGreen'
    | 'notificationMenu'
    | 'notificationMenuWhite'
    | 'paperUp'
    | 'padlock'
    | 'payrollAdjusterGreen'
    | 'payrollAdjusterNavGray'
    | 'payrollAdjusterNavWhite'
    | 'payrollAdjusterWhite'
    | 'paymentGray'
    | 'paymentGreenLight'
    | 'pilaLightGreen'
    | 'pointerWhite'
    | 'profileGreen'
    | 'profileGreenLight'
    | 'profileGray'
    | 'profileWhite'
    | 'request'
    | 'requestWhite'
    | 'reportsNavGray'
    | 'reportsNavWhite'
    | 'reportsGreen'
    | 'stackGreen'
    | 'stackNavGray'
    | 'stackNavGreen'
    | 'stackNavWhite'
    | 'searchLightGreen'
    | 'searchWhite'
    | 'simpleSearchGreen'
    | 'symbolValueWhite'
    | 'symbolValueGray'
    | 'symbolCurrency'
    | 'symbolValueGreen'
    | 'searchGreen'
    | 'trashWhite'
    | 'trashBrown'
    | 'user'
    | 'whatsapp'
    | 'whatsappWhite'
    | 'support'
    | 'supportWhite'
    | 'whiteEditPencil'
    | 'editProfile'
    | 'twitter'
    | 'linkedin'
    | 'instagram'
    | 'facebook'
    | 'checkConfirmChange'
    | 'cancelConfirmChange'
    | 'moneyLightGray';

export type IMenuPlacemnt = 'auto' | 'top' | 'bottom';

export type ColorNames = 'green' | 'green-light-3';

/**
 * These interfaces are used to icon component
 *
 * @param {(event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void} onClick - Optional prop with a function executed when icon is clicked
 * @param {string} className - Optional prop with a className for customize the icon
 * @param {IconsNames} name - Name of each icon
 * @param {string} alt - Optional prop with a name of each icon when not found them.
 * @param {IconsNames} hoverIcon - Optional prop with a name of the hover icon
 * @param {ITypeExtension} type - Optional change type extension of the icon.
 */
export interface IIconProps {
    onClick?: (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void;
    className?: string;
    name: IconsNames;
    alt?: string;
    hoverIcon?: IconsNames;
    type?: ITypeExtension;
}
