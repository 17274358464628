// Api
import AxiosClient from '@api/axiosClient';
// Urls
import { urls } from './urls';
// Models
import { IYearPeriodId } from '@models/PayrollAdjuster';
import { IFormikContributorValues } from '@pages/company/components/models/FormikConfig';

export const apiPostGetStateTemplate = (data: IYearPeriodId): Promise<unknown> =>
    AxiosClient.post(urls.pila.getState, data, false);

export const apiPostSummaryTemplatePila = (data: IYearPeriodId): Promise<unknown> =>
    AxiosClient.post(urls.pila.summary, data, false);

export const apiGetTaxPayer = (): Promise<unknown> => AxiosClient.get(urls.pila.getTaxpayer);

export const apiPostPaymentSocialSecurityPila = (data: IYearPeriodId): Promise<unknown> =>
    AxiosClient.post(urls.pila.postPaymentSocialSecurityPila, data, false);

export const apiPostCreatePilaUser = (data: IFormikContributorValues): Promise<unknown> =>
    AxiosClient.post(urls.pila.createUser, data, false);
