// Libraries
import React from 'react';
import { TooltipRenderProps } from 'react-joyride';
// Components
import { Button } from '@components/button';
import welcome from '@assets/logos/welcome.png';
import finish from '@assets/logos/finish.png';
// Styles
import './TourTooltip.scss';

export const TourTooltip: React.FC<TooltipRenderProps> = ({ backProps, index, isLastStep, primaryProps, step, tooltipProps }) => {
    return (
        <div className="tour-tooltip" {...tooltipProps}>
            {index === 0 && <img src={welcome} className="img__tooltip" />}
            <div className="container-information">
                {step?.title && (
                    <div className="container-information__header">
                        <h3 className="title--tooltip">{step.title}</h3>
                    </div>
                )}
                {step?.content && (
                    <div className={isLastStep ? 'container-information__body--finalize' : 'container-information__body'}>
                        {step.content}
                    </div>
                )}
                <div className="container-information__footer">
                    {index > 0 && (
                        <Button onClick={backProps.onClick} color="green-light-3">
                            Anterior
                        </Button>
                    )}
                    <Button color={!isLastStep ? 'green-light-3' : 'green'} onClick={primaryProps.onClick}>
                        {!isLastStep ? 'Siguiente' : 'Finalizar'}
                    </Button>
                </div>
            </div>
            {isLastStep && <img src={finish} className="img__tooltip" />}
        </div>
    );
};
