// Libraries
import { useLocation } from 'react-router-dom';
// Constants
import { pathsCustomLoader, stepsCustomLoader } from '@constants/PathsCustomLoader';

export const useIsCustomLoader = (step: number | null): { isCustomLoader: boolean } => {
    const { pathname } = useLocation();

    /**
     * Action to check is the page should have a custom loader.
     * @returns {boolean}
     */
    const checkIsCustomLoader = (): boolean =>
        stepsCustomLoader.some(value => value === step) || pathsCustomLoader?.some(route => route === pathname);

    return {
        isCustomLoader: checkIsCustomLoader(),
    };
};
