/**
 * Const with options
 */
export enum Options {
    MONTHLY = 'MONTHLY',
    EFFECTIVE_PAYMENT = 'EFFECTIVE_PAYMENT',
}

/**
 * Type options to select
 */
export type OptionSelected = Options.MONTHLY | Options.EFFECTIVE_PAYMENT;

/**
 * Interface props to show modal information important
 * @constructor
 * @param {boolean} isOpen - State to open modal
 * @param {() => void} onRequestClose - Action to open modal
 * @param {() => void} handleBack - Action to go back
 * @param {() => void} mainAction - Action to button confirm
 * @param {(currentOption: Options | null) => void} handleOption - Action to select options in modal
 * @param {string} currentInformation - Current information to show
 * @param {OptionSelected | null} optionSelected - Option selected
 * @param {boolean} disabledConfirm - State to disabled button main
 * @param {string} date - Optional date to show in text
 */
export interface IModalInformationImportantProps {
    isOpen: boolean;
    onRequestClose: () => void;
    handleBack: () => void;
    mainAction: () => void;
    handleOption: (currentOption: Options | null) => void;
    currentInformation: string;
    optionSelected: OptionSelected | null;
    disabledConfirm?: boolean;
    date?: string;
}

/**
 * Interface options information from transmit
 * @constructor
 * @param {string | null} prima - Option selected
 * @param {string | null} cesantias - Option selected
 */
export interface IOptionsInformation {
    prima: string | null;
    cesantias: string | null;
}
