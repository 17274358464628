// Libraries
import { useState } from 'react';
// Models
import { IFloatingOption } from '../models/FloatingButton';
// Utils
import { getRoute } from '@utils/Paths';
// Constants
import { Routes } from '@components/paths';
import { SHOW_INFO_SUPPORT_ASSISTANCE, SHOW_SUPPORT_BLOG, SHOW_SUPPORT_CREATE_TICKET } from '@constants/Plataform';
import { SOON_AVAILABLE } from '@constants/OtherTexts';

export const useFloatingButton = (): {
    openOptions: boolean;
    openModal: boolean;
    handleOpenModal: () => void;
    generateOptions: () => IFloatingOption[];
    handleButton: () => void;
} => {
    const [openOptions, setOpenOptions] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);

    /**
     * Action handle open modal
     */
    const handleOpenModal = (): void => {
        setOpenModal(!openModal);
    };

    /**
     * Action to redirect screen
     */
    const handleRedirect = (url: string): void => {
        window.open(url);
    };

    /**
     * Create list options
     */
    const generateOptions = (): IFloatingOption[] => {
        return [
            {
                label: 'Comunícate con un asesor',
                iconName: 'call',
                hoverIcon: 'callWhite',
                onClick: SHOW_INFO_SUPPORT_ASSISTANCE ? handleOpenModal : (): void => {},
                moreInfo: SHOW_INFO_SUPPORT_ASSISTANCE ? '' : `(${SOON_AVAILABLE})`,
            },
            {
                label: 'WhatsApp con asesor',
                iconName: 'whatsapp',
                hoverIcon: 'whatsappWhite',
                onClick: () => handleRedirect(process.env.REACT_APP_WHATSAPP_URL || ''),
            },
            {
                label: 'Registra tu solicitud',
                iconName: 'support',
                hoverIcon: 'supportWhite',
                onClick: SHOW_SUPPORT_CREATE_TICKET
                    ? (): void => handleRedirect(getRoute(Routes.REGISTER_REQUEST) || '')
                    : (): void => {},
                moreInfo: SHOW_SUPPORT_CREATE_TICKET ? '' : `(${SOON_AVAILABLE})`,
            },
            {
                label: 'Accede a nuestro blog',
                iconName: 'blog',
                hoverIcon: 'blogWhite',
                onClick: SHOW_SUPPORT_BLOG ? (): void => handleRedirect(process.env.REACT_APP_BLOG_URL || '') : (): void => {},
                moreInfo: SHOW_SUPPORT_BLOG ? '' : `(${SOON_AVAILABLE})`,
            },
        ];
    };

    /**
     * Action to close list options
     */
    const handleButton = (): void => {
        setOpenOptions(!openOptions);
        const element = document.getElementById('floating-button');
        element?.classList.toggle('floating-button--hover');
    };

    return {
        openOptions,
        openModal,
        handleOpenModal,
        generateOptions,
        handleButton,
    };
};
