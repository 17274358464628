// Components
import { IOptionSelect } from '@components/input';
// Models
import { IDeductionsLoans } from '../models/Deductions';

export const initialDeductionsLoans: IDeductionsLoans = {
    deductions: [
        {
            deductions: '',
            value: '',
            percentage: '',
            description: '',
        },
    ],
    loans: [
        {
            loans: '',
            value: '',
        },
    ],
};

export const fakeDeductionsOption: IOptionSelect[] = [
    {
        label: 'Deducciones',
        value: 'Deducciones',
    },
];

export const fakeLoansOptions: IOptionSelect[] = [
    {
        label: 'Préstamos',
        value: 'Préstamos',
    },
];

/*  
    Validate that the label is only for the first position. 
*/
export enum deductionConstants {
    FIRST_ELEMENT_SHOW = 0,
}

export const deductionsTypes: string[] = ['65620BB0-7A67-45DA-9ECC-EED943CE8AD8', 'CE0C0CCB-7453-43B3-B8A0-BC9D478F908E'];

export enum TypeSelect {
    DEDUCTIONS = 'deductions',
    LOANS = 'loans',
}

export const DEDUCTIONS_UNION_ID = 'CC329425-0F4D-4D71-A9C6-8846F45EAFB1';
export const DEDUCTIONS_FREE_TRADE_DISCOUNT = 'F4E55955-0BD7-4772-8B81-1A94689647E6';

export const TEXT_TOOLTIP_TEXT_AREA =
    'Nombre de la Libranza que corresponda a las cuotas que el empleado deba pagar a una entidad financiera, para la amortización de un crédito que le haya sido otorgado por libranza.';

export const SOCIAL_SECURITY_ITEMS_NAME = ['Salud', 'Pensión'];
