import { configureStore, ThunkAction, Action, ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { RootState, rootReducer } from './rootReducer';
import { initialMigration } from './migrations';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { payslipPeriodApi } from './rtk-query/payslip/payslipPeriodApi';
import { payslipApi } from './rtk-query/payslip/payslipApi';

const persistConfig = {
    key: 'root',
    version: 0,
    storage: storage,
    blacklist: ['payrollAdjuster', 'periods'],
    debug: true,
    stateReconciler: autoMergeLevel2,
    migrate: initialMigration,
};

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        })
            .concat(payslipPeriodApi.middleware)
            .concat(payslipApi.middleware),
});

const persistor = persistStore(store);

export { store, persistor };

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type DispatchExts = ThunkDispatch<RootState, void, AnyAction>;
