import { ICustomValidationSubContributor, TCustomValidationStepTwo } from '@pages/employees/components/models/EmployeeComponents';
import {
    APPRENTICES_IN_PRODUCTIVE_STAGE,
    APPRENTICES_IN_TRAINING_STAGE,
    PART_TIME_WORKER,
    PRISON_WORKER,
    PRIVATE_ESTABLISHMENT_TEACHER,
    TAXPAYER_DEPENDENT,
    VETERAN_DEPENDENT_OF_THE_PUBLIC_FORCE,
    contractForMoreThanOneMonth,
} from '@pages/employees/constants/Employees';

/**
 * Enum for type student contributor.
 */
export enum TypeContributorStudent {
    TYPE_STUDENT_ONE_ID = 'C36A9CAC-ABFA-43F7-8604-8AA2A3EEBB1C',
    TYPE_STUDENT_TWO_ID = '6F5E22A5-65B3-4972-87F3-469B64CEE17C',
    TYPE_STUDENT_THREE_ID = 'A5194FCE-E76C-4F57-88E8-38D5C880D295',
    TYPE_STUDENT_FOUR_ID = 'B572C3A9-50D7-4AC8-BF9A-DC4C07669850',
}

export enum SubTypeContributor {
    DEPENDENT_PENSIONED = '17824050-5966-47E8-9E2E-57952C3A6ED3',
    CONTRIBUTOR_NOT_OBLIGED_PENSIONS = '10116A8C-031D-4F6E-8AB0-89F5329A7656',
    CONTRIBUTOR_WITH_REQUIREMENTS_PENSION = '9B24F59C-4537-4A35-B0D7-A21D35314AB5',
    CONTRIBUTOR_SUBSTITUTE_COMPENSATION = 'A1983903-268B-486D-8576-0DC2A6392B69',
    CONTRIBUTOR_EXCEPTED_PENSION = '082C6A19-D7BB-419E-A64E-01ACF493FBC7',
    RETIRED_CONTRIBUTOR_ALLOWANCE_25_SMMLV = '15DE059E-DD6C-446E-94E5-E32B4CE1A047',
    DRIVERS_OF_THE_PUBLIC_SERVICE = 'C0CCAB92-533E-438E-A57B-663D3A88126D',
}

/**
 * Array for type student contributor.
 */
export const TYPE_CONTRIBUTOR_AVAILABLE_CCF = [
    TypeContributorStudent.TYPE_STUDENT_ONE_ID,
    TypeContributorStudent.TYPE_STUDENT_TWO_ID,
    TypeContributorStudent.TYPE_STUDENT_THREE_ID,
    TypeContributorStudent.TYPE_STUDENT_FOUR_ID,
];

/**
 * Array for not reder field EPS
 */
export const TYPE_CONTRIBUTOR_DISABLE_EPS = [
    TypeContributorStudent.TYPE_STUDENT_THREE_ID,
    PART_TIME_WORKER,
    PRISON_WORKER,
    SubTypeContributor.RETIRED_CONTRIBUTOR_ALLOWANCE_25_SMMLV,
];

/**
 * Array for not reder field Pension
 */

export const TYPE_CONTRIBUTOR_DISABLE_PENSION = [
    APPRENTICES_IN_TRAINING_STAGE,
    APPRENTICES_IN_PRODUCTIVE_STAGE,
    TypeContributorStudent.TYPE_STUDENT_TWO_ID,
    TypeContributorStudent.TYPE_STUDENT_THREE_ID,
    PRISON_WORKER,
    SubTypeContributor.CONTRIBUTOR_EXCEPTED_PENSION,
    SubTypeContributor.CONTRIBUTOR_NOT_OBLIGED_PENSIONS,
    SubTypeContributor.CONTRIBUTOR_SUBSTITUTE_COMPENSATION,
    SubTypeContributor.CONTRIBUTOR_WITH_REQUIREMENTS_PENSION,
    SubTypeContributor.DEPENDENT_PENSIONED,
    SubTypeContributor.DRIVERS_OF_THE_PUBLIC_SERVICE,
    SubTypeContributor.RETIRED_CONTRIBUTOR_ALLOWANCE_25_SMMLV,
];

/**
 * Array for not reder field Compensation Box
 */

export const TYPE_CONTRIBUTOR_DISABLE_COMPENSATION_BOX = [
    APPRENTICES_IN_TRAINING_STAGE,
    APPRENTICES_IN_PRODUCTIVE_STAGE,
    TypeContributorStudent.TYPE_STUDENT_TWO_ID,
    TypeContributorStudent.TYPE_STUDENT_THREE_ID,
    PRISON_WORKER,
    SubTypeContributor.CONTRIBUTOR_EXCEPTED_PENSION,
    SubTypeContributor.CONTRIBUTOR_NOT_OBLIGED_PENSIONS,
    SubTypeContributor.CONTRIBUTOR_SUBSTITUTE_COMPENSATION,
    SubTypeContributor.CONTRIBUTOR_WITH_REQUIREMENTS_PENSION,
    SubTypeContributor.DEPENDENT_PENSIONED,
    SubTypeContributor.DRIVERS_OF_THE_PUBLIC_SERVICE,
    SubTypeContributor.RETIRED_CONTRIBUTOR_ALLOWANCE_25_SMMLV,
];

export const TYPE_CONTRIBUTOR_DISABLE_RISK_ARL = [APPRENTICES_IN_TRAINING_STAGE];

/**
 * Array for render custom label
 */

export const CUSTOM_LABEL_FIELD = [contractForMoreThanOneMonth];

/**
 * Array for custom validation step two type_contributor
 */

export const CUSTOM_VALIDATE_TYPE_FUNDER: TCustomValidationStepTwo[] = [
    {
        administratorId: 'Others',
        messageError: '* Campo requerido',
        propertiesValidate: [
            {
                name: 'eps_id',
            },
            {
                name: 'pension_fund_id',
            },
            {
                name: 'type_funder_id',
            },
            {
                name: 'contributor_subtype',
            },
        ],
    },
    {
        administratorId: APPRENTICES_IN_PRODUCTIVE_STAGE,
        messageError: '* Campo requerido',
        propertiesValidate: [
            {
                name: 'type_funder_id',
            },
            {
                name: 'contributor_subtype',
            },
            {
                name: 'risk_class_id',
            },
            {
                name: 'eps_id',
            },
        ],
    },
    {
        administratorId: APPRENTICES_IN_TRAINING_STAGE,
        messageError: '* Campo requerido',
        propertiesValidate: [
            {
                name: 'eps_id',
            },
            {
                name: 'type_funder_id',
            },
            {
                name: 'contributor_subtype',
            },
        ],
    },
    {
        administratorId: TAXPAYER_DEPENDENT,
        messageError: '* Campo requerido',
        propertiesValidate: [
            {
                name: 'type_funder_id',
            },
            {
                name: 'contributor_subtype',
            },
            {
                name: 'risk_class_id',
            },
            {
                name: 'eps_id',
            },
            {
                name: 'pension_fund_id',
            },
            {
                name: 'cesantias_fund_id',
            },
            {
                name: 'compensation_box_id',
            },
        ],
    },
    {
        administratorId: TypeContributorStudent.TYPE_STUDENT_THREE_ID,
        messageError: '* Campo requerido',
        propertiesValidate: [
            {
                name: 'risk_class_id',
            },
            {
                name: 'type_funder_id',
            },
            {
                name: 'contributor_subtype',
            },
        ],
    },
    {
        administratorId: TypeContributorStudent.TYPE_STUDENT_TWO_ID,
        messageError: '* Campo requerido',
        propertiesValidate: [
            {
                name: 'risk_class_id',
            },
            {
                name: 'eps_id',
            },
            {
                name: 'type_funder_id',
            },
            {
                name: 'contributor_subtype',
            },
        ],
    },
    {
        administratorId: TypeContributorStudent.TYPE_STUDENT_ONE_ID,
        messageError: '* Campo requerido',
        propertiesValidate: [
            {
                name: 'risk_class_id',
            },
            {
                name: 'eps_id',
            },
            {
                name: 'pension_fund_id',
            },
            {
                name: 'type_funder_id',
            },
            {
                name: 'contributor_subtype',
            },
        ],
    },
    {
        administratorId: PART_TIME_WORKER,
        messageError: '* Campo requerido',
        propertiesValidate: [
            {
                name: 'pension_fund_id',
            },
            {
                name: 'risk_class_id',
            },
            {
                name: 'type_funder_id',
            },
            {
                name: 'contributor_subtype',
            },
            {
                name: 'compensation_box_id',
            },
        ],
    },
    {
        administratorId: PRIVATE_ESTABLISHMENT_TEACHER,
        messageError: '* Campo requerido',
        propertiesValidate: [
            {
                name: 'type_funder_id',
            },
            {
                name: 'contributor_subtype',
            },
            {
                name: 'risk_class_id',
            },
            {
                name: 'eps_id',
            },
            {
                name: 'pension_fund_id',
            },
            {
                name: 'cesantias_fund_id',
            },
            {
                name: 'compensation_box_id',
            },
        ],
    },
    {
        administratorId: contractForMoreThanOneMonth,
        messageError: '* Campo requerido',
        propertiesValidate: [
            {
                name: 'type_funder_id',
            },
            {
                name: 'contributor_subtype',
            },
            {
                name: 'risk_class_id',
            },
        ],
    },
    {
        administratorId: PRISON_WORKER,
        messageError: '* Campo requerido',
        propertiesValidate: [
            {
                name: 'type_funder_id',
            },
            {
                name: 'contributor_subtype',
            },
            {
                name: 'risk_class_id',
            },
        ],
    },
    {
        administratorId: VETERAN_DEPENDENT_OF_THE_PUBLIC_FORCE,
        messageError: '* Campo requerido',
        propertiesValidate: [
            {
                name: 'type_funder_id',
            },
            {
                name: 'contributor_subtype',
            },
            {
                name: 'risk_class_id',
            },
            {
                name: 'eps_id',
            },
            {
                name: 'pension_fund_id',
            },
        ],
    },
];

export const CUSTOM_VALIDATE_SUB_TYPE_CONTRIBUTOR: TCustomValidationStepTwo[] = [
    {
        administratorId: SubTypeContributor.DEPENDENT_PENSIONED,
        messageError: '* Campo requerido',
        propertiesValidate: [
            {
                name: 'type_funder_id',
            },
            {
                name: 'contributor_subtype',
            },
            {
                name: 'risk_class_id',
            },
            {
                name: 'eps_id',
            },
        ],
    },
    {
        administratorId: SubTypeContributor.CONTRIBUTOR_NOT_OBLIGED_PENSIONS,
        messageError: '* Campo requerido',
        propertiesValidate: [
            {
                name: 'type_funder_id',
            },
            {
                name: 'contributor_subtype',
            },
            {
                name: 'risk_class_id',
            },
            {
                name: 'eps_id',
            },
        ],
    },
    {
        administratorId: SubTypeContributor.CONTRIBUTOR_WITH_REQUIREMENTS_PENSION,
        messageError: '* Campo requerido',
        propertiesValidate: [
            {
                name: 'type_funder_id',
            },
            {
                name: 'contributor_subtype',
            },
            {
                name: 'risk_class_id',
            },
            {
                name: 'eps_id',
            },
        ],
    },
    {
        administratorId: SubTypeContributor.CONTRIBUTOR_SUBSTITUTE_COMPENSATION,
        messageError: '* Campo requerido',
        propertiesValidate: [
            {
                name: 'type_funder_id',
            },
            {
                name: 'contributor_subtype',
            },
            {
                name: 'risk_class_id',
            },
            {
                name: 'eps_id',
            },
        ],
    },
    {
        administratorId: SubTypeContributor.CONTRIBUTOR_EXCEPTED_PENSION,
        messageError: '* Campo requerido',
        propertiesValidate: [
            {
                name: 'type_funder_id',
            },
            {
                name: 'contributor_subtype',
            },
            {
                name: 'risk_class_id',
            },
            {
                name: 'eps_id',
            },
        ],
    },
    {
        administratorId: SubTypeContributor.RETIRED_CONTRIBUTOR_ALLOWANCE_25_SMMLV,
        messageError: '* Campo requerido',
        propertiesValidate: [
            {
                name: 'type_funder_id',
            },
            {
                name: 'contributor_subtype',
            },
            {
                name: 'risk_class_id',
            },
        ],
    },
    {
        administratorId: SubTypeContributor.DRIVERS_OF_THE_PUBLIC_SERVICE,
        messageError: '* Campo requerido',
        propertiesValidate: [
            {
                name: 'type_funder_id',
            },
            {
                name: 'contributor_subtype',
            },
            {
                name: 'risk_class_id',
            },
            {
                name: 'eps_id',
            },
        ],
    },
];

export const CUSTOM_VALIDATE_EXCEPTION_SUB_TYPE_CONTRIBUTOR: ICustomValidationSubContributor[] = [
    {
        administratorId: PART_TIME_WORKER,
        messageError: '* Campo requerido',
        propertiesValidate: [
            {
                name: 'type_funder_id',
            },
            {
                name: 'contributor_subtype',
            },
            {
                name: 'risk_class_id',
            },
        ],
        subContributorIds: [
            SubTypeContributor.CONTRIBUTOR_EXCEPTED_PENSION,
            SubTypeContributor.CONTRIBUTOR_NOT_OBLIGED_PENSIONS,
            SubTypeContributor.CONTRIBUTOR_SUBSTITUTE_COMPENSATION,
            SubTypeContributor.CONTRIBUTOR_WITH_REQUIREMENTS_PENSION,
            SubTypeContributor.DEPENDENT_PENSIONED,
            SubTypeContributor.DRIVERS_OF_THE_PUBLIC_SERVICE,
            SubTypeContributor.RETIRED_CONTRIBUTOR_ALLOWANCE_25_SMMLV,
        ],
    },
];
