// Api
import AxiosClient from '@api/axiosClient';
// Models
import { ICreateCompany, IGetCurrentCiiu, IUpdateUser, TUpdateCompany } from '@models/Company';
// Urls
import { urls } from './urls';
import { RequestDueDiligence } from '@redux/company/types';

export const apiHomeInformation = (yearPeriodId: string): Promise<unknown> => AxiosClient.get(urls.company.home(yearPeriodId));
export const apiGetCompany = <T>(employerId: string): Promise<T> => AxiosClient.get(urls.company.uniqueCompany(employerId));
export const apiGetPeriodicity = (): Promise<unknown> => AxiosClient.get(urls.utils.periodicity.getSelect);
export const apiGetCiiu = <T>(data: IGetCurrentCiiu): Promise<T> => AxiosClient.post(urls.company.ciiu, data);
export const apiGetEmployees = (id: string): Promise<unknown> => AxiosClient.get(urls.company.employees(id));
export const apiPostCompany = <T>(data: ICreateCompany): Promise<T> => AxiosClient.post(urls.company.employers, data);
export const apiPutCompany = <T>(companyId: string, data: TUpdateCompany): Promise<T> =>
    AxiosClient.put(urls.company.uniqueCompany(companyId), data);
export const apiPutAdb2cCompany = <T>(companyId: string, data: IUpdateUser): Promise<T> =>
    AxiosClient.put(urls.company.uniqueCompany(companyId), data);
export const apiGetDueDiligence = <T>(data: RequestDueDiligence): Promise<T> =>
    AxiosClient.get(urls.onboarding.dueDiligence(data));
