export const ARRAY_DYNAMIC_INTEGRAL_SALARY_WAGE_VALUES = ['year', 'year_variable_type', 'year_variable'];
export const SALARY_CODE = 'SALARY';
export const COLOMBIA_COUNTRY = 'Colombia';
export const MIN_COLOMBIA_SALARY = 1160000;
export const BANK_OTHER_ID = '76324D89-B73B-4A94-8B7B-0B24DF6AC8ED';
export const CURRENT_MONTH = 1;
export enum typesAddress {
    first = 1,
    second = 2,
    cardinal = 3,
}
