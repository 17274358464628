// Libraries
import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { ControlledMenu, MenuItem, useClick } from '@szhsin/react-menu';
// Components
import { Icon } from '@components/icon';
import { useActiveLink } from '@components/navbar/hooks/useActiveLink';
// Hooks
import { useGetNavItems } from '@components/navbar/hooks/useGetNavItems';
// Models
import { IItems, INavItems } from '@components/navbar/models/Navbar';
import { INavMenu } from '../models/NavbarMenu';
// Constants
import { STEP_RESPONSIVE_WITHOUT_MENU, mediaQuery } from '@constants/TourSteps';
// Styles
import './NavbarResponsive.scss';

export const NavBarResponsive: React.FC<INavMenu> = ({
    logOut,
    information,
    menuState,
    toggleMenu = (): void => {},
    indexStepTour = 0,
}) => {
    const ref = useRef(null);
    const anchorProps = useClick(menuState?.state, toggleMenu);
    const [toggle, setToggle] = useState<boolean>(false);
    const { generateNavItems, generateProfileItems } = useGetNavItems(true);

    useEffect(() => {
        if (mediaQuery && indexStepTour && STEP_RESPONSIVE_WITHOUT_MENU.includes(indexStepTour)) {
            toggleMenu(false);
        }
    }, [indexStepTour]);

    return (
        <div className="navbar-responsive">
            <button id="menu__button_responsive" className="menu__button" data-testid="menu__item" ref={ref} {...anchorProps}>
                <Icon name="menuResponsiveGreen" type="svg" />
            </button>
            <ControlledMenu
                menuClassName="menu"
                id="menu-responsive"
                anchorRef={ref}
                {...menuState}
                onClose={(e): void => {
                    if (STEP_RESPONSIVE_WITHOUT_MENU.includes(indexStepTour) && e.reason === 'blur') toggleMenu(false);
                }}
            >
                <div className="container-profile">
                    <MenuItem className="menu__profile" onClick={(): void => setToggle(!toggle)}>
                        <Icon name="buildingMenuWhite" className="menu__profile--main-icon" />
                        <span id="title">{information?.name || ''}</span>
                        <Icon
                            name="arrowDownWhite"
                            className={`menu__profile--arrow-icon ${toggle && 'menu__profile--arrow-rotate'}`}
                        />
                    </MenuItem>

                    <div className={`menu__item ${toggle ? 'menu__item-open' : 'menu__item-close'}`}>
                        {toggle && (
                            <div className="container-link">
                                {generateProfileItems().map(({ name, route, icon }: IItems, index: number) => (
                                    <React.Fragment key={`item-${index}`}>
                                        <MenuItem
                                            key={name}
                                            onClick={(e): void => {
                                                e.keepOpen = false;
                                                e.stopPropagation = true;
                                                setToggle(!toggle);
                                            }}
                                        >
                                            <NavLink className="link-profile" to={route} id="item-nav">
                                                <Icon name={icon} />
                                                {name}
                                            </NavLink>
                                        </MenuItem>
                                    </React.Fragment>
                                ))}
                                <MenuItem
                                    className="link-profile"
                                    onClick={(e): void => {
                                        e.keepOpen = false;
                                        e.stopPropagation = true;
                                        logOut();
                                    }}
                                >
                                    <Icon name="logoutMenuWhite" />
                                    Cerrar sesión
                                </MenuItem>
                            </div>
                        )}
                    </div>
                </div>
                {generateNavItems().map(({ name, route, icon }: INavItems) => {
                    const { active } = useActiveLink(route);
                    return (
                        <MenuItem
                            key={name}
                            className="menu__item"
                            onClick={(e): void => {
                                e.keepOpen = false;
                                e.stopPropagation = true;
                                setToggle(!toggle);
                            }}
                        >
                            <NavLink className={`link ${active && 'link-active'}`} to={route}>
                                <Icon name={icon} />
                                {name}
                            </NavLink>
                        </MenuItem>
                    );
                })}
            </ControlledMenu>
        </div>
    );
};
