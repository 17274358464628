// Libraries
import { useEffect, useState } from 'react';
import { useMenuState } from '@szhsin/react-menu';
// Models
import { IStateNavMenu } from '../models/Navbar';
// Constants
import { MenuStates } from '../constants/MenuStates';

export const useNavBar = (
    isCustomLoader: boolean
): {
    menuState: IStateNavMenu;
    toggleMenu: (open: boolean) => void;
    applyZIndex: boolean;
} => {
    const [menuState, toggleMenu] = useMenuState({ transition: true });
    const [applyZIndex, setApplyZIndex] = useState<boolean>(false);

    useEffect(() => {
        setApplyZIndex(checkApplyZIndex());
    }, [menuState?.state, isCustomLoader]);

    /**
     * Action to check if the navbar items needs a z-index.
     * @returns {boolean}
     */
    const checkApplyZIndex = (): boolean => {
        if (!menuState?.state && isCustomLoader) {
            return true;
        }
        return menuState?.state === MenuStates.CLOSE && isCustomLoader;
    };

    return {
        menuState,
        toggleMenu,
        applyZIndex,
    };
};
