// Libraries
import moment from 'moment';
// Redux
import { AppThunk } from '@redux/configureStore';
import { createSlice } from '@reduxjs/toolkit';
// Api
import { apiPostPeriodicityByYear } from '@api/payrollAdjuster';
import { apiGetPeriodById, apiGetPeriodsByYear } from '@api/utils';
// Types
import { IPeriodsState } from './types';
// Constants
import { PeriodicityTypes } from '@constants/Periodicity';
// Models
import { IPeriods } from '@models/Periods';

const initialState: IPeriodsState = {
    periods: [],
    monthlyPeriods: [],
    currentPeriod: {
        id: '',
        code: '',
        name: '',
        start_date: '',
        end_date: '',
        year_month_id: '',
        periodicity_id: '',
        yearPeriods: [],
        period: {
            id: '',
            code: '',
            name: '',
            start_date: '',
            end_date: '',
            year_month_id: '',
            periodicity_id: '',
        },
        created_at: '',
        updated_at: '',
        year_id: '',
    },
    currentIndex: null,
    error: '',
};

const periodsSlice = createSlice({
    name: 'periods',
    initialState,
    reducers: {
        setPeriods: (state, action) => {
            state.periods = action.payload;
        },
        setCurrentPeriod: (state, action) => {
            state.currentPeriod = action.payload;
        },
        resetCurrentPeriod: state => {
            state.currentPeriod = state.periods[moment().month()];
        },
        setMonthlyPeriods: (state, action) => {
            state.monthlyPeriods = action.payload;
        },
        setCurrentIndex: (state, action) => {
            state.currentIndex = action.payload;
        },
        failedRequest: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const getApiCarouselPeriods = async (
    periodicity_id: string,
    currentPeriod: IPeriods
): Promise<{ periods: IPeriods[]; period: IPeriods }> => {
    const newData = {
        start_date: moment().format('YYYY-01-01'),
        end_date: moment().format('YYYY-12-31'),
        periodicity_id: periodicity_id ?? PeriodicityTypes.MONTHLY,
    };

    const currentMonth = moment().month();
    const currentPeriods = await apiPostPeriodicityByYear<IPeriods[]>(newData);
    const periods = currentPeriods.map(value => ({
        ...value,
        period: (value.yearPeriods && value.yearPeriods[0]) ?? {},
        indexMonth: currentMonth,
    }));
    const period =
        !currentPeriod?.period?.id || Object.keys(currentPeriod).length === 1 ? periods[moment().month()] : currentPeriod;

    return {
        periods,
        period,
    };
};

export const getCarouselPeriods = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            company: {
                information: { periodicity_id },
            },
            periods: { currentPeriod },
        } = getState();

        try {
            const { periods, period } = await getApiCarouselPeriods(periodicity_id || '', currentPeriod);
            dispatch(setPeriods(periods));
            dispatch(setCurrentPeriod(period));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const getPeriodById = (id: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetPeriodById<IPeriods>(id);
            dispatch(setCurrentPeriod({ ...response, indexMonth: moment(response.end_date).month() }));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const getAllMonthlyPeriod = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetPeriodsByYear(moment().year().toString());
            dispatch(setMonthlyPeriods(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const { setPeriods, setCurrentPeriod, resetCurrentPeriod, setMonthlyPeriods, setCurrentIndex, failedRequest } =
    periodsSlice.actions;

export default periodsSlice.reducer;
