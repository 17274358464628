// Libraries
import React from 'react';
import moment from 'moment';
// Components
import { Icon } from '@components/icon';
import { SubTitle } from '@components/title';
import { ButtonSectionIcon } from '@components/button';
import { BannerPlan } from '@components/banner-plan';
import { InformativeDate } from '../informative-date/InformativeDate';
// Hooks
import { useInitializeData } from '../hooks/useInitializeData';
// Utils
import { capitalizeCase } from '@utils/Text';
import { formatCurrencyNoSymbol } from '@utils/Number';
// Styles
import './InsidePlatform.scss';
import { TooltipInformation } from '@components/tooltip';
import { TOOLTIP_INFORMATION } from '@pages/home/constants/InsidePlatform';
import { Remembrance } from '@pages/home/components';
import { DueDiligenceNotificationBanner } from '@pages/home/components/due-diligence/dueDiligenceNotificationBanner';
import { useEmployee } from '@pages/employees/hooks/useEmployee';
import { CreateEmployee } from '@pages/employees/components';
import { useReinitializeDetails } from '@pages/employees/hooks/useInitialData';

export const InsidePlatform: React.FC = () => {
    const {
        data,
        information,
        informativeDate,
        employees,
        planEmployee,
        plansEmployee,
        handleLiquidation,
        handleShowInformativeDate,
        handleStartTour,
        handleYearPeriodText,
        showDueDiligenceBanner,
        dueDiligenceLink,
    } = useInitializeData();

    const { createEmployee, handleModal, selectedEmployee, editEmployee, handleActionEmployeeById, viewEmployee } = useEmployee();

    const { clearDetail } = useReinitializeDetails();

    return (
        <>
            <CreateEmployee
                edit={editEmployee}
                showModal={createEmployee}
                handleModal={handleModal}
                actionModal={(): void => {
                    handleModal();
                    clearDetail();
                }}
                employee={selectedEmployee}
                isEditLiquidator
                defaultStep={1}
                viewEmployee={viewEmployee}
            />

            <div className="inside-platform flex-column">
                {plansEmployee?.length > 1 && plansEmployee.every(({ is_active }) => !is_active) && (
                    <Remembrance
                        days={plansEmployee[plansEmployee.length - 1].total_months}
                        isActive={false}
                        finishDate="2024-01-01"
                    />
                )}
                {showDueDiligenceBanner && <DueDiligenceNotificationBanner link={dueDiligenceLink} />}
                {planEmployee?.is_active && employees?.length <= 0 && <BannerPlan months={planEmployee?.total_months} />}
                <div className="inside-platform__header">
                    <div className="container__title flex-row">
                        <h1 className="title">Bienvenido {information?.name || ''}</h1>
                    </div>
                    <div className="container__pointer" id="pointer" onClick={handleStartTour}>
                        <div className="container__icon">
                            <Icon name="pointerWhite" className="pointer--icon" />
                        </div>
                        <div className="container__text">
                            <label className="text">Conoce nuestro portal</label>
                        </div>
                    </div>
                </div>
                <div className="inside-platform__body flex-column">
                    <div className="summary-month__container flex-column">
                        <SubTitle title={`Resumen ${capitalizeCase(moment().format('MMMM YYYY'))}`} className="title" />
                        <div className="container__main-card flex-row">
                            <div className="main-card flex-row">
                                <div className="main-card--column flex-column">
                                    <label className="column--title">Total empleados liquidados </label>
                                    <div className="value__container flex-row">
                                        <label className="value">{data?.month_summary?.pay_off_employees || 0}</label>
                                    </div>
                                </div>
                                <div className="main-card--column flex-column">
                                    <label className="column--title">Nuevos ingresos</label>
                                    <div className="value__container flex-row">
                                        <label className="value">{data?.month_summary?.new_incomes || 0}</label>
                                    </div>
                                </div>
                                <div className="main-card--column flex-column">
                                    <label className="column--title">Total contratos terminados</label>
                                    <div className="value__container flex-row">
                                        <label className="value">{data?.month_summary?.finished_contracts || 0}</label>
                                    </div>
                                </div>
                                <div className="main-card--column flex-column">
                                    {informativeDate && <InformativeDate handleClose={handleShowInformativeDate} />}
                                    <label className="column--title">
                                        Contratos próximos a vencer
                                        <TooltipInformation
                                            textTooltip={TOOLTIP_INFORMATION}
                                            iconName="informativeGreen"
                                            typeIcon="svg"
                                            classNameIcon="inside-platform icon--tooltip"
                                            placement="top-end"
                                            classNameTooltip="max-width-inside-platform"
                                        />
                                    </label>
                                    <div className="value__container flex-row">
                                        <label className="other--value">
                                            {data?.month_summary?.next_contracts_to_due?.length || 0}
                                        </label>
                                        {!!data?.month_summary?.next_contracts_to_due.length && (
                                            <ul className="list--dates flex-column">
                                                {data?.month_summary?.next_contracts_to_due?.map(contract => (
                                                    <li
                                                        className="date"
                                                        key={`contract-${contract.employee_id}`}
                                                        onClick={(): void => handleActionEmployeeById(contract.employee_id)}
                                                    >
                                                        {`${contract.employee_name || ''} ${contract.finish_contract_date || ''}`}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="total-payments__container">
                        <SubTitle title="Total Pagos" className="title" />
                        <div className="cards__container flex-row">
                            <div className="card__container flex-column">
                                <label className="card--title">Total pago de nómina/{handleYearPeriodText()}</label>
                                <div className="container__price flex-row">
                                    <Icon name="symbolValueWhite" className="icon--width" type="svg" />
                                    <label className="value">
                                        {formatCurrencyNoSymbol(data?.total_pays?.total_payslip || 0)}
                                    </label>
                                </div>
                            </div>
                            <div className="card__container flex-column">
                                <label className="card--title">Total pago seguridad social/{handleYearPeriodText()}</label>
                                <div className="container__price flex-row">
                                    <Icon name="symbolValueWhite" className="icon--width" type="svg" />
                                    <label className="value">
                                        {formatCurrencyNoSymbol(data?.total_pays?.total_social_security || 0)}
                                    </label>
                                </div>
                            </div>
                            <div className="card__container flex-column">
                                <label className="card--title">Total pago prestaciones sociales</label>
                                <div className="container__price flex-row">
                                    <Icon name="symbolValueWhite" className="icon--width" type="svg" />
                                    <label className="value">
                                        {formatCurrencyNoSymbol(data?.total_pays?.total_social_benefits || 0)}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="inside-platform__footer flex-row">
                    <ButtonSectionIcon
                        iconName="calendarPriceGreen"
                        iconClassName="icon--width"
                        typeIcon="svg"
                        onClick={handleLiquidation}
                    >
                        Liquidar mi Nómina
                    </ButtonSectionIcon>
                </div>
            </div>
        </>
    );
};
