// Libraries
import { AppThunk } from '@redux/configureStore';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
// Redux
import { setInformation } from '@redux/company/company.slice';
import { setPeriods as setPeriodsReport } from '@redux/reports/reports.slice';
import { showLoader } from '@redux/utils/utils.slice';
// Types
import { IPilaState } from './types';
// Api
import {
    apiGetTaxPayer,
    apiPostCreatePilaUser,
    apiPostPaymentSocialSecurityPila,
    apiPostSummaryTemplatePila,
    apiPostGetStateTemplate,
} from '@api/pila';
import { apiGetPeriodsByYear } from '@api/utils';
import { apiGetAllPeriodicity } from '@api/payrollAdjuster';
// Constant
import { INITIAL_DATE } from '@pages/payroll-adjuster/constants/InitialDate';
import { PeriodicityTypes } from '@constants/Periodicity';
import { OBJECT, SUCCESS_USER_PILA } from '@pages/pila-detail/constants/PilaDetail';
import { ZERO } from '@constants/Arrays';
// Models
import { IPeriods } from '@models/Periods';
import { IErrorPilaUser, IResponsePaymentSocialSecurity } from '@models/Pila';
import { IFormikContributorValues } from '@pages/company/components/models/FormikConfig';

const initialState: IPilaState = {
    pila: [],
    periods: [],
    showCustomLoader: false,
    currentPeriod: {
        id: '',
        indexMonth: INITIAL_DATE,
        code: '',
        name: '',
        start_date: '',
        end_date: '',
        year_month_id: '',
        periodicity_id: '',
        yearPeriods: [],
        period: {
            id: '',
            code: '',
            name: '',
            start_date: '',
            end_date: '',
            year_month_id: '',
            periodicity_id: '',
        },
        created_at: '',
        updated_at: '',
    },
    modalPaymentSocialSecurity: {
        visible: false,
        response: '',
    },
    stateTemplate: '',
    templateSelected: null,
    taxPayer: null,
    error: '',
    reliquidate: false,
    completedLiquidation: false,
};

const pilaSlice = createSlice({
    name: 'pila',
    initialState,
    reducers: {
        setPeriods: (state, action) => {
            state.periods = action.payload;
        },
        setPeriod: (state, action) => {
            state.currentPeriod = { ...state.currentPeriod, period: action.payload };
        },
        setCurrentPeriod: (state, action) => {
            state.currentPeriod = action.payload;
        },
        setPila: (state, action) => {
            state.pila = action.payload;
        },
        setModalPaymentSocialSecurity: (state, action) => {
            state.modalPaymentSocialSecurity = action.payload;
        },
        setTemplateSelected: (state, action) => {
            state.templateSelected = action.payload;
        },
        setTaxPayer: (state, action) => {
            state.taxPayer = action.payload;
        },
        setStateTemplate: (state, action) => {
            state.stateTemplate = action.payload;
        },
        setCustomLoader: (state, action) => {
            state.showCustomLoader = action.payload;
        },
        setReliquidate: (state, action) => {
            state.reliquidate = action.payload;
        },
        setCompletedLiquidation: (state, action) => {
            state.completedLiquidation = action.payload;
        },
        failedRequest: (state, action) => {
            state.error = action.payload;
        },
    },
});

/**
 * Action to get periods by periodicity.
 */
export const getAllPeriodsInPila = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            company: { information },
            payrollAdjuster: { periods },
        } = getState();

        if (!periods.length) {
            try {
                const response = await apiGetAllPeriodicity<IPeriods[]>(information?.periodicity_id ?? PeriodicityTypes.MONTHLY);
                const periods = response.map(value => ({
                    ...value,
                    period: (value.yearPeriods && value.yearPeriods[INITIAL_DATE]) ?? {},
                    indexMonth: INITIAL_DATE,
                }));
                dispatch(setPeriods(periods));
                dispatch(setCurrentPeriod(periods[INITIAL_DATE]));
            } catch (error) {
                dispatch(failedRequest('Error request data'));
            }
        }
    };
};

/**
 * Action to get state summary templates pila
 */
export const postGetState = (id: string): AppThunk => {
    return async (dispatch): Promise<void | unknown> => {
        try {
            const data = await apiPostGetStateTemplate({
                year_period_id: id,
            });
            dispatch(setStateTemplate(data));
            return data;
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get summary templates pila
 */
export const postDataPila = (id: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            if (id) {
                const data = await apiPostSummaryTemplatePila({
                    year_period_id: id,
                });
                dispatch(setPila(Array.isArray(data) ? data : []));
                dispatch(setCustomLoader(false));
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get tax payer
 */
export const getTaxPayer = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            company: {
                information: { pila_user },
            },
        } = getState();
        try {
            if (pila_user) {
                const response = await apiGetTaxPayer();
                dispatch(setTaxPayer(response));
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const createPilaUser = (
    data: IFormikContributorValues,
    handleOpenErrorModal: (errors: IErrorPilaUser[]) => void,
    handleCloseRegister: () => void,
    handleConfirmModal: () => void,
    resetForm: () => void
): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const { company } = getState();
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response: any = await apiPostCreatePilaUser(data);
            if (Array.isArray(response)) {
                handleOpenErrorModal(response);
                return;
            }
            if (Array.isArray(response?.errors)) {
                handleOpenErrorModal(response.errors);
                return;
            }
            if (response === SUCCESS_USER_PILA) {
                handleConfirmModal();
            }
            handleCloseRegister();
            resetForm();
            dispatch(setInformation({ ...company.information, pila_user: data.user.user_name }));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to payment social security pila
 */
export const postPaymentSocialSecurityPila = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            pila: {
                currentPeriod: { id },
            },
        } = getState();
        try {
            const response = await apiPostPaymentSocialSecurityPila({
                year_period_id: id,
            });

            if (typeof response === OBJECT) {
                const responsePayload = (response as IResponsePaymentSocialSecurity[])[ZERO];
                dispatch(setModalPaymentSocialSecurity({ visible: true, response: responsePayload }));
            } else {
                dispatch(setModalPaymentSocialSecurity({ visible: true, response: response }));
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get all periods for pila
 */
export const getPeriodsPilaByYear = (
    handleStateTemplate: (id: string, intervalId?: NodeJS.Timer) => void,
    isOpenPagePila = false
): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            periods: { currentIndex },
        } = getState();
        const currentYear = moment().year();
        try {
            showLoader(true);
            const response = await apiGetPeriodsByYear<IPeriods[]>(String(currentYear));
            if (!Array.isArray(response)) {
                dispatch(setPeriodsReport([]));
                return;
            }
            dispatch(setPeriodsReport(response));
            const indexMonth = isOpenPagePila ? moment().month() : currentIndex || moment().month();
            const intervalId = setInterval(async () => {
                await handleStateTemplate(response[indexMonth].id, intervalId);
            }, 2000);
            showLoader(false);
        } catch (error) {
            showLoader(false);
            dispatch(failedRequest('Error request'));
        }
    };
};

export const {
    setPeriods,
    setPeriod,
    setCurrentPeriod,
    setPila,
    setTemplateSelected,
    setTaxPayer,
    setModalPaymentSocialSecurity,
    setStateTemplate,
    setCustomLoader,
    setReliquidate,
    setCompletedLiquidation,
    failedRequest,
} = pilaSlice.actions;

export default pilaSlice.reducer;
