// Libraries
import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
// Components
import { IOptionSelect } from '@components/input';
// Hooks
import { useAppDispatch, useAppSelector } from '@hooks/redux';
// Models
import { IInitEmployee } from '@models/Employee';
import { IWorkCenter } from '@models/TaxPayer';
// Redux
import { RootState } from '@redux/rootReducer';
import { getTaxPayer } from '@redux/pila/pila.slice';

export const useTaxPayer = (): {
    branchOptions: IOptionSelect[];
    workCenterOptions: IOptionSelect[];
    pila_user: string | undefined;
    handleTypeBranchChange: (branchCode: string) => void;
    handleTypeWorkCenter: (workCenterCode: string) => void;
} => {
    const dispatch = useAppDispatch();
    const {
        pila: { taxPayer },
        company: {
            information: { pila_user },
        },
        utils: { ciius_complete, riskClass: risk_class },
        employee: { employee_stack },
    } = useAppSelector((state: RootState) => state);
    const [branchOptions, setBranchOptions] = useState<IOptionSelect[]>([]);
    const [workCenterOptions, setWorkCenterOptions] = useState<IOptionSelect[]>([]);
    const [workCenter, setWorkCenter] = useState<IWorkCenter[]>([]);

    const { values, setFieldValue } = useFormikContext<IInitEmployee>();

    useEffect(() => {
        dispatch(getTaxPayer());
    }, []);

    useEffect(() => {
        if (pila_user && taxPayer) {
            const optionsTaxPayer = taxPayer?.sucursales?.map(taxPayer => {
                return {
                    label: taxPayer.nombre || '',
                    value: taxPayer.codigo || '',
                };
            });
            setBranchOptions(optionsTaxPayer || []);
        }

        if (employee_stack?.CodigoSucursal) {
            handleTypeBranchChange(employee_stack?.CodigoSucursal);
        }

        if (values?.name_branch) {
            handleTypeBranchChange(values?.name_branch);
        }
    }, [taxPayer]);

    const handleTypeBranchChange = (branchCode: string): void => {
        const arrayWorkCenter = taxPayer?.sucursales?.find(branch => branch.codigo === branchCode)?.centrotrabajo;
        const optionWorkCenter = arrayWorkCenter?.map(workCenter => {
            return {
                label: workCenter.nombre || '',
                value: workCenter.codigo || '',
            };
        });
        setWorkCenter(arrayWorkCenter ?? []);
        setWorkCenterOptions(optionWorkCenter || []);
    };

    const handleTypeWorkCenter = (workCenterCode: string): void => {
        const currentWorkCenter = workCenter.find(workCenter => workCenter.codigo === workCenterCode);

        if (currentWorkCenter) {
            const economicSubactivity = currentWorkCenter?.subactividad_economica ?? '';
            const riskRateWorkCenter = currentWorkCenter?.tarifariesgo ?? 0;
            const autoSelectCiiu = ciius_complete.find(ciiu => ciiu.ciiu_complete === economicSubactivity);
            const autoSelectTarifa = risk_class.find(riskClass => riskClass.risk_rate === riskRateWorkCenter);
            setFieldValue('ciiu_code', autoSelectCiiu?.ciiu_complete);
            setFieldValue('risk_class_id', autoSelectTarifa?.id);
        }
    };

    useEffect(() => {
        if (employee_stack && taxPayer?.sucursales) {
            const arrWorkCenter = taxPayer?.sucursales.find(
                branch => branch.codigo === employee_stack.CodigoSucursal
            )?.centrotrabajo;
            if (arrWorkCenter) {
                const currentWorkCenter = arrWorkCenter.find(workCenter => workCenter.codigo === values.work_center);
                if (currentWorkCenter) {
                    const economicSubactivity = currentWorkCenter?.subactividad_economica ?? '';
                    const riskRateWorkCenter = currentWorkCenter?.tarifariesgo ?? 0;
                    const autoSelectCiiu = ciius_complete.find(ciiu => ciiu.ciiu_complete === economicSubactivity);
                    const autoSelectTarifa = risk_class.find(riskClass => riskClass.risk_rate === riskRateWorkCenter);
                    setFieldValue('ciiu_code', autoSelectCiiu?.ciiu_complete);
                    setFieldValue('risk_class_id', autoSelectTarifa?.id);
                }
            }
        }
    }, [employee_stack, taxPayer]);

    return {
        branchOptions,
        workCenterOptions,
        pila_user,
        handleTypeBranchChange,
        handleTypeWorkCenter,
    };
};
