import React from 'react';
// Hooks
import { useStepFour } from '@pages/employees/hooks/useStepFour';
// Constants
import { TooltipTexts } from './constants/TooltipTexts';
// Components
import { Input, SelectInput } from '@components/input';
// Models
import { IStepFourProps } from '@pages/employees/components/models/EmployeeComponents';

export const StepFour: React.FC<IStepFourProps> = ({ disabled }) => {
    const { bankAccountOptions, bankOptions, paymentMethodOptions } = useStepFour();
    return (
        <div className="scroll--page">
            <div className="padding--container">
                <h3 className="title--container">Información de pago</h3>
            </div>
            <div className="form__container mt--container">
                <div className="container_mb">
                    <SelectInput
                        name="payment_method_id"
                        label="Método de pago"
                        options={paymentMethodOptions}
                        maxMenuHeight={135}
                        isSearchable
                        tooltip
                        disabled={disabled}
                        textTooltip={TooltipTexts.PAYMENT_METHOD}
                    />
                </div>
                <div className="container_mb">
                    <SelectInput
                        name="bank_id"
                        label="Banco"
                        options={bankOptions}
                        maxMenuHeight={135}
                        isSearchable
                        disabled={disabled}
                    />
                </div>
                <div className="container_mb">
                    <SelectInput
                        name="bank_account_type_id"
                        label="Tipo de cuenta"
                        options={bankAccountOptions}
                        isSearchable
                        menuPlacement="top"
                        disabled={disabled}
                    />
                </div>
                <div className="container_mb">
                    <Input name="account_number" label="Número de cuenta" disabled={disabled} maxLength={20} type="number" />
                </div>
            </div>
        </div>
    );
};
