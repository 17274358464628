// Constants
import { PeriodicityTypes } from '@constants/Periodicity';

export const isMonthly = (periodicity: string | undefined): boolean => {
    if (!periodicity) {
        return true;
    }
    const periodicityMonthly = PeriodicityTypes.MONTHLY.toString().toLowerCase();
    return periodicity.toLocaleLowerCase() === periodicityMonthly;
};
