// Libraries
import React from 'react';
// Models
import { IBannerPlanProps } from './models/BannerPlan';
// Styles
import './BannerPlan.scss';

export const BannerPlan: React.FC<IBannerPlanProps> = ({ months }) => {
    return (
        <div className="banner-plan">
            <div className="banner">
                <p className="banner--text">
                    ¡Bienvenido a <span className="banner--bold">Nómina en Línea!</span> Recuerda que tu plan seleccionado hace
                    efecto desde el momento de realizaste la compra. Por lo tanto cuentas con &nbsp;
                    <span className="banner--months">{months && months > 1 ? `${months} meses` : `${months || ''} mes`}</span>
                    &nbsp; para hacer uso de la totalidad del mismo
                </p>
            </div>
        </div>
    );
};
