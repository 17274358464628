// Libraries
import React from 'react';

export enum RouteType {
    PUBLIC = 'public',
    PRIVATE = 'private',
}

/**
 * Type path properties
 * @constructor
 * @param { string } route - Path route redirect
 * @param { React.ReactElement } component - Component to show from this path
 * @param { RouteType } type - Type route from user
 * @param { string } title - Title to show in each path
 */
export type path = {
    route: string;
    component: React.ReactElement;
    type: RouteType;
    title: string;
};

/**
 * This interface describes structure for paths
 * @constructor
 * @param {[string]: path} path - component private or public route
 */
export interface IPath {
    [path: string]: path;
}
