import { OBJECT, FUNCTION } from '@constants/OtherTexts';

export const isEmpty = (value: object): boolean => {
    const type = typeof value;
    if ((value !== null && type === OBJECT) || type === FUNCTION) {
        const props = Object.keys(value) as unknown as {
            length: number;
            size: number;
        };
        if (props.length === 0 || props.size === 0) return true;
    }
    return !value;
};
