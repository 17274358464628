// Libraries
import React from 'react';
import { Swiper, SwiperProps } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import moment from 'moment';
// Components
import { Icon } from '@components/icon';
// Hooks
import { useAppSelector } from '@hooks/redux';
import { RootState } from '@redux/rootReducer';
// Models
import { ICarouselPeriodsProps } from '@pages/payroll-adjuster/components/models/PayrollAdjusterComponents';
// Constants
import { PeriodIndex } from '@constants/Periodicity';
// Styles
import './Carousel.scss';
// Utils
import { CURRENT_MONTH } from '@constants/Utils';

export const Carousel: React.FC<SwiperProps> = props => {
    return <Swiper {...props}>{props.children}</Swiper>;
};

export const CarouselPeriods: React.FC<ICarouselPeriodsProps> = ({
    data,
    handleChange,
    handlePeriod,
    currentDate,
    emptyMessage,
    onUpdate,
    isPilaModule = false,
}) => {
    const { yearMonthlyPeriodId } = useAppSelector((state: RootState) => state.payrollAdjuster);

    const validateIndexMonth = typeof currentDate?.indexMonth === 'number' ? currentDate.indexMonth < 3 : false;

    return (
        <div className="carousel-periods">
            <div className="carousel-periods__container">
                <Carousel
                    initialSlide={
                        isPilaModule
                            ? yearMonthlyPeriodId
                                ? validateIndexMonth
                                    ? currentDate?.indexMonth
                                    : CURRENT_MONTH
                                : CURRENT_MONTH
                            : currentDate?.indexMonth ?? moment().month()
                    }
                    onSlideChange={(swiper): void => handleChange(swiper?.activeIndex)}
                    pagination={{
                        clickable: true,
                        dynamicBullets: true,
                    }}
                    navigation
                    allowTouchMove
                    modules={[Pagination, Navigation]}
                    onUpdate={(swiper): void => {
                        if (onUpdate) {
                            swiper.activeIndex = moment().month();
                        }
                    }}
                    observer
                    className="mySwiper"
                >
                    {data.length ? (
                        data.map(period => (
                            <SwiperSlide key={period.id} className="mySwiper">
                                <div className="period-card">
                                    <div className="period-card__header">
                                        <label className="title--card">Período de liquidación</label>
                                    </div>
                                    <div className="period-card__body">
                                        <div className={`month-date__container py-card`}>
                                            <Icon className="card--icon" name="calendarGreen" />
                                            <label className="date">{period.name}</label>
                                        </div>
                                        {period?.yearPeriods?.length > 1 && (
                                            <div className="period-liquidation__container">
                                                <div className="period">
                                                    <label
                                                        className={`text--first text--${
                                                            currentDate?.period
                                                                ? currentDate?.yearPeriods &&
                                                                  currentDate?.yearPeriods[PeriodIndex.MONTHLY]?.id ===
                                                                      currentDate?.period?.id
                                                                    ? 'active'
                                                                    : 'disabled'
                                                                : 'active'
                                                        }`}
                                                        onClick={(): void =>
                                                            handlePeriod(currentDate?.yearPeriods[PeriodIndex.MONTHLY])
                                                        }
                                                    >
                                                        Primera quincena
                                                    </label>
                                                </div>
                                                <div className="period" />
                                                <div className="period">
                                                    <label
                                                        className={`text--second text--${
                                                            currentDate?.yearPeriods &&
                                                            currentDate?.yearPeriods[PeriodIndex.FORTNIGHTLY]?.id ===
                                                                currentDate?.period?.id
                                                                ? 'active'
                                                                : 'disabled'
                                                        }`}
                                                        onClick={(): void =>
                                                            handlePeriod(currentDate?.yearPeriods[PeriodIndex.FORTNIGHTLY])
                                                        }
                                                    >
                                                        Segunda quincena
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    ) : (
                        <>
                            {emptyMessage && (
                                <SwiperSlide className="mySwiper">
                                    <div className="period-card">
                                        <div className="period-card__header">
                                            <label className="title--card">Período de liquidación</label>
                                        </div>
                                        <div className="period-card__body">
                                            <div className={`month-date__container py-card`}>
                                                <Icon className="card--icon" name="calendarGreen" />
                                                <label className="date">Selecciona un año</label>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )}
                        </>
                    )}
                </Carousel>
            </div>
        </div>
    );
};
