// Libraries
import { Dispatch, SetStateAction } from 'react';
// Redux
import { AppThunk } from '@redux/configureStore';
import { createSlice } from '@reduxjs/toolkit';
// Api
import {
    apiGetCompany,
    apiGetEmployees,
    apiGetPeriodicity,
    apiPostCompany,
    apiPutCompany,
    apiHomeInformation,
    apiPutAdb2cCompany,
} from '@api/company';
// Models
import { ICreateCompany, IUpdateUser, TUpdateCompany } from '@models/Company';
import { ICompanyForm } from '@pages/company/models/UserForm';
import { ICompanyPlan } from '@models/Plans';
import { ICountry, ICountryStateDuediligence } from '@models/Utils';
// Types
import { ICompanyState, ResponseDueDiligence, RequestDueDiligence, ResponseEmployer } from './types';
import { apiGetCompensationOptions } from '@api/utils';
import { getAllPeriods, setPeriods } from '@redux/payroll-adjuster/payrollAdjuster.slice';
import { THREE, errorMessageBlockedAccount } from '@constants/Api';
import { apiGetDueDiligence } from '@api/company';
import { removeAccents } from '@utils/Text';

const initialState: ICompanyState = {
    id: '',
    funder_id: '',
    compensation_id: '',
    compensation_options: [],
    employees: '',
    information: {},
    periodicity: [],
    home_information: null,
    modal_error: {
        message: '',
        open_modal: false,
    },
    error_pila_count: 0,
    modal_error_user_pila: false,
    plan: {} as ICompanyPlan,
    error: '',
    duediligenciie: {} as ResponseDueDiligence,
    load_data_duediligence: false,
};

const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        setInformation: (state, action) => {
            state.information = action.payload;
        },
        setEmployerId: (state, action) => {
            state.id = action.payload;
            localStorage.setItem('EMPLOYER_ID', action.payload);
        },
        setPeriodicity: (state, action) => {
            state.periodicity = action.payload;
        },
        setFunderId: (state, action) => {
            state.funder_id = action.payload;
        },
        setCompensationBoxId: (state, action) => {
            state.compensation_id = action.payload;
        },
        setEmployees: (state, action) => {
            state.employees = action.payload.employees_number;
        },
        setCompensationOptions: (state, action) => {
            state.compensation_options = action.payload;
        },
        setHomeInformation: (state, action) => {
            state.home_information = action.payload;
        },
        setOpenModalError: (state, action) => {
            state.modal_error = action.payload;
        },
        setErrorPilaCount: (state, action) => {
            state.error_pila_count = action.payload;
        },
        setModalErrorUserPila: (state, action) => {
            state.modal_error_user_pila = action.payload;
        },
        setPlan: (state, action) => {
            state.plan = action.payload;
        },
        setCountryState: (state, action) => {
            state.information.state_id = action.payload;
        },
        setCountryStateCity: (state, action) => {
            state.information.city_id = action.payload;
        },
        setLoadDataDuediligence: (state, action) => {
            state.load_data_duediligence = action.payload;
        },
        setDueDiligenceInfo: (state, action) => {
            state.duediligenciie = action.payload;
            state.information.address =
                action.payload?.direccion !== null && state.information.address === null
                    ? action.payload?.direccion
                    : state.information.address;
            state.information.phone =
                action.payload?.telefono !== null && state.information.phone === null
                    ? action.payload?.telefono
                    : state.information.phone;
            state.information.constitution_date =
                action.payload?.fechaConstitucion !== null && state.information.constitution_date === null
                    ? action.payload?.fechaConstitucion
                    : state.information.constitution_date;
            state.information.ciiu_code =
                action.payload?.actividadEconomica != null && state.information.ciiu_code === null
                    ? action.payload?.actividadEconomica
                          .toString()
                          .substring(0, action.payload?.actividadEconomica.indexOf('-'))
                          .trim()
                    : state.information.ciiu_code;
        },
        failedRequest: (state, action) => {
            state.error = action.payload;
        },
    },
});

/**
 * Action get information company.
 * @constructor
 * @param {string} id - Current company id.
 * @returns {AppThunk}
 */
export const getInformationCompany = (id: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetCompany(id);
            dispatch(setInformation(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};
/**
 * Action get information company.
 * @constructor
 * @param {string} id - Current company id.
 * @returns {AppThunk}
 */
export const getEmployeesCompany = (id: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetEmployees(id);
            dispatch(setEmployees(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 *  This action create a employer/company.
 * @constructor
 * @param {ICompanyForm} data - Current data without format for request.
 * @param {Dispatch<SetStateAction<boolean>} setEdit - Set state edit form.
 * @param {(data: ICompanyForm, customCiiu: string) => ICreateCompany} formatCreateEmployer - Function format data request.
 * @returns {AppThunk}
 */
export const createEmployer = (
    data: ICompanyForm,
    setEdit: Dispatch<SetStateAction<boolean>>,
    formatCreateEmployer: (data: ICompanyForm) => ICreateCompany
): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            company: { error_pila_count },
        } = getState();

        try {
            const response = await apiPostCompany<ResponseEmployer>(formatCreateEmployer(data));

            if (typeof response === 'string') {
                if (!error_pila_count || error_pila_count < THREE) {
                    dispatch(setErrorPilaCount(error_pila_count + 1));
                }
                dispatch(
                    setOpenModalError({
                        message: error_pila_count === THREE ? errorMessageBlockedAccount : response,
                        open_modal: true,
                    })
                );
                return;
            }

            dispatch(setErrorPilaCount(0));
            dispatch(getInformationCompany(response.id));
            dispatch(setEmployerId(response.id));
            dispatch(setFunderId(response.type_funder_id));
            dispatch(setCompensationBoxId(response.compensation_box_id));
            dispatch(getAllPeriods());
            setEdit(false);
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 *  This action create a employer/company.
 * @constructor
 * @param {ICompanyForm} data - Current data without format for request.
 * @param {Dispatch<SetStateAction<boolean>} setEdit - Set state edit form.
 * @param {(data: ICompanyForm, customCiiu: string) => ICreateCompany} formatUpdateEmployer - Action update employer.
 * @returns {AppThunk}
 */
export const updateEmployer = (
    data: ICompanyForm,
    setEdit: Dispatch<SetStateAction<boolean>>,
    formatUpdateEmployer: (data: ICompanyForm) => TUpdateCompany,
    edit?: boolean
): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            company: { id, error_pila_count },
        } = getState();
        try {
            const response = await apiPutCompany<ResponseEmployer>(id, formatUpdateEmployer(data));
            if (typeof response === 'string') {
                if (error_pila_count < THREE) {
                    dispatch(setErrorPilaCount(error_pila_count + 1));
                }

                dispatch(
                    setOpenModalError({
                        message: error_pila_count === THREE ? errorMessageBlockedAccount : response,
                        open_modal: true,
                    })
                );
                return;
            }

            dispatch(setErrorPilaCount(0));
            if ((response as ResponseEmployer).id) {
                dispatch(getInformationCompany(response.id));
                dispatch(setFunderId(response.type_funder_id));
                dispatch(setCompensationBoxId(response.compensation_box_id));
                dispatch(setPeriods([]));
                dispatch(getAllPeriods());
                setEdit(edit || false);
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const updateEmployerAdb2c = (data: IUpdateUser): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            company: { id },
        } = getState();
        try {
            const response = await apiPutAdb2cCompany<ResponseEmployer>(id, data);

            dispatch(setErrorPilaCount(0));
            if ((response as ResponseEmployer).id) dispatch(getInformationCompany(response.id));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action get listo of periodicity's
 * @constructor
 * @returns {AppThunk}
 */
export const getPeriodicity = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetPeriodicity();
            dispatch(setPeriodicity(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action get listo of periodicity's
 * @constructor
 * @param {string} departmentId - Current department id.
 * @returns {AppThunk}
 */
export const getCompensationOptions = (departmentId = ''): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            if (departmentId) {
                const response = await apiGetCompensationOptions(departmentId);
                dispatch(setCompensationOptions(response));
            } else {
                dispatch(setCompensationOptions([]));
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action get home information company
 * @returns data information company
 */
export const getHomeInformation = (id: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiHomeInformation(id);
            dispatch(setHomeInformation(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action get home information to DueDiligence
 * @returns data information DueDiligence
 */
export const getDuediligence = (
    typeDocument: string | undefined,
    numberDocument: string | undefined,
    country_state: ICountry[],
    departments_duediligence: ICountryStateDuediligence,
    county_state_city: ICountry[],
    cities_duediligence: ICountryStateDuediligence
): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            if (typeDocument && numberDocument) {
                const parameters: RequestDueDiligence = {
                    TipoIdentificacion: typeDocument,
                    NumeroIdentificacion: numberDocument,
                };
                const response = await apiGetDueDiligence(parameters);
                dispatch(setDueDiligenceInfo(response));
                dispatch(getDepartmentByDeparmentDueDiligence(response, country_state, departments_duediligence));
                dispatch(getCityByCityDueDiligence(response, county_state_city, cities_duediligence));
                dispatch(setLoadDataDuediligence(true));
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action update information state id
 * @returns void
 */
export const updateStateId = (id: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            dispatch(setCountryState(id));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action update information city id
 * @returns void
 */
export const updateCityId = (id: string): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            dispatch(setCountryStateCity(id));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action get state by department duediligence
 * @returns void
 */
export const getDepartmentByDeparmentDueDiligence = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    duediligence: any,
    country_state: ICountry[],
    departments_duediligence: ICountryStateDuediligence
): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            if (duediligence?.departamento === null || duediligence?.departamento === '') return;

            const nameDepartment = removeAccents(duediligence?.departamento).trim().toUpperCase();
            let departmentNominaEnLinea: string | undefined = country_state.find(
                x => removeAccents(x.name).trim().toUpperCase() === nameDepartment
            )?.id;
            if (departmentNominaEnLinea === undefined || departmentNominaEnLinea === null) {
                departmentNominaEnLinea = departments_duediligence[nameDepartment];
                departmentNominaEnLinea = country_state.find(
                    x =>
                        removeAccents(x.name).trim().toUpperCase() ===
                        removeAccents(departmentNominaEnLinea ? departmentNominaEnLinea : '')
                            .trim()
                            .toUpperCase()
                )?.id;
            }

            if (departmentNominaEnLinea === undefined) return;
            dispatch(updateStateId(departmentNominaEnLinea));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action get city by city duediligence
 * @returns void
 */
export const getCityByCityDueDiligence = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    duediligence: any,
    county_state_city: ICountry[],
    cities_duediligence: ICountryStateDuediligence
): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            if (duediligence?.ciudad === null || duediligence?.ciudad === '') return;

            const nameCity = removeAccents(duediligence?.ciudad).trim().toUpperCase();
            let cityNominaEnLinea: string | undefined = county_state_city.find(
                x => removeAccents(x.name).trim().toUpperCase() === nameCity
            )?.id;
            if (cityNominaEnLinea === undefined || cityNominaEnLinea === null) {
                cityNominaEnLinea = cities_duediligence[nameCity];
                cityNominaEnLinea = county_state_city.find(
                    x =>
                        removeAccents(x.name).trim().toUpperCase() ===
                        removeAccents(cityNominaEnLinea ? cityNominaEnLinea : '')
                            .trim()
                            .toUpperCase()
                )?.id;
            }

            if (cityNominaEnLinea === undefined) return;
            dispatch(updateCityId(cityNominaEnLinea));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const {
    setInformation,
    setEmployerId,
    setFunderId,
    setCompensationBoxId,
    setPeriodicity,
    setCompensationOptions,
    setEmployees,
    setHomeInformation,
    setOpenModalError,
    setModalErrorUserPila,
    setErrorPilaCount,
    setPlan,
    setDueDiligenceInfo,
    setCountryState,
    setCountryStateCity,
    setLoadDataDuediligence,
    failedRequest,
} = companySlice.actions;

export default companySlice.reducer;
