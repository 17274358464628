// Libraries
import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { MenuItem, MenuDivider, ControlledMenu, useClick } from '@szhsin/react-menu';
// Hooks
import { useGetNavItems } from '@components/navbar/hooks/useGetNavItems';
// Components
import { Icon } from '@components/icon';
// Models
import { INavMenu } from '../models/NavbarMenu';
// Constants
import { STEP_DROPDOWN_MENU, mediaQuery } from '@constants/TourSteps';
// Style
import './NavMenu.scss';

export const NavMenu: React.FC<INavMenu> = ({
    logOut = (): void => {},
    menuState,
    toggleMenu = (): void => {},
    indexStepTour = 0,
}) => {
    const { generateProfileItems } = useGetNavItems();
    const ref = useRef(null);
    const anchorProps = useClick(menuState?.state, toggleMenu);

    useEffect(() => {
        if (!mediaQuery && indexStepTour !== STEP_DROPDOWN_MENU) {
            toggleMenu(false);
        }
    }, [indexStepTour]);

    return (
        <div className="nav-menu">
            <button id="menu__button" className="menu__button" ref={ref} {...anchorProps}>
                <Icon name="arrowDownWhite" className={`menu__icon ${menuState?.state === 'open' && 'menu__icon--rotate'}`} />
            </button>
            <ControlledMenu
                menuClassName="menu"
                id="menu"
                anchorRef={ref}
                {...menuState}
                onClose={(): void => {
                    if (indexStepTour !== STEP_DROPDOWN_MENU) toggleMenu(false);
                }}
            >
                {generateProfileItems().map(({ name, route, icon }) => (
                    <React.Fragment key={name}>
                        <NavLink to={route} className="menu__link">
                            <MenuItem className="menu__item" id="item-nav">
                                <Icon name={icon} />
                                {name}
                            </MenuItem>
                        </NavLink>
                        <MenuDivider className="menu__divider" />
                    </React.Fragment>
                ))}
                <MenuItem className="menu__item menu__link" onClick={logOut}>
                    <Icon name="logoutMenu" />
                    Cerrar sesión
                </MenuItem>
            </ControlledMenu>
        </div>
    );
};
