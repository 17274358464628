// Redux
import { RootState } from '@redux/rootReducer';
// Hooks
import { useAppSelector } from '@hooks/redux';
// Models
import { CodesDocumentTypes, IValidateSalary } from '@models/ValidateSalary';

/**
 * Validate document number by document type id
 * @param {string | undefined} value - Current value document number
 * @param {string} documentTypeId - Id document type selected
 * @returns {IValidateSalary | object} error from validation
 */
export const useValidateDocumentNumber = (): {
    validateDocumentNumber: (value: string | undefined, documentTypeId: string) => IValidateSalary | object;
} => {
    const { document_type } = useAppSelector((state: RootState) => state.utils);

    const validateDocumentNumber = (value: string | undefined, documentTypeId: string): IValidateSalary | object => {
        const prueba = document_type || [];
        const currentCode = prueba?.find(element => element.id === documentTypeId)?.code;

        const errors: IValidateSalary = {};
        switch (currentCode) {
            case CodesDocumentTypes.CC:
                if (!value) {
                    errors.document_number = '* Campo requerido';
                } else if (!/^(?=^(?!0)[0-9]*$)(?:.{3,8}|.{10})$/i.test(value)) {
                    errors.document_number =
                        'El número de identificación sólo debe contener números, su longitud debe estar entre 3 y 10 caracteres, no puede ser de 9 y tampoco puede iniciar por 0.';
                }
                return errors;
            case CodesDocumentTypes.CE:
                if (!value) {
                    errors.document_number = '* Campo requerido';
                } else if (!/^[1-9]{1}[0-9]{6,8}$/i.test(value)) {
                    errors.document_number =
                        'El número de identificación sólo debe contener números, su longitud debe estar entre 7 y 9 caracteres pero no puede iniciar por 0.';
                }
                return errors;
            case CodesDocumentTypes.NIT:
                if (!value) {
                    errors.document_number = '* Campo requerido';
                } else if (!/^(?=^(?!0)[0-9]*$)(?:.{3,10})$/i.test(value)) {
                    errors.document_number =
                        'La longitud del número de identificación debe estar entre 3 y 10 caracteres y no puede iniciar por 0.';
                }
                return errors;
            case CodesDocumentTypes.TI:
                if (!value) {
                    errors.document_number = '* Campo requerido';
                } else if (!/^(?=^(?!0)[0-9]*$)(?:.{10,11})$/i.test(value)) {
                    errors.document_number =
                        'La longitud del número de identificación debe estar entre 10 y 11 caracteres y no puede iniciar por 0.';
                }
                return errors;
            case CodesDocumentTypes.PA:
                if (!value) {
                    errors.document_number = '* Campo requerido';
                } else if (!/^[A-Za-z0-9Ññ]{3,16}$/i.test(value)) {
                    errors.document_number =
                        'El número de identificación sólo debe contener letras y números, su longitud debe estar entre 3 y 16 caracteres.';
                } else if (!/\d+/.test(value)) {
                    errors.document_number = 'Debe contener al menos un número.';
                }

                return errors;
            case CodesDocumentTypes.CD:
                if (!value) {
                    errors.document_number = '* Campo requerido';
                } else if (!/^(?=^(?!0)[A-Za-z0-9Ññ]*$)(?:.{1,16})$/i.test(value)) {
                    errors.document_number =
                        'El número de identificación sólo debe contener letras y números, su longitud debe estar entre 1 y 16 caracteres y no puede iniciar por 0.';
                }
                return errors;
            case CodesDocumentTypes.SC:
                if (!value) {
                    errors.document_number = '* Campo requerido';
                } else if (!/^(?=^(?!0)[0-9]*$)(?:.{7,9})$/i.test(value)) {
                    errors.document_number =
                        'El número de identificación sólo debe contener números, su longitud debe estar entre 7 y 9 caracteres pero no puede iniciar por 0.';
                }
                return errors;
            case CodesDocumentTypes.PE:
                if (!value) {
                    errors.document_number = '* Campo requerido';
                } else if (!/^[0-9]{15}$/i.test(value)) {
                    errors.document_number =
                        'El número de identificación sólo debe tener números y su longitud debe ser de 15 caracteres.';
                }
                return errors;
            case CodesDocumentTypes.PT:
                if (!value) {
                    errors.document_number = '* Campo requerido';
                } else if (!/^[1-9]{1}[0-9]{1,7}$/i.test(value)) {
                    errors.document_number =
                        'El número de identificación sólo debe contener números, su longitud mínima debe ser de 2 y máximo 8 caracteres, pero no puede iniciar por 0.';
                }
                return errors;
            case CodesDocumentTypes.RC:
                if (!value) {
                    errors.document_number = '* Campo requerido';
                } else if (!/^[1-9][0-9]{9,10}$/i.test(value)) {
                    errors.document_number =
                        'La longitud del número de identificación debe estar entre 10 y 11 caracteres y no puede iniciar por 0.';
                }
                return errors;
            default:
                if (!documentTypeId) {
                    errors.document_type_id = '* Campo requerido';
                } else if (!value) {
                    errors.document_number = '* Campo requerido';
                }
                return errors;
        }
    };
    return { validateDocumentNumber };
};
