import React from 'react';

export const informationSteps = [
    {
        title: 'Descarga y pago PILA',
        number: [1, 2],
        description: [
            <>
                Has terminado el proceso de liquidación de nómina de tus empleados, ahora puedes descargar tu planilla PILA. Ten
                presente que el cálculo se realizará directamente por Aportes en Línea, implementando toda la regulación vigente.
            </>,
            <>
                Para hacer tu pago, deberás validar que toda la información sea correcta. Dado que de ello dependerá la generación
                de la planilla de seguridad social. Una vez termines de realizar el pago de la planilla, podrás ir visualizando el
                histórico de las planillas pagadas y el certificado de aportes por empleado en la sección de Reportes.
            </>,
        ],
    },
    {
        title: 'Modificaciones',
        number: [3, 4],
        description: [
            <>
                Si requieres realizar alguna <strong>modificación a la información de la planilla antes del pago,</strong> deberás
                regresar al módulo <strong>Liquidación de Nómina,</strong> realizar los cambios deseados y finalizar volviendo
                al&nbsp; <strong>módulo PILA.</strong>
            </>,
            <>
                Frente a inconsistencias de planilla pagada, debes hacer los cambios en el portal Pymes o aportesenlinea.com. Ten
                en cuenta que las modificaciones realizadas en esas plataformas no se actualizarán en este portal, por lo tanto
                debes realizar la respectiva actualización.
            </>,
        ],
    },
];

export const MESSAGE_ERROR = 'Queue not found';

export enum InformationSteps {
    FIRST_SECTION,
    SECOND_SECTION,
}
