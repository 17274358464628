import { IPlanTypeProps } from '../models/Remembrance';

/**
 * This constants describe number of mouths is the plan.
 */
export const PLAN_TYPE: IPlanTypeProps = {
    1: 'mensual',
    3: 'trimestral',
    6: 'semenstral',
    12: 'anual',
};

/**
 * This constants describe date to complete plan.
 */
export const DAYS_COUNTER: IPlanTypeProps = {
    1: 'un',
    2: 'dos',
    3: 'tres',
};

/**
 * This constants describe max days
 */
export const MAX_DAYS = 3;

/**
 * This constants describe first day
 */
export const FIRST_DAY = 1;
