// Libraries
import React from 'react';
// Components
import { Icon } from '@components/icon';
import { Modal } from '@components/modal';
// Hooks
import { useFloatingButton } from './hooks/useFloatingButton';
// Styles
import './FloatingButton.scss';

export const FloatingButton: React.FC = () => {
    const { openOptions, openModal, handleOpenModal, generateOptions, handleButton } = useFloatingButton();

    return (
        <>
            <Modal isOpen={openModal} onRequestClose={handleOpenModal} className="modal-information-call" disabledOverlay>
                <div className="container__information">
                    <div className="container__main-text">
                        <p className="main-text">
                            <strong>
                                Comunícate a nuestras líneas de servicio en donde un asesor te ayudará con tu gestión:
                            </strong>
                        </p>
                    </div>
                    <div className="container__numbers">
                        <p className="main-text">
                            <strong>Bogotá:</strong> 6017460888
                        </p>
                        <p className="main-text">
                            <strong>Medellín:</strong> 6046043010
                        </p>
                        <p className="main-text">
                            <strong>Cali:</strong> 6024852050
                        </p>
                        <p className="main-text">
                            <strong>Cartagena:</strong> 6056934080
                        </p>
                        <p className="main-text">
                            <strong>Barranquilla:</strong> 6053858090
                        </p>
                    </div>
                    <div className="container__time-attention-text">
                        <p className="time-attention-text">
                            <strong>
                                Ten en cuenta que nuestro horario de atención telefónica es de lunes a viernes de 8:00 a.m. a 5:00
                                p.m.
                            </strong>
                        </p>
                    </div>
                </div>
            </Modal>
            <div className="floating-button" id="floating-button">
                <div className="container__option--floating {">
                    {generateOptions()?.map((option, index) => (
                        <div className="option--floating" key={`option-${index}`}>
                            <div className="container-labels">
                                <label className="label--option">{option.label}</label>
                                {option.moreInfo && <label className="label--option--moreInfo">{option.moreInfo}</label>}
                            </div>
                            <div className="container__floating-icon" onClick={option.onClick}>
                                <Icon name={option.iconName} hoverIcon={option.hoverIcon} className="icon--w" type="svg" />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="floating-main-button" id="main-button">
                    {openOptions ? (
                        <Icon
                            name="closeAssistant"
                            className="assistant--close"
                            type="svg"
                            onClick={(): void => handleButton()}
                        />
                    ) : (
                        <Icon name="assistant" className="assistant" type="svg" onClick={(): void => handleButton()} />
                    )}
                </div>
            </div>
        </>
    );
};
