// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { CheckboxGreen, SelectInput } from '@components/input';
// Models
import { IStepTwoProps } from '../../../models/EmployeeComponents';
import { ISelectValue } from '@components/input/models/Input';
// Constants
import {
    RISK_CLASS_ID_CLASS_FIVE,
    RISK_CLASS_ID_CLASS_FOUR,
    contractForMoreThanOneMonth,
    APPRENTICES_IN_TRAINING_STAGE,
    APPRENTICES_IN_PRODUCTIVE_STAGE,
} from '@pages/employees/constants/Employees';
import {
    TYPE_CONTRIBUTOR_AVAILABLE_CCF,
    TYPE_CONTRIBUTOR_DISABLE_COMPENSATION_BOX,
    TYPE_CONTRIBUTOR_DISABLE_EPS,
    TYPE_CONTRIBUTOR_DISABLE_PENSION,
} from './constants/StepTwo';
// Hooks
import { useTaxPayer } from '@pages/employees/hooks/useTaxPayer';
import { useStepTow } from '@hooks/useStepTow';
import { useChangeCompensationBox } from '@pages/employees/hooks/useChangeValues';

export const StepTwo: React.FC<IStepTwoProps> = ({
    disabled,
    compensationBox,
    typeContributor,
    eps,
    pensions,
    layoffs,
    values,
    riskClass,
    subtypeContributor,
    ciuus,
    handleTypeContributorChange,
}) => {
    const { risk_class_id, type_funder_id, contributor_subtype } = values;
    const { pila_user, branchOptions, handleTypeBranchChange, workCenterOptions, handleTypeWorkCenter } = useTaxPayer();
    const { setFieldValue } = useFormikContext();
    const { availableContracts, checkAvailableField, getLabelField } = useStepTow(type_funder_id, contributor_subtype);

    const availableCCF = (id: string): boolean => {
        return TYPE_CONTRIBUTOR_AVAILABLE_CCF.some(type => type === id);
    };
    const { validateCompensationBoxByTypeFunder } = useChangeCompensationBox();

    return (
        <div className="scroll--page">
            <div className="form__container">
                {pila_user && (
                    <div className="container_mb">
                        <SelectInput
                            name="name_branch"
                            label="Nombre Sucursal"
                            options={branchOptions}
                            disabled={disabled}
                            onChange={(e: ISelectValue | unknown): void => {
                                handleTypeBranchChange((e as ISelectValue).value);
                            }}
                        />
                    </div>
                )}
                {pila_user && (
                    <div className="container_mb">
                        <SelectInput
                            name="work_center"
                            label="Nombre Centro de trabajo"
                            options={workCenterOptions}
                            disabled={workCenterOptions.length === 0 || disabled}
                            onChange={(e: ISelectValue | unknown): void => {
                                handleTypeWorkCenter((e as ISelectValue).value);
                            }}
                        />
                    </div>
                )}
                <div className="container_mb">
                    <SelectInput
                        name="type_funder_id"
                        label="Tipo de cotizante"
                        options={typeContributor}
                        onChange={(e: ISelectValue | unknown): void => {
                            handleTypeContributorChange((e as ISelectValue).value);
                            !availableCCF((e as ISelectValue).value) && setFieldValue('optional_ccf_contribution', false);
                            validateCompensationBoxByTypeFunder((e as ISelectValue).value);
                        }}
                        disabled={disabled}
                        isSearchable
                    />
                </div>
                <div className="container_mb">
                    <SelectInput
                        name="contributor_subtype"
                        label="Subtipo de cotizante"
                        options={subtypeContributor}
                        disabled={disabled}
                        isSearchable
                    />
                </div>
                {availableCCF(type_funder_id) && (
                    <div className="container_mb">
                        <CheckboxGreen
                            name="optional_ccf_contribution"
                            label="Aportes opcionales CCF"
                            disabled={disabled}
                            checkBoxGreenLarge
                        />
                    </div>
                )}
                {(!checkAvailableField(TYPE_CONTRIBUTOR_DISABLE_EPS) ||
                    risk_class_id === RISK_CLASS_ID_CLASS_FIVE ||
                    risk_class_id === RISK_CLASS_ID_CLASS_FOUR) && (
                    <div className="container_mb">
                        <SelectInput name="eps_id" label={getLabelField('EPS')} options={eps} disabled={disabled} isSearchable />
                    </div>
                )}

                {!checkAvailableField(TYPE_CONTRIBUTOR_DISABLE_PENSION) && (
                    <div className="container_mb">
                        <SelectInput
                            name="pension_fund_id"
                            label={getLabelField('Fondo de pensiones')}
                            options={pensions}
                            disabled={disabled}
                            isSearchable
                        />
                    </div>
                )}

                {!availableContracts([
                    APPRENTICES_IN_TRAINING_STAGE,
                    APPRENTICES_IN_PRODUCTIVE_STAGE,
                    contractForMoreThanOneMonth,
                ]) && (
                    <>
                        <div className="container_mb">
                            <SelectInput
                                name="cesantias_fund_id"
                                label="Cesantías"
                                options={layoffs}
                                disabled={disabled}
                                isSearchable
                            />
                        </div>
                    </>
                )}

                {!checkAvailableField(TYPE_CONTRIBUTOR_DISABLE_COMPENSATION_BOX) && (
                    <div className="container_mb">
                        <SelectInput
                            name="compensation_box_id"
                            label={getLabelField('Caja de compensación')}
                            disabled={disabled}
                            options={compensationBox}
                            isSearchable
                        />
                    </div>
                )}

                {!checkAvailableField([APPRENTICES_IN_TRAINING_STAGE]) && (
                    <>
                        <div className="container_mb">
                            <SelectInput
                                name="risk_class_id"
                                label="Clases de riesgo/Tarifa ARL"
                                options={riskClass}
                                disabled={disabled}
                            />
                        </div>
                    </>
                )}
                <div className="container_mb">
                    <SelectInput
                        isSearchable
                        name="ciiu_code"
                        label="CIIU - Actividad económica/ARL"
                        options={ciuus}
                        disabled={disabled}
                        className="width-input"
                    />
                </div>
                <div className="container_mb">
                    <CheckboxGreen name="high_risk_rate_pension" label="Tarifa Alto Riesgo Pensión" disabled={disabled} />
                </div>
            </div>
        </div>
    );
};
