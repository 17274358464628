// Libraries
import { AppThunk } from '@redux/configureStore';
// Models
import { ICiiusComplete, IDynamicDataIntegralMinimumWage } from '@models/Utils';
// Internal imports
import {
    apiDynamicEmployee,
    apiDynamicRequest,
    apiGetAllCiius,
    apiGetCiuusByRisk,
    apiGetLiquidationReasonList,
    apiGetTypeFunder,
    apiGetWithdrawReasonList,
    apiGetAllCitiesDueDiligence,
    apiGetAllDepartmentsDueDiligence,
    apiGetAddressType,
} from '@api/utils';
import { createSlice } from '@reduxjs/toolkit';
import { IUtilsState } from './types';
// Constants
import { ARRAY_DYNAMIC_INTEGRAL_SALARY_WAGE_VALUES, SALARY_CODE, typesAddress } from '@constants/Utils';

const initialState: IUtilsState = {
    loader: false,
    loaderRegisterCompany: false,
    document_type: [],
    administrator: [],
    country: [],
    country_state: [],
    county_state_city: [],
    type_contributor: [],
    type_funder: [],
    contract_term: [],
    contract_type: [],
    salary_type: [],
    cost_center: [],
    ciius: [],
    ciius_complete: [],
    compensation_boxes: [],
    types_funder: [],
    conceptTypeCreate: {},
    riskClass: [],
    bank: [],
    bank_account_type: [],
    payment_method: [],
    payment_type: [],
    withdraw_reason: [],
    subtype_contributor: [],
    liquidation_reason: [],
    ciius_by_risk: [],
    integral_minimum_wage: 0,
    type_pila_contributor: [],
    ciiu_company: '',
    cities_duediligence: {},
    departments_duediligence: {},
    load_dynamnic_data: false,
    error: '',
    firstAddressType: [],
    secondAddressType: [],
    cardinalAddressType: [],
};

const utilsSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        showLoader: (state, action) => {
            state.loader = action.payload;
        },
        setDynamicData: (state, action) => {
            state.document_type = action.payload?.document_type || [];
            state.administrator = action.payload?.administrator || [];
            state.country = action.payload?.country || [];
            state.country_state = action.payload?.country_state || [];
            state.county_state_city = action.payload?.county_state_city || [];
            state.type_contributor = action.payload?.type_contributor || [];
            state.type_funder = action.payload?.type_funder || [];
            state.compensation_boxes = action.payload?.compensation_box || [];
            state.riskClass = action.payload?.risk_class || [];
            state.subtype_contributor = action.payload?.subtype_contributor || [];
            state.type_pila_contributor = action.payload?.type_pila_contributor || [];
            state.ciius = action.payload?.ciius || [];
        },
        setDynamicEmployee: (state, action) => {
            state.contract_term = action.payload?.contract_term || [];
            state.contract_type = action.payload?.contract_type || [];
            state.salary_type = action.payload?.salary_type || [];
        },
        setTypesFunder: (state, action) => {
            state.types_funder = action.payload;
        },
        setConceptTypeCreate: (state, action) => {
            state.conceptTypeCreate = action.payload;
        },
        setWithdrawReason: (state, action) => {
            state.withdraw_reason = action.payload;
        },
        setLiquidationReason: (state, action) => {
            state.liquidation_reason = action.payload;
        },
        setPaymentsInformation: (state, action) => {
            state.bank = action.payload?.bank || [];
            state.bank_account_type = action.payload?.bank_account_type || [];
            state.payment_method = action.payload?.payment_method || [];
            state.payment_type = action.payload?.payment_type || [];
        },
        setLoaderRegisterCompany: (state, action) => {
            state.loaderRegisterCompany = action.payload;
        },
        setCiiusByRisk: (state, action) => {
            state.ciius_by_risk = action.payload;
        },
        setIntegralMinimumWage: (state, action) => {
            state.integral_minimum_wage = action.payload;
        },
        setCiiusComplete: (state, action) => {
            state.ciius_complete = action.payload;
        },
        setCiiuCompany: (state, action) => {
            state.ciiu_company = action.payload;
        },
        setCitiesDueDiligence: (state, action) => {
            state.cities_duediligence = action.payload;
        },
        setDepartmentsDueDiligence: (state, action) => {
            state.departments_duediligence = action.payload;
        },
        setLoadDynamicInformation: (state, action) => {
            state.load_dynamnic_data = action.payload;
        },
        setfirstAddressType: (state, action) => {
            state.firstAddressType = action.payload;
        },
        setsecondAddressType: (state, action) => {
            state.secondAddressType = action.payload;
        },
        setCardinalAddressType: (state, action) => {
            state.cardinalAddressType = action.payload;
        },
        failedRequest: (state, action) => {
            state.error = action.payload;
        },
    },
});

/**
 * Action to get dynamic data platform
 */
export const getDynamicData = (types: string[]): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiDynamicRequest(types);
            dispatch(setDynamicData(response));
            dispatch(getLoadDynamicData());
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get dynamic data platform
 */
export const getDynamicEmployee = (types: string[]): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiDynamicEmployee(types);
            dispatch(setDynamicEmployee(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Actioon t
 * @returns
 */

/**
 * Action to get payment information.
 */
export const getDynamicPayments = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiDynamicRequest(['payment_method', 'payment_type', 'bank', 'bank_account_type']);
            dispatch(setPaymentsInformation(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get type funders with funder id
 */
export const getTypesFunder = (): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            company: {
                information: { type_funder_id },
            },
        } = getState();

        try {
            if (type_funder_id) {
                const response = await apiGetTypeFunder(type_funder_id);
                dispatch(setTypesFunder(response));
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const getWithdrawList = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetWithdrawReasonList();
            dispatch(setWithdrawReason(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const getLiquidationReasonList = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetLiquidationReasonList();
            dispatch(setLiquidationReason(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const getCiiusByRisk = (risk: string, enableAllActivities: boolean): AppThunk => {
    return async (dispatch, getState): Promise<ICiiusComplete[] | undefined> => {
        const {
            company: { information },
            utils: { ciiu_company },
        } = getState();

        try {
            if (information?.ciiu_code) {
                const response = await apiGetCiuusByRisk<ICiiusComplete[]>({
                    ciiu: ciiu_company || information?.ciiu_code,
                    risk_class: risk,
                    enable_all_activities: enableAllActivities,
                });
                dispatch(setCiiusByRisk(response));
                return response;
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const getAllCiius = (): AppThunk => {
    return async (dispatch, getState): Promise<ICiiusComplete[] | undefined> => {
        const {
            company: { information },
        } = getState();

        try {
            if (information?.ciiu_code) {
                const response = await apiGetAllCiius<ICiiusComplete[]>();
                dispatch(setCiiusComplete(response));
                return response;
            }
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const getAllCitiesDueDiligence = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetAllCitiesDueDiligence();
            dispatch(setCitiesDueDiligence(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const getAllDepartmentsDueDiligence = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetAllDepartmentsDueDiligence();
            dispatch(setDepartmentsDueDiligence(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get integral minimum wage
 */
export const getIntegralMinimumWage = (): AppThunk => {
    const currentYear = new Date().getFullYear();

    return async (dispatch): Promise<void> => {
        try {
            const { year, year_variable, year_variable_type } = (await apiDynamicRequest(
                ARRAY_DYNAMIC_INTEGRAL_SALARY_WAGE_VALUES
            )) as IDynamicDataIntegralMinimumWage;
            const currentYearId = year.find(({ code }) => code === currentYear.toString())?.id || '';
            const salaryTypeId = year_variable_type.find(({ code }) => code === SALARY_CODE)?.id || '';
            const currentValueSalary =
                year_variable.find(item => item.year_id === currentYearId && item.year_variable_type_id === salaryTypeId)
                    ?.value || '';
            dispatch(setIntegralMinimumWage(Number(currentValueSalary)));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const getLoadDynamicData = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            dispatch(setLoadDynamicInformation(true));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get first direction type
 */
export const getfirstAddressType = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetAddressType(typesAddress.first);
            dispatch(setfirstAddressType(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get second direction type
 */
export const getsecondAddressType = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetAddressType(typesAddress.second);
            dispatch(setsecondAddressType(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

/**
 * Action to get second direction type
 */
export const getCardinalAddressType = (): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiGetAddressType(typesAddress.cardinal);
            dispatch(setCardinalAddressType(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const {
    showLoader,
    setDynamicData,
    setDynamicEmployee,
    setTypesFunder,
    failedRequest,
    setConceptTypeCreate,
    setPaymentsInformation,
    setWithdrawReason,
    setLiquidationReason,
    setLoaderRegisterCompany,
    setCiiusByRisk,
    setCiiusComplete,
    setIntegralMinimumWage,
    setCiiuCompany,
    setCitiesDueDiligence,
    setDepartmentsDueDiligence,
    setLoadDynamicInformation,
    setfirstAddressType,
    setsecondAddressType,
    setCardinalAddressType,
} = utilsSlice.actions;

export default utilsSlice.reducer;
