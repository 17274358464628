// Api
import AxiosClient from '@api/axiosClient';
// Urls
import { urls } from '@api/urls';
// Models
import { ICiiuByRiskData } from '@models/Utils';

export const apiGetCurrentPeriod = <T>(type: string, year: string): Promise<T> =>
    AxiosClient.get(urls.utils.yearPeriod.current(type, year), {});
export const apiDynamicRequest = (data: string[]): Promise<unknown> => AxiosClient.post(urls.utils.dynamicRequest, data);
export const apiDynamicEmployee = (data: string[]): Promise<unknown> => AxiosClient.post(urls.utils.employee, data);
export const apiGetTypeFunder = (idFunder: string): Promise<unknown> => AxiosClient.get(urls.utils.typeFunder(idFunder));
export const apiGetCompensationOptions = <T>(departmentId: string): Promise<T> =>
    AxiosClient.get(urls.utils.compensationBox(departmentId));

export const apiGetWithdrawReasonList = <T>(): Promise<T> => AxiosClient.get(urls.utils.conceptType.type.withdrawReason, {});
export const apiGetPeriodById = <T>(id: string): Promise<T> => AxiosClient.get(urls.utils.periodicity.getPeriodById(id), {});
export const apiGetPeriodsByYear = <T>(year: string): Promise<T> => AxiosClient.get(urls.utils.yearPeriod.byYear(year), {});
export const apiGetLiquidationReasonList = <T>(): Promise<T> =>
    AxiosClient.get(urls.utils.conceptType.type.liquidationReason, {});

export const apiGetCiuusByRisk = <T>(data: ICiiuByRiskData): Promise<T> => AxiosClient.post(urls.utils.ciiusByRisk, data);

export const apiGetAllCiius = <T>(): Promise<T> => AxiosClient.post(urls.utils.ciiusByRisk, {});

export const apiGetAllCitiesDueDiligence = <T>(): Promise<T> => AxiosClient.get(urls.utils.cityDueDiligence);
export const apiGetAllDepartmentsDueDiligence = <T>(): Promise<T> => AxiosClient.get(urls.utils.departmentDueDiligence);
export const apiGetAddressType = <T>(type: number): Promise<T> => AxiosClient.get(urls.utils.addressType(type));
