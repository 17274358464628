// Libraries
import { UserOptions } from 'jspdf-autotable';

/**
 * This constant provide the custom styles of pdf table
 */
export const CustomStyles: UserOptions = {
    styles: { fontSize: 8, fillColor: '#f9f5f5', textColor: '#706262', halign: 'center' },
    headStyles: { fillColor: '#00805d', lineWidth: 0.5, textColor: '#ffffff' },
    margin: { left: 10, right: 10 },
    theme: 'plain',
};
