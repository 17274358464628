// Libraries
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
// Hooks
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { useValidate } from './useValidate';
// Models
import { IInitEmployee, IEmployee, IItemEmployee, TExistEmployee } from '@models/Employee';
import { IDataEmployeeStack } from '../models/Employee';
// Redux
import { RootState } from '@redux/rootReducer';
import {
    getDetailEmployee,
    setEmployee,
    deleteEmployee,
    setEmployeeStack,
    setValidateContributor,
    postExistEmployeeByEmail,
    setModalError,
    postValidateChangeContract,
} from '@redux/employee/employee.slice';
// Utils
import { capitalizeCase } from '@utils/Text';
import { IPlanEmployer } from '@models/Plans';
import { EMPLOYEE_EXIST_EMAIL } from '../constants/Employees';
import { IValidateChangeNoveltyContract } from '@models/Novelty';

export const useEmployee = (): {
    activePlan: IPlanEmployer | null;
    createEmployee: boolean;
    currentStep: number;
    selectedEmployee: IEmployee | null;
    editEmployee: boolean;
    handleModal: () => void;
    handleLiquidatorModal: () => void;
    handleNextStep: (values: IInitEmployee | undefined) => void;
    handleBackStep: () => void;
    setSelectedEmployee: Dispatch<SetStateAction<IEmployee | null>>;
    handleSelectCheck: (id: string, check: boolean) => void;
    handleSelectAll: (check: boolean) => void;
    handleActionEmployee: (id: string, edit: boolean) => void;
    employees: IItemEmployee[];
    checkAll: boolean;
    openModal: boolean;
    showMessage: boolean;
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    setShowMessage: Dispatch<SetStateAction<boolean>>;
    handleDeleteEmployees: () => void;
    setCurrentStep: Dispatch<SetStateAction<number>>;
    handleActionEmployeeById: (id: string) => void;
    handleNextStepThree: () => void;
    handleNextStepFourth: () => void;
    showStepThree: number;
    setShowStepThree: Dispatch<SetStateAction<number>>;
    employeesExceeded: boolean;
    resetDataModal: () => void;
    viewEmployee: boolean;
    getContractStartDate: ({
        employee,
        dataEmployeeStack,
        edit,
        viewEmployee,
    }: {
        employee: IEmployee | null | undefined;
        dataEmployeeStack: IDataEmployeeStack;
        viewEmployee?: boolean;
        edit?: boolean;
    }) => string;
    showPermanentChangeSalary: boolean;
    handleShowPermanentChangeSalary: () => void;
    handleValidateExistEmployeeByEmail: (values: IInitEmployee) => Promise<boolean>;
    handleValidationChangeDateContract: (values: IInitEmployee, employee?: IEmployee | null) => Promise<boolean>;
} => {
    const { validateValuesStepOne, validateValuesStepTwo, validateValuesStepThree } = useValidate();
    const dispatch = useAppDispatch();
    const {
        plans: { activePlan },
        employee: { activeEmployees: listEmployee, employee },
        periods: { currentPeriod },
    } = useAppSelector((state: RootState) => state);
    const [createEmployee, setCreateEmployee] = useState<boolean>(false);
    const [editEmployee, setEditEmployee] = useState<boolean>(false);
    const [viewEmployee, setViewEmployee] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [selectedEmployee, setSelectedEmployee] = useState<IEmployee | null>(null);
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [employees, setEmployees] = useState<IItemEmployee[]>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [showStepThree, setShowStepThree] = useState<number>(3);
    const [showPermanentChangeSalary, setShowPermanentChangeSalary] = useState<boolean>(false);
    /**
     * Format data employee to edit data
     */
    useEffect(() => {
        if (employee?.id) {
            const current_contract = employee?.contract[0];

            setSelectedEmployee({
                ...employee,
                contract_id: current_contract?.id,
                contract_type_id: current_contract?.contract_type_id,
                contract_term_id: current_contract?.contract_term_id,
                start_date: current_contract?.start_date,
                salary: current_contract?.salary,
                transportation_assistance: current_contract?.transportation_assistance || false,
                finish_date: current_contract?.finish_date,
                salary_type_id: current_contract?.salary_type_id,
                first_start_contract_date: current_contract?.first_start_contract_date,
            });
        }
    }, [employee]);

    /**
     * Format current list employee
     */
    useEffect(() => {
        if (listEmployee && Array.isArray(listEmployee)) {
            const currentListEmployee = [...listEmployee];
            const employeeOrder = currentListEmployee.sort((a, b) => {
                if (a.names > b.names) {
                    return 1;
                }
                if (a.names < b.names) {
                    return -1;
                }
                return 0;
            });
            const currentEmployees = employeeOrder?.map(employee => {
                return {
                    ...employee,
                    check: false,
                    type_contract: employee.contract,
                    names: capitalizeCase(employee.names || ''),
                    last_names: capitalizeCase(employee.last_names || ''),
                    second_name: employee.second_name ? capitalizeCase(employee.second_name) : '',
                    second_last_name: employee.second_last_name ? capitalizeCase(employee.second_last_name) : '',
                };
            });
            setEmployees(currentEmployees);
        }
    }, [listEmployee]);

    /**
     * Action to change state checks from list
     */
    useEffect(() => {
        const currentEmployees = employees?.map(employee => {
            return {
                ...employee,
                check: checkAll,
            };
        });
        setEmployees(currentEmployees);
    }, [checkAll]);

    /**
     * Action to show create employee modal
     */
    const handleModal = (): void => {
        setCreateEmployee(!createEmployee);
    };

    /**
     * Action to show create employee modal in liquidator
     */
    const handleLiquidatorModal = (): void => {
        resetDataModal();
        handleModal();
    };

    const resetDataModal = (): void => {
        setCreateEmployee(false);
        dispatch(setEmployeeStack(null));
        dispatch(setEmployee(null));
        dispatch(setValidateContributor(false));
        setSelectedEmployee(null);
        setViewEmployee(false);
        setEditEmployee(false);
    };

    /**
     * Action to get information from modal employee
     * @constructor
     * @param {IItemEmployee} id - Id employee to show information in modal
     * @param {boolean} edit - State to edit or preview information modal
     */
    const handleActionEmployee = (id: string, edit: boolean, view?: boolean): void => {
        if (!createEmployee) {
            dispatch(getDetailEmployee(id));
        } else {
            dispatch(setEmployee(null));
            setSelectedEmployee(null);
        }
        handleModal();
        setEditEmployee(edit);
        setViewEmployee(view || false);
    };

    /**
     * Action to get information from modal employee by id.
     * @constructor
     * @param {string} id - Current employee id
     */
    const handleActionEmployeeById = (id: string): void => {
        if (!createEmployee) {
            dispatch(getDetailEmployee(id));
        } else {
            dispatch(setEmployee(null));
            setSelectedEmployee(null);
        }
        handleModal();
        setEditEmployee(false);
        setViewEmployee(Boolean(id));
    };

    /**
     * Action to go to next step
     */
    const handleNextStep = (values?: IInitEmployee): void => {
        if (values) {
            if (currentStep === 1 && validateValuesStepOne(values)) {
                setCurrentStep(2);
                return;
            } else if (currentStep === 2 && validateValuesStepTwo(values)) {
                setCurrentStep(3);
                return;
            } else if (currentStep === 3 && validateValuesStepThree(values)) {
                setCurrentStep(4);
            } else {
                setShowMessage(true);
            }
        }
    };

    /**
     * Action to go to previous step
     */
    const handleBackStep = (): void => {
        let step = currentStep;
        if (currentStep != 1) {
            step -= 1;
            setCurrentStep(step);
        }
    };

    /**
     * Action to select a checkbox from employee
     * @constructor
     * @param {string} id - Id employee
     * @param {boolean} check - check state
     */
    const handleSelectCheck = (id: string, check: boolean): void => {
        const currentEmployee = employees.map(employe => {
            if (employe.id === id) {
                return {
                    ...employe,
                    check: !check,
                };
            }

            return employe;
        });
        setEmployees(currentEmployee);
    };

    /**
     * Action to select all employees from list
     * @param {boolean} check - State from item
     */
    const handleSelectAll = (check: boolean): void => {
        setCheckAll(!check);
    };

    /**
     * Action to delete employees
     */
    const handleDeleteEmployees = (): void => {
        const deleteEmployees = employees.filter(employe => employe.check);
        const idDeleteEmployes = deleteEmployees.map(employe => employe.id);
        const payloadDelete = {
            employees: idDeleteEmployes,
            year_period_id: currentPeriod.period.id,
        };
        dispatch(deleteEmployee(payloadDelete));
        setCheckAll(false);
        setOpenModal(!openModal);
    };

    const handleNextStepThree = (): void => {
        setCurrentStep(3);
    };

    const handleNextStepFourth = (): void => {
        setCurrentStep(4);
    };

    const checkEmployeesExceeded = (): boolean => {
        if (activePlan) return listEmployee?.length >= activePlan?.max_employees;
        return false;
    };

    const getContractStartDate = ({
        employee,
        edit,
        viewEmployee,
        dataEmployeeStack,
    }: {
        employee: IEmployee | null | undefined;
        dataEmployeeStack: IDataEmployeeStack;
        viewEmployee?: boolean;
        edit?: boolean;
    }): string => {
        if (viewEmployee || edit) {
            return employee?.first_start_contract_date || employee?.start_date || dataEmployeeStack.date_start || '';
        }
        return employee?.start_date || dataEmployeeStack?.date_start || '';
    };

    const handleShowPermanentChangeSalary = (): void => {
        setShowPermanentChangeSalary(!showPermanentChangeSalary);
    };

    const handleValidateExistEmployeeByEmail = async (values: IInitEmployee): Promise<boolean> => {
        const employeeInfoEmail: TExistEmployee = {
            email: values.email,
        };

        const resultValidation = await postExistEmployeeByEmail(employeeInfoEmail);
        if (resultValidation) {
            dispatch(
                setModalError({
                    message: EMPLOYEE_EXIST_EMAIL,
                    open_modal: true,
                })
            );
        }
        return resultValidation;
    };

    const handleValidationChangeDateContract = async (values: IInitEmployee, employee?: IEmployee | null): Promise<boolean> => {
        const currentContract = employee?.contract[0];
        if (currentContract?.start_date === values.start_date && (currentContract?.finish_date ?? '') === values.finish_date)
            return false;

        const validationRequest: IValidateChangeNoveltyContract = {
            employee_id: employee?.id || '',
            start_date: values.start_date,
            finish_date: values.finish_date,
            liquidation_contract: false,
        };
        const resultValidation = await postValidateChangeContract(validationRequest);
        if (resultValidation.error) {
            dispatch(
                setModalError({
                    message: resultValidation.message,
                    open_modal: true,
                })
            );
        }
        return resultValidation.error;
    };

    return {
        activePlan,
        createEmployee,
        currentStep,
        selectedEmployee,
        editEmployee,
        handleModal,
        handleLiquidatorModal,
        handleNextStep,
        handleBackStep,
        setSelectedEmployee,
        handleSelectCheck,
        handleSelectAll,
        handleActionEmployee,
        employees,
        checkAll,
        openModal,
        showMessage,
        setOpenModal,
        setShowMessage,
        handleDeleteEmployees,
        setCurrentStep,
        handleActionEmployeeById,
        handleNextStepThree,
        handleNextStepFourth,
        showStepThree,
        setShowStepThree,
        employeesExceeded: checkEmployeesExceeded(),
        resetDataModal,
        viewEmployee,
        getContractStartDate,
        showPermanentChangeSalary,
        handleShowPermanentChangeSalary,
        handleValidateExistEmployeeByEmail,
        handleValidationChangeDateContract,
    };
};
