// Libraries
import React from 'react';

export const TEXT_POPUP_PERMANENT_SALARY_CHANGE = (
    <p>
        Has asignado un cambio en el salario del empleado, el cambio se ajustará para todos los meses, si es un cambio de salario
        permanente, debes seleccionar la opción <strong>Cambio permanente de salario</strong> e indicar la fecha de inicio del
        nuevo salario. Asegúrate de seleccionar nuevamente la opción <strong>Liquidar Nómina</strong> y{' '}
        <strong>Liquidar Seguridad Social</strong> en el <strong>Liquidador</strong> para generar la planilla de PILA ajustada a
        este salario.
    </p>
);
