import {
    CUSTOM_LABEL_FIELD,
    TypeContributorStudent,
} from '@pages/employees/components/create-employee/components/step-two/constants/StepTwo';

export const useStepTow = (
    typeFunderId: string,
    contributor_subtype: string
): {
    availableContracts: (arr: string[]) => boolean;
    checkAvailableField: (arr: string[]) => boolean;
    getLabelField: (currentLabel: string) => string;
} => {
    const availableContracts = (arr: string[]): boolean => {
        const initialArr = [
            TypeContributorStudent.TYPE_STUDENT_ONE_ID,
            TypeContributorStudent.TYPE_STUDENT_TWO_ID,
            TypeContributorStudent.TYPE_STUDENT_THREE_ID,
            ...arr,
        ];

        return initialArr.some(item => typeFunderId === item);
    };

    const checkAvailableField = (arr: string[]): boolean => {
        return arr.some(item => contributor_subtype === item || typeFunderId === item);
    };

    const getLabelField = (currentLabel: string): string => {
        if (currentLabel === 'Caja de compensación' && typeFunderId === TypeContributorStudent.TYPE_STUDENT_ONE_ID) {
            return `${currentLabel} (Informativo)`;
        }

        if (CUSTOM_LABEL_FIELD.some(x => x === typeFunderId)) {
            return `${currentLabel} (Informativo)`;
        }

        return currentLabel;
    };

    return {
        availableContracts,
        checkAvailableField,
        getLabelField,
    };
};
