import { Dispatch, SetStateAction, useEffect } from 'react';

export const useDefaultStep = (
    defaultStep: number,
    setCurrentStep: Dispatch<SetStateAction<number>>,
    showModal: boolean
): void => {
    useEffect(() => {
        setCurrentStep(defaultStep);
    }, [showModal]);
};
