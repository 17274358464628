// Libraries
import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus, RedirectRequest, AccountInfo } from '@azure/msal-browser';
import { loginRequest, b2cPolicies } from '../../../authProvider';
// Redux
import { handleLogin } from '@redux/session/session.slice';
// Hooks
import { useAppDispatch } from '@hooks/redux';
import { protectedResources } from '../../../authProvider';

export const useActionsLogin = (): {
    handleLoginPopup: () => void;
    handleLoginRedirect: () => void;
    handleLogoutRedirect: () => void;
    handleLogoutPopup: () => void;
    handleProfileEdit: () => void;
} => {
    const { instance, inProgress } = useMsal();
    const dispatch = useAppDispatch();

    const saveDataLogin = async (token: string, activeAccount: AccountInfo): Promise<void> => {
        await dispatch(handleLogin(token, activeAccount));
    };

    const handleGetToken = (): void => {
        if (instance) {
            const activeAccount = instance.getActiveAccount();
            const accessTokenRequest = {
                account: activeAccount || undefined,
                scopes: protectedResources.apiTodoList.scopes.read,
            };

            instance
                .acquireTokenSilent(accessTokenRequest)
                .then(res => {
                    if (res.accessToken && activeAccount) {
                        saveDataLogin(res.accessToken, activeAccount);
                    }
                })
                .catch(err => {
                    console.error(err);
                });
        }
    };

    useEffect(() => {
        handleGetToken();
        setInterval(handleGetToken, 60 * 60 * 1000 + 1000);
    }, []);

    /**
     * Action to login with popup
     */
    const handleLoginPopup = (): void => {
        instance
            .loginPopup({
                ...loginRequest,
                redirectUri: '/redirect.html',
            })
            .catch(error => console.error(error));
    };

    /**
     * Action to login with redirect
     */
    const handleLoginRedirect = (): void => {
        instance?.loginRedirect(loginRequest).catch(error => console.error(error));
    };

    /**
     * Action to logout with redirect
     */
    const handleLogoutRedirect = (): void => {
        instance?.logoutRedirect();
    };

    /**
     * Action to logout with popup
     */
    const handleLogoutPopup = (): void => {
        instance.logoutPopup({
            mainWindowRedirectUri: '/',
        });
    };

    /**
     * Action to edit profile with redirect
     */
    const handleProfileEdit = (): void => {
        if (inProgress === InteractionStatus.None) {
            instance.acquireTokenRedirect(b2cPolicies.authorities.editProfile as RedirectRequest);
        }
    };

    return {
        handleLoginPopup,
        handleLoginRedirect,
        handleLogoutRedirect,
        handleLogoutPopup,
        handleProfileEdit,
    };
};
