import { ENTER } from '@constants/OtherTexts';

export const useKeyDownEnter = (): {
    handleKeyDown: (event: React.KeyboardEvent<HTMLFormElement>) => void;
} => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
        if (event.key === ENTER) {
            event.preventDefault();
        }
    };

    return {
        handleKeyDown,
    };
};
