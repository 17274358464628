// Libraries
import React, { ReactElement } from 'react';
import { Menu, MenuDivider, MenuItem } from '@szhsin/react-menu';
// Components
import { Icon } from '@components/icon';
import { TooltipInformation } from '@components/tooltip';
// Hooks
import { useElementSize } from '@hooks/useElementSize';
// Models
import { IButtonProps } from './models/Button';
// Styles
import './Button.scss';

export const Button: React.FC<IButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
    onClick = (): void => {},
    disabled = false,
    children,
    color = 'green',
    tooltip = false,
    textTooltip = '',
    tooltipPosition,
    ...props
}) => {
    return (
        <button
            {...props}
            onClick={onClick}
            disabled={disabled}
            className={`button__custom${tooltip ? '--tooltip' : ''} button--${color} ${props.className}`}
        >
            {children}
            {tooltip && (
                <div className={`section--informative${disabled ? '-disabled' : ''}`}>
                    <TooltipInformation
                        textTooltip={textTooltip}
                        classNameIcon="icon-m"
                        iconName="informativeWhite"
                        typeIcon="svg"
                        placement={tooltipPosition}
                    />
                </div>
            )}
        </button>
    );
};

export const ButtonIcon: React.FC<IButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
    onClick = (): void => {},
    disabled = false,
    iconName,
    children,
    iconClassName = '',
    color = 'green-light-3',
    ...props
}) => {
    return (
        <Button {...props} onClick={onClick} disabled={disabled} className={`button-icon button--${color} ${props.className}`}>
            {iconName && <Icon className={`button-icon__img ${iconClassName}`} name={iconName} />}
            {children}
        </Button>
    );
};

export const ButtonSectionIcon: React.FC<IButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
    onClick = (): void => {},
    disabled = false,
    iconName,
    children,
    typeIcon = 'png',
    iconClassName = '',
    tooltip = false,
    textTooltip = '',
    tooltipPosition,
    classButtonSectionIconContainer,
    ...props
}) => {
    return (
        <div className={` button-section-icon ${classButtonSectionIconContainer}`}>
            <div className="main-content" {...props.onMouseOver} {...props.onMouseOut}>
                <div className="main-content__img" onClick={disabled ? (): void => {} : onClick}>
                    {iconName && <Icon className={`icon--button ${iconClassName}`} name={iconName} type={typeIcon} />}
                </div>
                <Button
                    {...props}
                    onClick={onClick}
                    disabled={disabled}
                    className={`main-content__button${tooltip ? '-informative' : ''} ${props.className}`}
                >
                    {children}
                </Button>
                {tooltip && (
                    <div className={`${disabled ? 'section--informative-disabled' : 'section--informative'}`}>
                        <TooltipInformation
                            textTooltip={textTooltip}
                            classNameIcon="icon-m"
                            iconName="informativeWhite"
                            typeIcon="svg"
                            placement={tooltipPosition}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export const ButtonTypeDownload: React.FC<IButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
    children,
    onClickPDF = (): void => {},
    onClickExcel = (): void => {},
    ...props
}) => {
    const { width, ref } = useElementSize();

    return (
        <div className="button-type-download">
            <Menu
                menuClassName="button-type-download__menu"
                align="center"
                menuStyle={{ width: width ? width : 'auto' }}
                position="initial"
                transition
                offsetY={6}
                menuButton={(): ReactElement => (
                    <div ref={ref} className="button-type-download__menu--button">
                        <ButtonSectionIcon {...props}>{children}</ButtonSectionIcon>
                    </div>
                )}
            >
                <MenuItem className="button-type-download__menu--item" onClick={onClickExcel}>
                    Descargar Excel
                </MenuItem>
                <MenuDivider className="button-type-download__menu--divider" />
                <MenuItem className="button-type-download__menu--item" onClick={onClickPDF}>
                    Descargar PDF
                </MenuItem>
            </Menu>
        </div>
    );
};

export const ButtonWithTooltip: React.FC<IButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
    onClick = (): void => {},
    disabled = false,
    children,
    color = 'green',
    tooltip = false,
    textTooltip = '',
    tooltipPosition,
    tooltipSize = 'small',
    size = 'small',
    ...props
}) => (
    <div className="button__container relative">
        <button
            {...props}
            onClick={onClick}
            disabled={disabled}
            className={`button__container-custom button--${color} ${props.className} ${size}`}
        >
            {children}
        </button>
        {tooltip && (
            <div className={`section--informative${disabled ? '-disabled' : ''} button__container-tooltip`}>
                <TooltipInformation
                    tooltipSize={tooltipSize}
                    textTooltip={textTooltip}
                    classNameIcon="icon-m"
                    iconName="informativeWhite"
                    typeIcon="svg"
                    placement={tooltipPosition}
                />
            </div>
        )}
    </div>
);
