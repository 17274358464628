// Libraries
import React, { useEffect, useState } from 'react';
import Tippy from '@tippyjs/react';
// Components
import { Icon } from '@components/icon';
// Models
import { ITippyWrapper, ITooltipProps } from './models/Tooltip';
// Styles
import './Tooltip.scss';

export const TooltipInformation: React.FC<ITooltipProps> = ({
    textTooltip,
    classNameIcon = '',
    iconName = 'informative',
    typeIcon = 'png',
    placement = 'top-start',
    tooltipSize,
    maxWidth = '21.875rem',
    classNameTooltip = '',
    scrollTable = 0,
}) => {
    const [visibleTooltip, setVisibleTooltip] = useState(false);

    useEffect(() => {
        setVisibleTooltip(false);
    }, [scrollTable]);

    const handleVisibleTooltip = (): void => setVisibleTooltip(!visibleTooltip);

    return (
        <Tippy
            theme={`${classNameTooltip ? classNameTooltip : 'tippy-custom'}`}
            interactive
            visible={visibleTooltip}
            arrow={false}
            content={
                <div>
                    <div
                        className={`tooltip-${tooltipSize} informative-card__${
                            placement === 'top-end' ? 'left' : 'right'
                        }--icon-container `}
                    >
                        <Icon name="informative" type="svg" className="width" />
                        <Icon onClick={handleVisibleTooltip} name="closeTooltip" type="png" className="close" />
                    </div>
                    {textTooltip}
                </div>
            }
            placement={placement}
            className={`informative-card__${placement === 'top-end' ? 'left' : 'right'}`}
            onClickOutside={handleVisibleTooltip}
            maxWidth={maxWidth}
        >
            <span>
                <Icon
                    name={iconName}
                    onClick={handleVisibleTooltip}
                    type={typeIcon}
                    className={`${classNameIcon} icon-tooltip`}
                />
            </span>
        </Tippy>
    );
};

export const TippyWrapper: React.FC<ITippyWrapper> = ({ content, children, disabled }) => {
    return (
        <Tippy content={<div>{content}</div>} theme={'tippy-wrapper'} offset={[0, 4.8]} disabled={disabled}>
            <span>{children}</span>
        </Tippy>
    );
};
