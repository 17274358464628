// Libraries
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
// Constants
import {
    ENDOWMENT_VALUE,
    RISK_CLASS_ID_CLASS_FIVE,
    RISK_CLASS_ID_CLASS_FOUR,
    SALARY_TYPE_ID,
    StepTwoValues,
    contractForMoreThanOneMonth,
    contractSENA,
    contractUniversityPractice,
} from '../constants/Employees';
// Models
import { IInitEmployee } from '@models/Employee';

export const useStepTwo = (): void => {
    const { values, setFieldValue } = useFormikContext<IInitEmployee>();

    const { type_funder_id, risk_class_id, contract_type_id } = values;

    useEffect(() => {
        if (type_funder_id === contractForMoreThanOneMonth) {
            if (risk_class_id !== RISK_CLASS_ID_CLASS_FIVE && risk_class_id !== RISK_CLASS_ID_CLASS_FOUR) {
                setFieldValue(StepTwoValues.EPS_ID, '');
            }
            setFieldValue(StepTwoValues.PENSION_FUND_ID, '');
            setFieldValue(StepTwoValues.CESANTIAS_FUND_ID, '');
            setFieldValue(StepTwoValues.COMPENSATION_BOX_ID, '');
        }
    }, [type_funder_id]);

    useEffect(() => {
        if (contract_type_id === contractSENA || contract_type_id === contractUniversityPractice) {
            setFieldValue(ENDOWMENT_VALUE, '');
        }
    }, [contract_type_id]);

    useEffect(() => {
        if ([contractSENA, contractUniversityPractice].some(item => item === contract_type_id)) {
            setFieldValue(SALARY_TYPE_ID, '');
        }
    }, [contract_type_id]);
};
