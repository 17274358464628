// Models
import { IEmployerStatus } from '@models/ElectronicPayroll';
// Constants
import { EmployerCodes } from '../constants/EmployerCodes';

export const checkEmployerCode = (status: IEmployerStatus): boolean => {
    if (status.codigo !== EmployerCodes.AVAILABLE) {
        return false;
    }

    return status?.already_transmit || false;
};
