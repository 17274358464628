import { HeaderConstants, LoginConstants } from '@constants/Session';
import { store } from '@redux/configureStore';
import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

export const baseQuery = retry(
    fetchBaseQuery({
        baseUrl,
        prepareHeaders: headers => {
            const {
                session: { accessToken },
                company: { id },
            } = store.getState();

            const userToken = localStorage.getItem(LoginConstants.USER_TOKEN) ?? accessToken;
            const employerId = localStorage.getItem(LoginConstants.EMPLOYER_ID) ?? id;

            if (userToken) {
                headers.set(HeaderConstants.AUTHORIZATION, `Bearer ${userToken}`);
                headers.set(HeaderConstants.CONTENT_TYPE, 'application/json');
                headers.set(HeaderConstants.EMPLOYER_ID, employerId);
            }

            return headers;
        },
    }),
    {
        maxRetries: 2,
    }
);
