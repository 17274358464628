// Libraries
import React from 'react';
import { Formik } from 'formik';
// Components
import { Button } from '@components/button';
import { CheckBox } from '@components/input';
import { Modal } from '@components/modal';
// Models
import { IModalPoliticalDataProps } from '../models/ModalConditionsProps';
// Constants
import { TypeDocumentNames } from '@constants/Onboarding';
// Assets
import logo from '@assets/logos/logo-nav-green.png';

export const ModalPoliticalData: React.FC<IModalPoliticalDataProps> = ({
    handleOpenDocument = (): void => {},
    handleSubmitPoliticalData = (): void => {},
}) => {
    return (
        <Formik initialValues={{ political_dates: false }} onSubmit={(): void => {}}>
            {({ values }): JSX.Element => (
                <Modal isOpen closeIcon={false} className="modal-term" disabledOverlay>
                    <div className="modal-term__container">
                        <img src={logo} className="image-width" />
                        <div className="modal-term__container--text">
                            Señor Aportante, para continuar por favor revisa y acepta nuestra Política de Tratamiento de Datos
                            Personales de nuestro formulario de <span>Proceso de conocimiento del cliente</span>.
                        </div>
                        <div className="modal-term__container--check">
                            <div>
                                <CheckBox name="political_dates" />
                            </div>
                            <div>
                                He leído y acepto la&nbsp;
                                <span onClick={(): void => handleOpenDocument(TypeDocumentNames.POLITIC_DATA, true)}>
                                    Política de Tratamiento de Datos Personales
                                </span>
                            </div>
                        </div>
                        <div className="modal-term__button-container">
                            <Button onClick={handleSubmitPoliticalData} disabled={!values.political_dates}>
                                Aceptar
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}
        </Formik>
    );
};
