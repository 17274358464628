import { createApi } from '@reduxjs/toolkit/query/react';
import { urls } from '@api/urls';
import { PayrollReport } from '@models/Reports';

import { keepDataPerSeconds } from '@redux/constants/queryConfig';
import { baseQuery } from '../baseQuery';
import { TEmployeeContractFinishedResponse } from '@models/PayrollAdjuster';
import { getEmployeesWithFinishedContractRequest } from './types';

export const payslipApi = createApi({
    baseQuery,
    reducerPath: 'payslipApi',
    keepUnusedDataFor: keepDataPerSeconds.payslipApi,
    refetchOnReconnect: true,
    endpoints: build => ({
        getPayslipReport: build.query<PayrollReport, string>({
            query: (id: string) => {
                return {
                    url: `${urls.payslip.historical(id)}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: PayrollReport) => response,
        }),

        getEmployeesWithFinishedContract: build.query<TEmployeeContractFinishedResponse, getEmployeesWithFinishedContractRequest>(
            {
                query: (request: getEmployeesWithFinishedContractRequest) => {
                    return {
                        url: `${urls.payslip.getEmployeesWithFinishedContract(request)}`,
                        method: 'GET',
                    };
                },
                transformResponse: (response: TEmployeeContractFinishedResponse) => response,
            }
        ),
    }),
});

export const {
    useGetPayslipReportQuery,
    useGetEmployeesWithFinishedContractQuery,
    util,
    useLazyGetEmployeesWithFinishedContractQuery,
} = payslipApi;
