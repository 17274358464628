/**
 * Compare the token issuing policy with a specific policy name
 * @param {object} idTokenClaims - Object containining token claims
 * @param {string} policyToCompare - ID/Name of the policy as expressed in the Azure portal
 * @returns {boolean}
 */
export const compareIssuingPolicy = (
    idTokenClaims: { [key: string]: string | number } | undefined,
    policyToCompare: string
): boolean => {
    if (idTokenClaims) {
        const tfpMatches = idTokenClaims['tfp'] === policyToCompare.toLowerCase();
        const acrMatches = idTokenClaims['acr'] === policyToCompare.toLowerCase();
        return tfpMatches || acrMatches;
    }
    return false;
};
