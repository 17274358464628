// Api
import AxiosClient from '@api/axiosClient';
// Urls
import { urls } from './urls';
// Models
import { IEmployerLogin } from '@models/Session';

/**
 * Save azure user
 */
export const apiGetSaveUser = (): Promise<unknown> => AxiosClient.get(urls.session.saveUser);
/**
 * Associate user
 */
export const apiPostSecurityEmployer = <T>(data: IEmployerLogin): Promise<T> =>
    AxiosClient.post(urls.session.employer, data, false);

export const apiPutLastLogin = <T>(userId: string, lastLogin: string): Promise<T> =>
    AxiosClient.put(urls.session.lastLogin(userId), { last_login: lastLogin });
