// Libraries
import { useEffect, useState } from 'react';
// Redux
import { RootState } from '@redux/rootReducer';
import { getDynamicPayments } from '@redux/utils/utils.slice';
// Hooks
import { useAppDispatch, useAppSelector } from '@hooks/redux';
// Models
import { ISelectValue } from '@components/input/models/Input';
// Utils
import { formatOptions } from '@utils/Options';

export const useStepFour = (): {
    bankOptions: ISelectValue[];
    bankAccountOptions: ISelectValue[];
    paymentMethodOptions: ISelectValue[];
    paymentTypeOptions: ISelectValue[];
} => {
    const dispatch = useAppDispatch();
    const { bank, bank_account_type, payment_method, payment_type } = useAppSelector((state: RootState) => state.utils);
    const [bankOptions, setBankOptions] = useState<ISelectValue[]>([]);
    const [bankAccountOptions, setBankAccountOptions] = useState<ISelectValue[]>([]);

    useEffect(() => {
        dispatch(getDynamicPayments());
    }, []);

    useEffect(() => {
        if (bank?.length) {
            const formatBanks = formatOptions(bank);
            formatBanks.unshift({
                label: 'Seleccione...',
                value: '',
            });
            setBankOptions(formatBanks);
        }

        if (bank_account_type?.length) {
            const formatBankAccounts = formatOptions(bank_account_type);
            formatBankAccounts.unshift({
                label: 'Seleccione...',
                value: '',
            });
            setBankAccountOptions(formatBankAccounts);
        }
    }, [bank, bank_account_type]);

    return {
        bankOptions,
        bankAccountOptions,
        paymentMethodOptions: formatOptions(payment_method),
        paymentTypeOptions: formatOptions(payment_type),
    };
};
