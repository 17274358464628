// Redux
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@redux/configureStore';
import { setLoaderRegisterCompany, showLoader } from '@redux/utils/utils.slice';
import { getPayments, getStatusTransaction, setPlansEmployee } from '@redux/plans/plans.slice';
// Types
import { IOnboardingState } from './types';
// Components
import { Routes } from '@components/paths';
// Api
import { apiPostOnboardingStatus, apiPostSaveStep, apiSaveApproval, apiSaveApprovalElectronicPayroll } from '@api/onboarding';
import { apiGetPlansEmployee } from '@api/plans';
// Constants
import { NAME_DOCUMENTS, OnboardingStepsUser, TypeDiligence, TypeDocumentNames } from '@constants/Onboarding';
// Models
import { ApprovalData, ICurrentOnboardingState, IOnboardingPostInformation } from '@models/Onboarding';
import { ICompanyPlan } from '@models/Plans';
// Utils
import { getRoute } from '@utils/Paths';
import { checkIsSupport } from '@utils/CheckSupport';

const initialState: IOnboardingState = {
    dataProcessing: [],
    dueDiligence: '',
    urlEncrypt: '',
    step: null,
    userId: '',
    modalErrorRequest: null,
    error: '',
    isNewCompany: false,
};

const onboardingSlide = createSlice({
    name: 'onboarding',
    initialState,
    reducers: {
        setDataProcessing: (state, action) => {
            state.dataProcessing = action.payload;
        },
        setDueDiligence: (state, action) => {
            state.dueDiligence = action.payload;
        },
        setStep: (state, action) => {
            state.step = action.payload;
        },
        setUserId: (state, action) => {
            state.userId = action.payload;
        },
        setUrlEncrypt: (state, action) => {
            state.urlEncrypt = action.payload;
        },
        setModalErroRequest: (state, action) => {
            state.modalErrorRequest = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },

        setIsNewCompany: (state, action) => {
            state.isNewCompany = action.payload;
        },
    },
});

export const checkOnboardingStatus = (
    information: IOnboardingPostInformation,
    saveEmployer?: boolean,
    navigate?: (route: string) => void
): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            company: { information: companyInformation },
        } = getState();
        !saveEmployer && dispatch(showLoader(true));

        try {
            const response = await apiPostOnboardingStatus<ICurrentOnboardingState>(information);

            const checkPoliticDataDocument = (): boolean | null => {
                return Array.isArray(response?.data_processing)
                    ? response?.data_processing?.some(
                          item => item.document === NAME_DOCUMENTS[TypeDocumentNames.POLITIC_DATA] && item.is_dd
                      ) || false
                    : null;
            };

            dispatch(setDataProcessing(Array.isArray(response.data_processing) ? response.data_processing : []));
            dispatch(setDueDiligence(response.due_diligence));
            dispatch(setUrlEncrypt(response.encrypted));
            dispatch(setIsNewCompany(response.isNewCompany));

            if (!Array.isArray(response.data_processing)) {
                dispatch(setModalErroRequest(true));
            } else {
                response.data_processing = response.data_processing.filter(d => d.version);
            }

            if (response.data_processing.length) {
                switch (response.due_diligence) {
                    case TypeDiligence.APPROVE:
                        dispatch(saveUserStep(OnboardingStepsUser.FOURTH));
                        break;
                    case TypeDiligence.CREATED:
                        dispatch(saveUserStep(OnboardingStepsUser.THIRD));
                        break;
                    case TypeDiligence.REJECT:
                        dispatch(saveUserStep(OnboardingStepsUser.REJECT));
                        break;
                    case TypeDiligence.NOTIFIED:
                        if (response?.isNewCompany) {
                            const checkDataPolicy = checkPoliticDataDocument();
                            dispatch(
                                saveUserStep(
                                    OnboardingStepsUser.SECOND,
                                    handleDueDiligenceRedirect(checkDataPolicy, response?.encrypted)
                                )
                            );
                        }
                        break;
                    default:
                        dispatch(
                            saveUserStep(
                                OnboardingStepsUser.SECOND,
                                handleDueDiligenceRedirect(checkPoliticDataDocument(), response?.encrypted)
                            )
                        );
                        break;
                }
            }

            if (!response.data_processing.length && response.due_diligence === TypeDiligence.TO_CREATE) {
                const checkOpenTab = false;
                dispatch(saveUserStep(OnboardingStepsUser.SECOND, handleDueDiligenceRedirect(checkOpenTab, response?.encrypted)));
                return;
            }

            if (!response.data_processing.length) {
                checkIsSupport(companyInformation) && (await getStatusTransaction());
                const plans = await apiGetPlansEmployee<ICompanyPlan[]>();
                dispatch(setPlansEmployee(plans));
                dispatch(getPayments());

                if (plans?.length) {
                    dispatch(saveUserStep(OnboardingStepsUser.HOME));
                    navigate && navigate(getRoute(Routes.HOME));
                    return;
                }

                if (plans?.some(item => item.is_active)) {
                    dispatch(saveUserStep(OnboardingStepsUser.HOME));
                    navigate && navigate(getRoute(Routes.HOME));
                    return;
                }

                dispatch(saveUserStep(OnboardingStepsUser.PLANS));
                navigate && navigate(getRoute(Routes.PLANS));
            }
            dispatch(setLoaderRegisterCompany(false));
            dispatch(showLoader(false));
        } catch (error) {
            dispatch(setLoaderRegisterCompany(false));
            dispatch(showLoader(false));
            dispatch(setError(error));
            dispatch(setModalErroRequest(true));
            dispatch(setIsNewCompany(false));
        }
    };
};

const handleDueDiligenceRedirect = (checkOpenTab: boolean | null, urlEncrypted: string) => {
    return (): void => {
        if (!checkOpenTab && checkOpenTab !== null) {
            window.open(`${process.env.REACT_APP_FORM_DILIGENCE_URL}/${urlEncrypted}`, '_blank', 'noreferrer');
        }
    };
};

export const saveApprovalTerms = (data: ApprovalData, action?: () => void): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            await apiSaveApproval(data);
            action && action();
        } catch (error) {
            dispatch(setError(error));
        }
    };
};

export const saveApprovalElectronicPayroll = (data: ApprovalData, action?: () => void): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            await apiSaveApprovalElectronicPayroll(data);
            action && action();
        } catch (error) {
            dispatch(setError(error));
        }
    };
};

export const saveUserStep = (step: number, action?: () => void): AppThunk => {
    return async (dispatch, getState): Promise<void> => {
        const {
            onboarding: { userId },
        } = getState();
        try {
            await apiPostSaveStep({
                step,
                user_id: userId,
            });
            dispatch(setStep(step));
            action && action();
        } catch (error) {
            dispatch(setError(error));
        }
    };
};

export const {
    setDataProcessing,
    setDueDiligence,
    setStep,
    setUserId,
    setUrlEncrypt,
    setModalErroRequest,
    setIsNewCompany,
    setError,
} = onboardingSlide.actions;

export default onboardingSlide.reducer;
