// Libraries
import React from 'react';
// Models
import { IInformativeDateProps } from '../models/InformativeDate';
// Components
import { InformativeCard } from '@components/informative-card';
// Styles
import './InformativeDate.scss';

export const InformativeDate: React.FC<IInformativeDateProps> = ({ handleClose }) => {
    return (
        <InformativeCard position="left" handleClose={handleClose} className="informative-date">
            <div className="informative-date__body">
                Estos contratos están próximos a vencer, recuerda que un mes antes debes reportar al empleado de manera anticipada
                al vencimiento de su contrato.
            </div>
        </InformativeCard>
    );
};
