// Libraries
import React from 'react';
// Components
import { Icon } from '@components/icon';
// Models
import { ITableElectronicPayrollProps } from '../models/TableElectronicPayroll';
// Utils
import { formatNumber } from '@utils/Number';
// Styles
import './TableElectronicPayroll.scss';

export const TableElectronicPayroll: React.FC<ITableElectronicPayrollProps> = ({ data, transmit = false, handleModalDetail }) => {
    return (
        <table className="table-electronic-payroll" data-testid="table-transmit-electronic-payroll">
            <thead className="table-electronic-payroll__header">
                <tr className="header__row">
                    <th className="title-header">
                        Cantidad
                        <br /> documentos
                    </th>
                    <th className="title-header">
                        N° de
                        <br /> empleados
                    </th>
                    <th className="title-header">
                        Total
                        <br /> devengos
                    </th>
                    <th className="title-header">
                        Total
                        <br /> descuentos
                    </th>
                    <th className="title-header">
                        Total
                        <br /> salarios
                    </th>
                    <th className="title-header">Periodo</th>
                    {transmit && (
                        <>
                            <th className="title-header">Estado</th>
                            <th className="title-header">Detalle</th>
                        </>
                    )}
                </tr>
            </thead>
            <tbody className="table-electronic-payroll__body">
                {data?.map((element, index) => (
                    <tr className="item__row" key={`${index}-register`}>
                        <td className="column">
                            <label className="column--text">{element.documents_quantity}</label>
                        </td>
                        <td className="column">
                            <label className="column--text">{element.number_employees}</label>
                        </td>
                        <td className="column">
                            <label className="column--text">{formatNumber(element.total_accruals)}</label>
                        </td>
                        <td className="column">
                            <label className="column--text">{formatNumber(element.total_discounts)}</label>
                        </td>
                        <td className="column">
                            <label className="column--text">{formatNumber(element.total_salaries)}</label>
                        </td>
                        <td className="column">
                            <label className="column--text">{element.period}</label>
                        </td>
                        {transmit && (
                            <>
                                <td className="column">
                                    <label className="column--text">{element.state}</label>
                                </td>
                                <td className="column">
                                    <Icon
                                        name="simpleSearchGreen"
                                        className="icon--styles"
                                        type="svg"
                                        onClick={(): void => handleModalDetail()}
                                    />
                                </td>
                            </>
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
