// Redux
import { AppThunk } from '@redux/configureStore';
import { createSlice } from '@reduxjs/toolkit';
// Api
import { apiPostProvisions } from '@api/provisions';
// Models
import { IPostProvisionBody, IProvisionsState } from '@models/Provisions';

const initialState: IProvisionsState = {
    tableProvisions: [],
    employeeProvision: {
        id: '',
        employer_id: '',
        employee_id: '',
        employee_name: '',
        year_period_id: '',
        contract_id: '',
        wage: '',
        assistance: 0,
        days_worked: 0,
        provision_prima: '',
        provision_cesantias: '',
        provision_interests: '',
        provision_vacations: '',
        provision: [],
        document_number: '',
        document_type: '',
        not_apply_provision_cesantias: false,
        not_apply_provision_interest: false,
        not_apply_provision_prima: false,
        not_apply_provision_vacations: false,
        apply_transportation_assistance: false,
        days_transportation_assistance: 30,
    },
    error: '',
};

const provisionsSlice = createSlice({
    name: 'provisions',
    initialState,
    reducers: {
        setTableProvisions: (state, action) => {
            state.tableProvisions = action.payload;
        },
        setEmployeeProvision: (state, action) => {
            state.employeeProvision = action.payload;
        },

        failedRequest: (state, action) => {
            state.error = action.payload;
        },
    },
});

/**
 * Action to get table data.
 */
export const getProvisionsTable = (body: IPostProvisionBody): AppThunk => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await apiPostProvisions(body);
            if (!Array.isArray(response)) {
                dispatch(setTableProvisions([]));
                return;
            }
            dispatch(setTableProvisions(response));
        } catch (error) {
            dispatch(failedRequest('Error request data'));
        }
    };
};

export const { setTableProvisions, setEmployeeProvision, failedRequest } = provisionsSlice.actions;

export default provisionsSlice.reducer;
