export const useDisabledFields = (
    disabled: boolean,
    disabled_fields: boolean,
    validate: boolean,
    edit: boolean,
    onValidateClick: boolean
): {
    disabledFieldsStepOne: boolean;
} => {
    const disabledFields = disabled || disabled_fields;

    return {
        disabledFieldsStepOne: edit ? disabledFields : disabledFields || !validate || !onValidateClick,
    };
};
