/**
 * This constants is the type normal input.
 */
export enum InputType {
    TEXT = 'text',
    PASSWORD = 'password',
    NUMBER = 'number',
    ALPHANUMERIC = 'alphanumeric',
    ALPHABETICSPACES = 'alphabeticspaces',
}

/**
 * This enum represent all inputs length.
 */
export enum InputLength {
    phone = 10,
}

export const REGEX_FORMAT = {
    ESPECIAL_CHARACTER: /^[a-zA-Z0-9\s]+$/,
};

export const specialFields = ['names', 'second_name', 'last_names', 'second_last_name'];
export const validateOnlyIntegers = ['e', 'E', '+', '-', '.', ','];
export const INPUT_NAMES = ['percentage', 'fullSalary', 'hasFullPayDays', 'parafiscalContributions'];
export const ENTER = 'Enter';
export const LIMIT_VALUE = 500000;
export enum DATE_FORMAT {
    DAY,
    MONTH,
    YEAR,
}
