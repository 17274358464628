export const yearPeriodPayslips = '13479DDF-7571-4494-B27D-62639BD1F993';

export const errorContributorNotRegistered = 'El cotizante no está registrado.';
export const errorTypeDocument = 'El tipo de documento no es válido.';
export const errorContributor = 'El usuario no se encuentra relacionado al aportante.';

export const existEmployeeMessage = 'Employee already exists';
export const existEmployeeEsMessage = 'El empleado ya existe.';

export const errorBase = 'No se encontró información para el tipo y número de documento solicitado.';

export const errorUserPila = 'El usuario o la contraseña ingresados son incorrectos.';
export const errorBlockedAccount =
    "Su cuenta ha sido bloqueada por múltiples intentos fallidos. Para desbloquearla, por favor ingrese a la opción 'Olvidé mi contraseña'.";

export const errorMessageBlockedAccount =
    "Su cuenta ha sido bloqueada por múltiples intentos fallidos. Para desbloquearla, por favor ingresé a la opción 'Olvidé mi contraseña en el portal de PILA en el siguiente link www.aportesenlinea.com'";

export const THREE = 3;

export const MESSAGE_UNABLE_CREDENTIALS = 'Este servicio no está disponible para las credenciales ingresadas.';
