// Libraries
import React from 'react';
import { Formik } from 'formik';
// Components
import { Input } from '@components/input';
import { Button } from '@components/button';
// Models
import { IAddDetailProps } from '@pages/employees/components/models/EmployeeComponents';

export const AddDetail: React.FC<IAddDetailProps> = ({
    name,
    label,
    disabled,
    onClick,
    handleAdd,
    handleCancel,
    create,
    edit,
    message,
    id,
    options,
}) => {
    const currentOption = options?.find(option => id && option.value === id);

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{ [name]: create && edit ? currentOption?.label || '' : '' }}
                onSubmit={(): void => {}}
            >
                {({ values, resetForm }): React.ReactNode => (
                    <div className="container__flex-create">
                        {create && <Input name={name} label={label} inputClassContainer="container_mb" disabled={disabled} />}
                        <div className="action__container">
                            {create && (
                                <div className="container__icon" onClick={handleCancel}>
                                    <Button onClick={handleCancel} type="button">
                                        Volver
                                    </Button>
                                </div>
                            )}
                            {!create && !edit ? (
                                <label className="link--custom" onClick={!disabled ? onClick : (): void => {}}>
                                    + Adicionar {message}
                                </label>
                            ) : (
                                <Button
                                    color="green-light-3"
                                    onClick={(): void => {
                                        handleAdd({ name: values[name] });
                                        resetForm({ [name]: '' });
                                    }}
                                    type="button"
                                >
                                    {create && !edit ? 'Crear' : 'Guardar'}
                                </Button>
                            )}
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
};
