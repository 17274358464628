import { Routes } from '@components/paths';
import { getRoute } from '@utils/Paths';
import { useLocation } from 'react-router-dom';

export const useFloatingButton = (): { isVisible: boolean } => {
    const location = useLocation();

    return {
        isVisible:
            location.pathname === getRoute(Routes.HOME) ||
            location.pathname === getRoute(Routes.PAYROLL_ADJUSTER) ||
            location.pathname === getRoute(Routes.PILA) ||
            location.pathname === getRoute(Routes.ELECTRONIC_PAYROLL),
    };
};
