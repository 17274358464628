// React
import { ChangeEvent } from 'react';
// Component-types
import { IconsNames, IMenuPlacemnt } from '@components/icon';
import { ISelectValue } from './models/Input';

export * from './Input';

type SelectTextColor = 'green';

/**
 * Interface from options select
 * @constructor
 * @param {string} key - Prop to set key from option select
 * @param {string} value - Prop to set value from option select
 * @param {string} name - Prop to set name from option select
 */
export interface IOption {
    key: string;
    value: string;
    name: string;
}

/**
 * Interface option select
 * @constructor
 * @param {string} country_state_id - Optional country state relation.
 * @param {string} label - Show labels from select
 * @param {string} value - Value from selected items
 * @param {string} other_value - Optional other value
 * @param {string} id - Optional id from select
 * @param {string} color - Optional color option
 */
export interface IOptionSelect {
    label: string;
    value: string;
    country_state_id?: string;
    other_value?: string;
    id?: string;
    color?: SelectTextColor;
}

/**
 * Interface to get props from input.
 * @constructor
 * @param {string} label - Optional prop to set label text from input.
 * @param {boolean} symbolIcon - Optional prop to set boolean state to show symbol icon.
 * @param {IOption[]} options - Optional prop to set options from select.
 * @param {string} selectClassContainer - Optional select styles container.
 * @param {string} inputClassContainer - Optional input styles container.
 * @param {IconsNames} customIcon - Optional custom select icon.
 * @param {boolean} searchOnlyAlphabet - Optional search or not only alphabet.
 * @param {boolean} disabled - Optional disabled input.
 * @param {string} disabled - Optional classes label.
 * @param {boolean} onlyIntegers - Optional only integers input
 * @param {(e: ISelectValue | unknown) => void} onChange - Optional handle change
 * @param {boolean} tooltip - Optional tooltip information
 * @param {string | JSX.Element } textTooltip - Optional text tooltip / jsx element
 * @param {string} menuPlacement - Optional menu placement
 * @param {number} maxMenuHeight - Optional max menu height
 * @param {boolean} centerInputValues - Optional center input values
 * @param {string} maxWidthTooltip - Optional max width tooltip
 * @param {boolean} disabledFilterOption - Optional state to disabled filter option by start
 * @param {() => void} onCustomChange - Optional on custom change input
 */
export interface ICustomInput {
    label?: string;
    symbolIcon?: boolean;
    options?: IOptionSelect[];
    selectClassContainer?: string;
    inputClassContainer?: string;
    customIcon?: IconsNames;
    isSearchable?: boolean;
    searchOnlyAlphabet?: boolean;
    disabled?: boolean;
    classLabel?: string;
    onlyIntegers?: boolean;
    onChange?: (e: ISelectValue | unknown) => void;
    tooltip?: boolean;
    textTooltip?: string | JSX.Element;
    classTooltipArea?: string;
    menuPlacement?: IMenuPlacemnt;
    maxMenuHeight?: number;
    centerInputValues?: boolean;
    maxWidthTooltip?: string;
    valueAlt?: string;
    placeholder?: string;
    disabledFilterOption?: boolean;
    onCustomChange?: (value: string) => void;
    selectDisabledClass?: string;
    hiddenIconSelect?: boolean;
    showError?: boolean;
}

/**
 * Interface to get props from input with icon.
 * @constructor
 * @param {boolean} hasIcon - Optional prop to get icons.
 * @param {boolean} disabled - Optional prop to disable input.
 * @param {IconsNames} customIcon - Optional prop for custom icon.
 * @param {string} iconStyles - Optional prop to add icon styles.
 * @param {boolean} onlyIntegers - Optional prop to manage integer values.
 * @param {boolean} centerInputValues - Optional prop to center input values.
 * @param {string} inputClassContainer - Prop to add styles to main class.
 * @param {(e: ChangeEvent<HTMLInputElement>) => void} onChange - Handle change input.
 */
export interface ICustomInputIcon {
    type: string;
    hasIcon: boolean;
    disabled?: boolean;
    placeholder: string;
    customIcon?: IconsNames;
    iconStyles?: string;
    onlyIntegers?: boolean;
    centerInputValues?: boolean;
    inputClassContainer: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}
