import { useAppSelector } from '@hooks/redux';
import { TTextTooltipSalary } from '@pages/employees/components/models/EmployeeComponents';
import { ToolTipTexts } from '@pages/employees/constants/Tooltips';
import { RootState } from '@redux/rootReducer';
import { formatNumber } from '@utils/Number';
import React from 'react';

export const TextTooltipSalary: React.FC<TTextTooltipSalary> = ({ contractTypeSena }) => {
    const {
        utils: { integral_minimum_wage },
    } = useAppSelector((state: RootState) => state);

    return (
        <p>
            {contractTypeSena && (
                <>
                    {ToolTipTexts.SALARY_STUDENT}
                    <br></br>
                    <br></br>
                </>
            )}
            Recuerda que el SMMLV del año en curso es de {formatNumber(integral_minimum_wage.toString())}.
        </p>
    );
};
