// Libraries
import { useEffect, useState } from 'react';
import moment from 'moment';
// Redux
import { RootState } from '@redux/rootReducer';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { getCarouselPeriods, setCurrentPeriod } from '@redux/periods/periods.slice';
import { setCustomLoader } from '@redux/pila/pila.slice';
// Api
import {
    postGeneratePayroll,
    setConfirmed,
    setErrorModal,
    setErrorGeneratePayrollModal,
} from '@redux/electronic-payroll/electronicPayroll.slice';
// Models
import { IValidateSalary } from '@models/ValidateSalary';
import { IDataTablePayroll } from '@models/ElectronicPayroll';
import { IDataErrors } from '../components/models/ModalMistake';
import { IPeriods } from '@models/Periods';
// Constants
import { WITHOUT_TRANSMIT } from '../constants/Transmit';
// Utils
import { capitalizeFirstLetter } from '@utils/Text';

export const useTransmitElectronicPayroll = (): {
    periods: IPeriods[];
    currentDate: IPeriods;
    dataPayroll: IDataTablePayroll[];
    confirmed: boolean;
    transmitConfirmed: boolean;
    errorModal: boolean;
    errorGeneratePayrollModal: boolean;
    dataErrorModal: IDataErrors[];
    handleGeneratePayroll: () => Promise<void>;
    handleOpenModalError: () => void;
    handleOpenModalErrorGenerate: () => void;
    handleChangeCard: (item: number) => void;
    handleGetDateModal: () => string;
} => {
    const dispatch = useAppDispatch();
    const {
        periods: { periods, currentPeriod },
        electronicPayroll: { payroll, confirmed, errorModal, responseConfirm, errorGeneratePayrollModal },
        session: { loginUser },
    } = useAppSelector((state: RootState) => state);
    const [dataPayroll, setDataPayroll] = useState<IDataTablePayroll[]>([]);
    const [transmitConfirmed, setTransmitConfirmed] = useState<boolean>(confirmed);
    const [dataErrorModal, setDataErrorModal] = useState<IDataErrors[]>([]);
    const [currentPeriods, setCurrentPeriods] = useState<IPeriods[]>([]);
    const [currentDate, setCurrentDate] = useState<IPeriods>(currentPeriod);
    const currentMonth = moment().format('MM');
    const currentYear = moment().year();

    useEffect(() => {
        loginUser?.lastLogin && dispatch(getCarouselPeriods());
    }, []);

    useEffect(() => {
        setTransmitConfirmed(false);
    }, [responseConfirm, currentDate]);

    useEffect(() => {
        formatDataPayroll();
    }, [payroll, transmitConfirmed]);

    useEffect(() => {
        responseConfirm && handleDataErrorModal();
    }, [responseConfirm]);

    useEffect(() => {
        if (periods?.length) {
            const newPeriods = [];
            const currentPeriodIndex = periods?.findIndex(period => period.code === `${currentYear}-${currentMonth}`);
            if (currentPeriodIndex != -1) {
                if (currentPeriodIndex > 0) {
                    newPeriods.push(periods[currentPeriodIndex - 1]);
                }
                newPeriods.push(periods[currentPeriodIndex]);
            }
            setCurrentPeriods(newPeriods);
        }
    }, [periods]);

    /**
     * Format data table from electronic payroll
     */
    const formatDataPayroll = (): void => {
        if (payroll && payroll?.json_data && transmitConfirmed) {
            const baseData = JSON.parse(payroll?.json_data);
            const tableData: IDataTablePayroll[] = [];

            const payrollItem = {
                documents_quantity: baseData?.Trabajador?.length || 0,
                number_employees: getNumberEmployees(baseData?.Trabajador),
                total_accruals: baseData?.Trabajador?.reduce(
                    (prev: number, curr: IValidateSalary) => curr?.DevengadosTotal + prev,
                    0
                ),
                total_discounts: baseData?.Trabajador?.reduce(
                    (prev: number, curr: IValidateSalary) => curr?.DeduccionesTotal + prev,
                    0
                ),
                total_salaries: baseData?.Trabajador?.reduce(
                    (prev: number, curr: IValidateSalary) => parseInt(curr?.Sueldo) + prev,
                    0
                ),
                period: moment(baseData?.Periodo?.FechaGen, 'YYYY-MM-DD').format('MM/YYYY'),
                state: capitalizeFirstLetter(payroll?.status) || WITHOUT_TRANSMIT,
            };

            tableData.push(payrollItem);
            setDataPayroll(tableData);
            dispatch(setConfirmed(payroll.is_success));
            setTransmitConfirmed(true);
        } else {
            setDataPayroll([]);
            setTransmitConfirmed(false);
        }
    };

    /**
     * Get number employees
     * @param {IValidateSalary[]} data - Data list to count
     * @returns {number} - Number employees
     */
    const getNumberEmployees = (data: IValidateSalary[]): number => {
        const employees: IValidateSalary[] = [];

        data.forEach(employee => {
            const index = employees?.findIndex(item => item?.NumeroDocumento === employee?.NumeroDocumento);
            if (index === -1) {
                employees.push(employee);
            }
            return employees;
        });

        return employees.length;
    };

    /**
     * Action to generate payroll
     */
    const handleGeneratePayroll = async (): Promise<void> => {
        await dispatch(postGeneratePayroll(currentDate?.period.id));
        setTransmitConfirmed(true);
    };

    /**
     * Action to open error modal from confirm electronic payroll
     */
    const handleOpenModalError = (): void => {
        dispatch(setErrorModal());
    };

    /**
     * Action to open error modal from generate electronic payroll
     */
    const handleOpenModalErrorGenerate = (): void => {
        dispatch(setErrorGeneratePayrollModal());
    };

    /**
     * Action to format data modal error when you confirm electronic payroll
     */
    const handleDataErrorModal = (): void => {
        const errors: IDataErrors[] = [];
        responseConfirm?.descripcion?.map(error => errors.push({ error }));
        setDataErrorModal(errors);
    };

    /**
     * Action to get data pila
     * @param {number} index - Number index from current slide
     */
    const handleChangeCard = (index: number): void => {
        setCurrentDate(currentPeriods[index]);
        dispatch(setCurrentPeriod(currentPeriods[index]));
        if (currentDate?.id) {
            dispatch(setCustomLoader(true));
        }
    };

    /**
     * Action to get date by current period
     * @returns {string}
     */
    const handleGetDateModal = (): string => {
        const words = currentDate?.name?.split(' ');
        if (words?.length > 0) {
            const [month, year] = words;
            const newNameMonth = month?.slice(0, 3)?.toUpperCase();
            return `${newNameMonth}-${year}`;
        }
        return '';
    };

    return {
        periods: currentPeriods,
        currentDate,
        dataPayroll,
        confirmed,
        transmitConfirmed,
        errorModal,
        errorGeneratePayrollModal,
        dataErrorModal,
        handleGeneratePayroll,
        handleOpenModalError,
        handleOpenModalErrorGenerate,
        handleChangeCard,
        handleGetDateModal,
    };
};
