// Libraries
import React from 'react';
// Components
import { Icon } from '@components/icon';
// Models
import { ITitleProps } from './models/Title';
// Styles
import './Title.scss';

export const Title: React.FC<ITitleProps> = ({ title = '', iconName, className = '', iconClassName = '' }) => {
    return (
        <div className="title__container">
            {iconName && (
                <div className="title__container--icon">
                    <Icon name={iconName} className={`width-icon ${iconClassName}`} />
                </div>
            )}
            <h1 className={`title ${className}`}>{title}</h1>
        </div>
    );
};

// TODO: Refactor name this component to 'Title' when all the pages have the same component.
export const RoundTitle: React.FC<ITitleProps> = ({
    title = '',
    iconName,
    className = '',
    iconClassName = '',
    type = 'png',
    subtitle,
}) => {
    return (
        <div className="round-title__container">
            {iconName && (
                <div className="round-title__container--border-icon">
                    <div className="round-title__container--icon">
                        <Icon name={iconName} className={`width-icon ${iconClassName}`} type={type} />
                    </div>
                </div>
            )}
            <div className="container__title">
                <h1 className={`title ${className} ${subtitle ? 'margin-subtitle' : ''}`}>{title}</h1>
                {subtitle && <label className="subtitle">{subtitle}</label>}
            </div>
        </div>
    );
};

export const SubTitle: React.FC<ITitleProps> = ({ title = '', className = '' }) => {
    return (
        <div className="subtitle__container">
            <h1 className={`subtitle ${className}`}>{title}</h1>
        </div>
    );
};
