import React from 'react';
import { IPath, RouteType } from '@models/Paths';

//Pages
const Home = React.lazy(() => import('@pages/home'));
const Company = React.lazy(() => import('@pages/company'));
const Employees = React.lazy(() => import('@pages/employees'));
const PayrollAdjuster = React.lazy(() => import('@pages/payroll-adjuster'));
const PreviewPayrollAdjuster = React.lazy(() => import('@pages/preview-payroll-adjuster'));
const PreviewNoveltyLiquidator = React.lazy(() => import('@pages/preview-novelty-liquidator'));
const PremiumServices = React.lazy(() => import('@pages/premium-services'));
const SeverancePay = React.lazy(() => import('@pages/severance-pay'));
const InterestSeverancePay = React.lazy(() => import('@pages/interest-severance-pay'));
const NewSummary = React.lazy(() => import('@pages/new-summary'));
const ResumenLiquidation = React.lazy(() => import('@pages/resume-liquidation'));
const CalculationProvisionsDetails = React.lazy(() => import('@pages/calculation-provisions-details'));
const PaymentSlips = React.lazy(() => import('@pages/payment-slips'));
const Reports = React.lazy(() => import('@pages/reports'));
const Pila = React.lazy(() => import('@pages/pila'));
const PilaDetail = React.lazy(() => import('@pages/pila-detail'));
const PayrollReport = React.lazy(() => import('@pages/payroll-report'));
const PilaReport = React.lazy(() => import('@pages/pila-report'));
const PilaReportCertification = React.lazy(() => import('@pages/pila-report-certification'));
const ElectronicPayroll = React.lazy(() => import('@pages/electronic-payroll'));
const ElectronicPayrollReport = React.lazy(() => import('@pages/electronic-payroll-report'));
const Plans = React.lazy(() => import('@pages/plans'));
const RegisterRequest = React.lazy(() => import('@pages/register-request'));
const PayrollPlans = React.lazy(() => import('@pages/payroll-plans'));

export enum Routes {
    HOME,
    COMPANY,
    EMPLOYEES,
    PAYROLL_ADJUSTER,
    PREVIEW_PAYROLL_ADJUSTER,
    PREVIEW_NOVELTIES_LIQUIDATOR,
    STACK,
    ELECTRONIC_PAYROLL,
    ELECTRONIC_PAYROLL_REPORT,
    REPORTS,
    PREMIUM_SERVICES,
    SEVERANCE_PAY,
    INTEREST_SEVERANCE_PAY,
    NEW_SUMMARY,
    RESUMEN_LIQUIDATION,
    CALCULATION_DETAILS,
    PAYMENT_SLIPS,
    PILA,
    PILA_DETAIL,
    PAYROLL_REPORT,
    PILA_REPORT,
    PILA_REPORT_CERTIFICATION,
    PLANS,
    REGISTER_REQUEST,
    PAYROLL_PLANS,
}

export const PATHS: IPath = {
    [Routes.HOME]: {
        route: '/',
        component: <Home />,
        type: RouteType.PUBLIC,
        title: 'Inicio',
    },
    [Routes.COMPANY]: {
        route: '/company',
        component: <Company />,
        type: RouteType.PRIVATE,
        title: 'Datos empresa',
    },
    [Routes.EMPLOYEES]: {
        route: '/employees',
        component: <Employees />,
        type: RouteType.PRIVATE,
        title: 'Mis empleados',
    },
    [Routes.PAYROLL_ADJUSTER]: {
        route: '/payroll-adjuster',
        component: <PayrollAdjuster />,
        type: RouteType.PRIVATE,
        title: 'Liquidador de nómina',
    },
    [Routes.PREVIEW_PAYROLL_ADJUSTER]: {
        route: '/payroll-adjuster/preview',
        component: <PreviewPayrollAdjuster />,
        type: RouteType.PRIVATE,
        title: 'Detalle',
    },
    [Routes.PREVIEW_NOVELTIES_LIQUIDATOR]: {
        route: '/payroll-adjuster/preview-novelties-liquidator',
        component: <PreviewNoveltyLiquidator />,
        type: RouteType.PRIVATE,
        title: 'Novedades empleados',
    },
    [Routes.STACK]: {
        route: '/stack',
        component: <PayrollAdjuster />,
        type: RouteType.PRIVATE,
        title: 'Pila',
    },
    [Routes.REPORTS]: {
        route: '/reports',
        component: <Reports />,
        type: RouteType.PRIVATE,
        title: 'Reportes',
    },
    [Routes.ELECTRONIC_PAYROLL]: {
        route: '/electronic-payroll',
        component: <ElectronicPayroll />,
        type: RouteType.PRIVATE,
        title: 'Nómina electrónica',
    },
    [Routes.PREMIUM_SERVICES]: {
        route: '/payroll-adjuster/premium-services',
        component: <PremiumServices />,
        type: RouteType.PRIVATE,
        title: 'Prima de servicios',
    },
    [Routes.SEVERANCE_PAY]: {
        route: '/payroll-adjuster/severance-pay',
        component: <SeverancePay />,
        type: RouteType.PRIVATE,
        title: 'Cesantías',
    },
    [Routes.INTEREST_SEVERANCE_PAY]: {
        route: '/payroll-adjuster/interest-severance-pay',
        component: <InterestSeverancePay />,
        type: RouteType.PRIVATE,
        title: 'Intereses sobre cesantías',
    },
    [Routes.NEW_SUMMARY]: {
        route: '/new-summary',
        component: <NewSummary />,
        type: RouteType.PRIVATE,
        title: 'Resumen novedades',
    },
    [Routes.RESUMEN_LIQUIDATION]: {
        route: '/resumen-liquidator',
        component: <ResumenLiquidation />,
        type: RouteType.PRIVATE,
        title: 'Resumen liquidación',
    },
    [Routes.CALCULATION_DETAILS]: {
        route: '/payroll-adjuster/calculation-details',
        component: <CalculationProvisionsDetails />,
        type: RouteType.PRIVATE,
        title: 'Detalle de calculo',
    },
    [Routes.PAYMENT_SLIPS]: {
        route: '/payroll-adjuster/payment-slips',
        component: <PaymentSlips />,
        type: RouteType.PRIVATE,
        title: 'Desprendibles de pago',
    },
    [Routes.PILA]: {
        route: '/pila',
        component: <Pila />,
        type: RouteType.PRIVATE,
        title: 'Pila',
    },
    [Routes.PILA_DETAIL]: {
        route: '/pila/detail',
        component: <PilaDetail />,
        type: RouteType.PRIVATE,
        title: 'Detalle de pila',
    },
    [Routes.PAYROLL_REPORT]: {
        route: '/reports/payroll-report',
        component: <PayrollReport />,
        type: RouteType.PRIVATE,
        title: 'Reporte nómina',
    },
    [Routes.PILA_REPORT]: {
        route: '/reports/pila-report',
        component: <PilaReport />,
        type: RouteType.PRIVATE,
        title: 'Reporte PILA',
    },
    [Routes.PILA_REPORT_CERTIFICATION]: {
        route: '/reports/pila-report-certification',
        component: <PilaReportCertification />,
        type: RouteType.PRIVATE,
        title: 'Reporte PILA - Certificado de aportes',
    },
    [Routes.ELECTRONIC_PAYROLL_REPORT]: {
        route: '/reports/electronic-payroll-report',
        component: <ElectronicPayrollReport />,
        type: RouteType.PRIVATE,
        title: 'Reporte Nómina electrónica',
    },
    [Routes.PLANS]: {
        route: '/plans',
        component: <Plans />,
        type: RouteType.PUBLIC,
        title: 'Planes AEL',
    },
    [Routes.REGISTER_REQUEST]: {
        route: '/register-request',
        component: <RegisterRequest />,
        type: RouteType.PUBLIC,
        title: 'Registra tu solicitud',
    },
    [Routes.PAYROLL_PLANS]: {
        route: '/plans/payroll',
        component: <PayrollPlans />,
        type: RouteType.PRIVATE,
        title: 'Planes',
    },
};
