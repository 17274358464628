// Libraries
import React from 'react';
import ModalBase, { Props } from 'react-modal';
// Loader
import loader from '@assets/icons/loader.gif';
// Styles
import './Loader.scss';

export const Loader: React.FC<Props> = ({ isOpen = false }) => {
    return (
        <ModalBase isOpen={isOpen} className="loader" portalClassName="loader-portal" ariaHideApp={false}>
            <img src={loader} alt="loader" />
        </ModalBase>
    );
};
