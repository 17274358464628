// Libraries
import { useEffect, useState } from 'react';
// Hooks
import { useAppDispatch, useAppSelector } from '@hooks/redux';
// Redux
import { RootState } from '@redux/rootReducer';
// Constants
import { TypeAdministrator } from '../constants/Employees';
// Models
import { IDataEmployeeStack } from '../models/Employee';
import { typesContributor } from '@models/Utils';

// Redux
import { setModalError } from '@redux/employee/employee.slice';

export const useEmployeeStack = (): {
    disabledFields: boolean;
    dataEmployeeStack: IDataEmployeeStack;
    handleCloseModalError: () => void;
    showModalError: boolean;
    messageError: string;
    validateContributor: boolean;
} => {
    const {
        employee: { disabled_fields, employee_stack, modal_error, validateContributor },
        utils: { administrator, type_contributor, document_type, salary_type, subtype_contributor },
    } = useAppSelector((state: RootState) => state);

    const [dataEmployeeStack, setDataEmployeeStack] = useState<IDataEmployeeStack>({});
    const dispatch = useAppDispatch();

    const removeAccents = (text: string): string =>
        text
            ?.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase() || '';

    const findItem = (text = '', typeAdministrator: typesContributor): string =>
        administrator
            .filter(({ type }) => type === typeAdministrator)
            .find(item => {
                const unaccentedText = removeAccents(text);
                const parseText = unaccentedText.includes('(') ? unaccentedText.split('(')[0].trim() : unaccentedText;

                if (parseText === removeAccents(item.name_ael)) return item;
            })?.id || '';

    const refactorEmployeeStack = (): void => {
        const epsId = findItem(employee_stack?.NombreEPS || employee_stack?.NombreEps, TypeAdministrator.EPS);

        const afpId = findItem(employee_stack?.NombreAFP || employee_stack?.NombreAfp, TypeAdministrator.PENSIONS);

        const arlId = findItem(employee_stack?.NombreARL, TypeAdministrator.ARL);

        const currentTypeContributorId = type_contributor.find(
            item =>
                removeAccents(item.name) ===
                removeAccents(employee_stack?.NombreTipoCotizante || employee_stack?.TipoCotizante || '')
        )?.id;

        const currentTypeDocumentId = document_type.find(document => document.code === employee_stack?.TipoDocumento)?.id;

        const currentSubtypeContributorId = subtype_contributor.find(
            item => item.code == Number(employee_stack?.SubTipoCotizante)
        )?.id;

        const currentTypeSalary = (): string => {
            if (employee_stack?.SalarioIntegral) {
                return salary_type.find(salary => salary.name === 'Salario Integral')?.id || '';
            }
            if (employee_stack?.SalarioVariable) {
                return salary_type.find(salary => salary.name === 'Salario Variable')?.id || '';
            }

            return '';
        };

        setDataEmployeeStack({
            name: employee_stack?.PrimerNombre,
            second_name: employee_stack?.SegundoNombre,
            last_name: employee_stack?.PrimerApellido,
            second_last_name: employee_stack?.SegundoApellido,
            eps_id: epsId,
            arl_id: arlId,
            afp_id: afpId,
            phone: employee_stack?.Celular,
            type_contributor_id: currentTypeContributorId,
            document_type_id: currentTypeDocumentId,
            email: employee_stack?.CorreoElectronico,
            document_number: employee_stack?.NumeroDocumento,
            colombian_resident_abroad: employee_stack?.ColombianoEnElExterior,
            date_of_filing_in_ext: employee_stack?.FechaResidenciaExterior,
            salary: employee_stack?.Salario,
            date_start: employee_stack?.FechaIngreso,
            date_end: employee_stack?.FechaRetiro,
            type_salary_id: currentTypeSalary(),
            subtype_contributor_id: currentSubtypeContributorId,
            exempt_from_parafiscal_taxes: employee_stack?.ExoneradoParafiscalesYSalud,
            name_branch: employee_stack?.CodigoSucursal,
            work_center: employee_stack?.CodigoCentroTrabajo,
        });
    };

    const handleCloseModalError = (): void => {
        dispatch(
            setModalError({
                open_modal: false,
                message: '',
            })
        );
    };

    useEffect(() => {
        if (employee_stack) {
            refactorEmployeeStack();
        } else {
            setDataEmployeeStack({});
        }
    }, [employee_stack]);

    return {
        disabledFields: disabled_fields,
        dataEmployeeStack,
        handleCloseModalError,
        showModalError: modal_error?.open_modal,
        messageError: modal_error?.message,
        validateContributor,
    };
};
