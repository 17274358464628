// Libraries
import { useEffect } from 'react';
// Models
import { IPlanEmployer } from '@models/Plans';
// Redux
import { RootState } from '@redux/rootReducer';
import { getPlansEmployee } from '@redux/plans/plans.slice';
import { useAppDispatch, useAppSelector } from './redux';

export const useGetActivePlan = (): { userPlan: IPlanEmployer | undefined } => {
    const {
        plans: { activePlan, plansEmployee },
        company: { id },
    } = useAppSelector((state: RootState) => state);
    const dispatch = useAppDispatch();

    const lastPlan = plansEmployee?.at(-1);
    const userPlan = !activePlan ? lastPlan : activePlan;

    useEffect(() => {
        id && dispatch(getPlansEmployee());
    }, [id]);

    return {
        userPlan,
    };
};
