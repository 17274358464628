// Libraries
import React from 'react';
import { NavLink } from 'react-router-dom';
// Redux
import { clearSession } from '@redux/session/session.slice';
// Hooks
import { useAppDispatch } from '@hooks/redux';
import { useActiveLink } from './hooks/useActiveLink';
import { useGetNavItems } from './hooks/useGetNavItems';
import { useIsCustomLoader } from '@hooks/useIsCustomLoader';
import { useNavBar } from './hooks/useNavBar';
// Components
import { NavBarResponsive, NavMenu } from '@components/navbar/components';
import { Icon } from '@components/icon';
import { Routes } from '@components/paths';
// Models
import { INavbar, INavItems } from './models/Navbar';
// Constants
import { OnboardingStepsUser } from '@constants/Onboarding';
// Utils
import { getRoute } from '@utils/Paths';
// Assets
import logo from '@assets/logos/logo-nav-green.png';
// Style
import './Navbar.scss';

export const Navbar: React.FC<INavbar> = ({
    isAuthenticated = false,
    handleLogoutRedirect,
    information,
    guideTour,
    indexStepTour = 0,
}) => {
    const [dispatch, { generateNavItems }, { isCustomLoader }] = [
        useAppDispatch(),
        useGetNavItems(),
        useIsCustomLoader(OnboardingStepsUser.HOME),
    ];
    const { applyZIndex, menuState, toggleMenu } = useNavBar(isCustomLoader);

    /**
     * Action to logout user
     */
    const logOut = (): void => {
        isAuthenticated && dispatch(clearSession());
        handleLogoutRedirect();
    };

    const validateHasPilaUser = !!information?.pila_user && !!information.pila_password;

    return (
        <div className="navbar">
            <NavLink
                className={`navbar__container-img ${isCustomLoader && 'navbar__container-img--z-index'}`}
                to={getRoute(Routes.HOME)}
            >
                <img src={logo} alt="logo" />
            </NavLink>
            <div className="body">
                {isAuthenticated && (
                    <>
                        <div className="navbar__responsive">
                            <NavBarResponsive
                                information={information}
                                logOut={logOut}
                                menuState={menuState}
                                toggleMenu={toggleMenu}
                                indexStepTour={indexStepTour}
                            />
                        </div>
                        <div className={`body__profile ${isCustomLoader && 'body__profile--z-index'}`}>
                            <div className="body__profile--container">
                                <div className="container-image">
                                    <Icon name="buildingGreen" className="image" />
                                </div>
                                <span>{information?.name}</span>
                                <NavMenu
                                    information={information}
                                    logOut={logOut}
                                    menuState={menuState}
                                    toggleMenu={toggleMenu}
                                    indexStepTour={indexStepTour}
                                />
                            </div>
                        </div>
                    </>
                )}
                <div className={`body__items ${applyZIndex && 'body__items--z-index'}`}>
                    {isAuthenticated && (
                        <>
                            {generateNavItems().map(({ name, route, icon, activeIcon, subRoutes }: INavItems) => {
                                const { active } = useActiveLink(route, subRoutes);
                                return (
                                    <div key={name}>
                                        <NavLink
                                            key={name}
                                            className={`body__items--item ${
                                                active && `body__items--active ${!guideTour ? 'items-nav--padding' : ''}`
                                            } ${!validateHasPilaUser && 'body__items--pila-user'}`}
                                            id="item-nav"
                                            to={!validateHasPilaUser ? '#' : route}
                                        >
                                            <Icon name={active ? activeIcon : icon} />
                                            {name}
                                        </NavLink>
                                    </div>
                                );
                            })}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
