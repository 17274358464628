// Libraries
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Redux
import { RootState } from '@redux/rootReducer';
import { getTokenElectronicPayroll, setCurrentTab } from '@redux/electronic-payroll/electronicPayroll.slice';
// Hooks
import { useAppDispatch, useAppSelector } from '@hooks/redux';
// Models
import { ICompanyPlan } from '@models/Plans';
import { IPlanEmployer } from '@models/Plans';
import { ICompanyInformation } from '@models/Company';
// Components
import { Routes } from '@components/paths';
// Utils
import { getRoute } from '@utils/Paths';

export const useElectronicPayroll = (
    mainPage?: boolean
): {
    currentTab: string;
    activePlan: IPlanEmployer | null;
    handleChangeTab: (tab: string) => void;
    plan: ICompanyPlan;
    handleBuyPayroll: () => void;
    companyInformation: ICompanyInformation;
} => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        plans: { activePlan },
        electronicPayroll: { currentTab },
        session: { loginUser },
        company: { plan, information },
    } = useAppSelector((state: RootState) => state);

    useEffect(() => {
        mainPage && loginUser?.lastLogin && dispatch(getTokenElectronicPayroll());
    }, []);

    const handleChangeTab = (tab: string): void => {
        dispatch(setCurrentTab(tab));
    };

    const handleBuyPayroll = (): void => {
        navigate(getRoute(Routes.PAYROLL_PLANS));
    };

    return {
        activePlan,
        currentTab,
        handleChangeTab,
        plan,
        handleBuyPayroll,
        companyInformation: information,
    };
};
