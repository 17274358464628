import { TypesDocumentIds } from '@pages/employees/constants/Employees';

/**
 * This gives the document type name.
 * @param {string} id - Document type id
 * @returns {string}
 */
export const getTypeDocumentName = (id: string): string => {
    return id === TypesDocumentIds.NIT ? 'nit' : 'cc';
};

/**
 * Util to get the values of the document and verification.
 * @param {string} id - Document type id
 * @param {string} document - Document number
 * @returns {{document: string; verification: string}}
 */
export const getValuesDocumentNit = (id: string, document: string): { document: string; verification: string } => {
    if (id !== TypesDocumentIds.NIT) {
        return {
            document,
            verification: '',
        };
    }
    const formatDocument = document.slice(0, document.length - 1);
    const verification = document.slice(document.length - 1);

    return {
        document: formatDocument,
        verification,
    };
};
