// Libraries
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
// Models
import { RootState } from '@redux/rootReducer';
import { IItemEmployee } from '@models/Employee';
import { IPlanEmployer } from '@models/Plans';
import { ICompanyInformation, IHomeInformation } from '@models/Company';
// Redux
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { setFunderId } from '@redux/company/company.slice';
import { getCurrentHomePeriod } from '@redux/payslips/payslips.slice';
import { setGuideTour, setLoginUser } from '@redux/session/session.slice';
import { getPlansEmployee } from '@redux/plans/plans.slice';
import { getActiveEmployees } from '@redux/employee/employee.slice';
// Constants
import { Type } from '@pages/home/constants/InsidePlatform';
// Utils
import { getRoute } from '@utils/Paths';
// Components
import { Routes } from '@components/paths';
import { TypeDiligence } from '@constants/Onboarding';

export const useInitializeData = (): {
    data: IHomeInformation | null;
    information: ICompanyInformation;
    informativeDate: boolean;
    employees: IItemEmployee[];
    planEmployee: IPlanEmployer | null;
    plansEmployee: IPlanEmployer[] | [];
    handleLiquidation: () => void;
    handleShowInformativeDate: () => void;
    handleStartTour: () => void;
    handleYearPeriodText: () => string;
    showDueDiligenceBanner: boolean;
    dueDiligenceLink: string;
} => {
    const {
        plans: { activePlan, plansEmployee },
        company: { id, home_information, information },
        employee: { activeEmployees },
        payslips: { currentYearPeriod },
        session: { loginUser, activeTour },
        onboarding: { dueDiligence, urlEncrypt },
    } = useAppSelector((state: RootState) => state);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [informativeDate, setInformativeDate] = useState<boolean>(false);
    const [showDueDiligenceBanner, setShowDueDiligenceBanner] = useState<boolean>(false);
    const [dueDiligenceLink, setDueDiligenceLink] = useState<string>('');

    const initializeData = async (): Promise<void> => {
        await dispatch(getCurrentHomePeriod(Type.PAYROLL, moment().year().toString()));
        await dispatch(getActiveEmployees());
    };

    useEffect(() => {
        if (plansEmployee?.length === 1 && !plansEmployee[0].is_active) navigate(getRoute(Routes.PLANS));
    }, [plansEmployee]);

    useEffect(() => {
        if (!activeTour && !information?.pila_user && loginUser?.lastLogin) {
            navigate(getRoute(Routes.COMPANY));
        }

        loginUser?.lastLogin && initializeData();
    }, []);

    useEffect(() => {
        id && dispatch(getPlansEmployee());
    }, [id]);

    useEffect(() => {
        Object.keys(information).length && loginUser?.lastLogin && dispatch(setFunderId(information?.type_funder_id));
        handleShowInitialTour(information);
    }, [information]);

    useEffect(() => {
        setShowDueDiligenceBanner(dueDiligence === TypeDiligence.NOTIFIED);
    }, [dueDiligence]);

    useEffect(() => {
        setDueDiligenceLink(`${process.env.REACT_APP_FORM_DILIGENCE_URL}/${urlEncrypt}`);
    }, [urlEncrypt]);

    /**
     * redirect page
     */
    const handleLiquidation = (): void => {
        navigate(getRoute(Routes.PAYROLL_ADJUSTER));
    };

    /**
     * Action to show informative date component
     */

    const handleShowInformativeDate = (): void => {
        setInformativeDate(!informativeDate);
    };

    /**
     * Action to reinitialize guide tour
     */
    const handleStartTour = (): void => {
        dispatch(setGuideTour(false));
        dispatch(
            setLoginUser({
                userId: loginUser?.userId,
                lastLogin: null,
            })
        );
    };

    /**
     * Action to validate year period text
     * @returns {string} - Return if current year period is biweekly or monthly
     */
    const handleYearPeriodText = (): string => {
        const monthlyText = 'mes';
        const biweeklyText = 'quincenal';

        if (currentYearPeriod?.name) {
            const biweekly = 'Quincena';
            const regex = new RegExp(biweekly, 'gi');
            return regex.test(currentYearPeriod?.name) ? biweeklyText : monthlyText;
        }
        return monthlyText;
    };

    const handleShowInitialTour = (companyData: ICompanyInformation): void => {
        const isPilaUserNotExist = !companyData?.pila_user || !companyData?.pila_password;
        isPilaUserNotExist && handleStartTour();
    };

    return {
        data: home_information,
        information,
        informativeDate,
        employees: activeEmployees,
        planEmployee: activePlan,
        plansEmployee,
        handleLiquidation,
        handleShowInformativeDate,
        handleStartTour,
        handleYearPeriodText,
        showDueDiligenceBanner,
        dueDiligenceLink: dueDiligenceLink,
    };
};
