// Api
import AxiosClient from '@api/axiosClient';
// Urls
import { urls } from './urls';
// Models
import { ICreateLead, ICreateTransaction, IPaymentSavePlan, IRedeemCode } from '@models/Plans';

export const apiGetActivePlans = <T>(): Promise<T> => AxiosClient.get(urls.payment.plans, {});

export const apiGetPlansEmployee = <T>(): Promise<T> => AxiosClient.get(urls.payment.getPayments);

export const apiGetTransaction = <T>(): Promise<T> => AxiosClient.get(urls.payment.statusPurchase);

export const apiPostCreateTransaction = <T>(data: ICreateTransaction): Promise<T> =>
    AxiosClient.post(urls.payment.createTransaction, data);

export const apiPostSavePayment = <T>(data: IPaymentSavePlan): Promise<T> => AxiosClient.post(urls.payment.savePayment, data);

export const apiPostCreateLead = <T>(data: ICreateLead): Promise<T> => AxiosClient.post(urls.payment.createLead, data);

export const apiPostRedeemCode = <T>(data: IRedeemCode): Promise<T> => AxiosClient.post(urls.payment.redeemCode, data);

export const apiGetPromotion = <T>(periodicityId: string): Promise<T> =>
    AxiosClient.get(urls.payment.getPromotion(periodicityId));
