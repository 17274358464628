// Libraries
import React from 'react';

export const TEXT_HOME = (
    <>
        ¡Bienvenido al servicio de <strong>Nómina Electrónica</strong>! Aquí podrás visualizar el resumen de tu nómina
        seleccionando en el calendario el mes de transmisión y el botón de transmitir. Recuerda que la nómina electrónica debe ser
        enviada dentro de los 10 días hábiles del mes actual, correspondiendo la información al mes inmediatamente anterior.
    </>
);

export const TEXT_ELECTTRONIC_PAYROLL = (
    <>
        Gracias por adquirir nuestro servicio de Nómina Electrónica, a continuación conozca el resumen de su nómina para
        transmitirla.{' '}
        <strong>
            Ten presente que para ver el detalle de tu transmisión debes esperar que el proceso se complete. Ingresa nuevamente a
            este módulo para verificar el estado de tu transmisión.
        </strong>
    </>
);
