// Libraries
import { AppThunk } from '@redux/configureStore';
// Redux
import { postCreateEmployee, postCreateEmployeeInProvisions } from '@redux/employee/employee.slice';
// Models
import { IEmployeeData } from '@models/Employee';

/**
 * This interface is the format actions of the object.
 * @constructor
 * @param {[key: string]: {action: (data: IEmployeeState) => AppThunk<void>}} key - Key object.
 * @param {{action: (data: IEmployeeData) => AppThunk<void>}} main - Main actions create employee.
 * @param {{action: (data: IEmployeeData) => AppThunk<void>}} provisions - Action create employee from provisions.
 */
interface IActionCreateDispatch {
    [key: string]: { action: (data: IEmployeeData, actionModal: () => void) => AppThunk<void> };
    main: {
        action: (data: IEmployeeData, actionModal: () => void) => AppThunk<void>;
    };
    provisions: {
        action: (data: IEmployeeData, actionModal: () => void) => AppThunk<void>;
    };
}

/**
 * This enum It represents the constants for create an employee.
 */
export enum NamesDispatchCreate {
    MAIN = 'main',
    PROVISIONS = 'provisions',
}

/**
 * This object provide the all redux actions for create an employee.
 */
export const ActionCreateDispatch: IActionCreateDispatch = {
    [NamesDispatchCreate.MAIN]: {
        action: postCreateEmployee,
    },

    [NamesDispatchCreate.PROVISIONS]: {
        action: postCreateEmployeeInProvisions,
    },
};
