import React, { useCallback, useEffect, useRef, useState } from 'react';

/**
 * This hook provide the size of the an element
 */
export const useElementSize = (): { width: number | undefined; ref: React.RefObject<HTMLDivElement> } => {
    const [width, setWidth] = useState<number | undefined>(0);
    const ref = useRef<HTMLDivElement>(null);

    /**
     * Action to resize width state
     */
    const handleResize = useCallback((): void => {
        setWidth(ref.current?.offsetWidth);
    }, [ref.current?.offsetWidth]);

    useEffect(() => {
        setTimeout(() => {
            handleResize();
        }, 10);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [ref.current?.offsetWidth]);

    return { width, ref };
};
