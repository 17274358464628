// Libraries
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

// Internal imports
import localStorage from './LocalStorage';
import { HeaderConstants, LoginConstants } from '@constants/Session';
import { store } from '@redux/configureStore';
import { clearSession, loginSession } from '@redux/session/session.slice';
import { setLoaderRegisterCompany, showLoader } from '@redux/utils/utils.slice';
import { urls } from '@api/urls';

const currentRequests: AxiosRequestConfig[] = [];
const baseUrl = `${process.env.REACT_APP_BASE_URL}`;
const noShowMainLoaderUrls = [`${baseUrl}/${urls.session.employer}`, `${baseUrl}/${urls.payment.statusPurchase}`];

const createInstance = (): AxiosInstance => {
    const instance = axios.create({});
    instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    instance.interceptors.request.use((defaultConfig): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
        const {
            utils: { loader },
            session: { accessToken },
            company: { id },
        } = store.getState();

        currentRequests.push(defaultConfig);

        const config = defaultConfig;
        const userToken = localStorage.get(LoginConstants.USER_TOKEN) || accessToken;
        const employerId = localStorage.get(LoginConstants.EMPLOYER_ID) || id;
        const userId = '';
        const checkNonShowMainLoader = noShowMainLoaderUrls.some(url => config.url === url);

        if (!loader && !checkNonShowMainLoader) {
            store.dispatch(showLoader(true));
        }

        if (userToken) {
            config.headers = Object.assign(config.headers || {}, {
                [HeaderConstants.AUTHORIZATION]: `Bearer ${userToken}`,
                [HeaderConstants.CONTENT_TYPE]: 'application/json',
                [HeaderConstants.EMPLOYER_ID]: employerId,
                [HeaderConstants.USER_ID]: userId,
            });
        }
        return config;
    }, Promise.reject);

    instance.interceptors.response.use(
        response => {
            const loaderState = store.getState().utils.loader;
            const loaderRegisterCompanyState = store.getState().utils.loaderRegisterCompany;
            currentRequests.pop();

            if (loaderState && currentRequests.length === 0) {
                store.dispatch(showLoader(false));
            }
            if (loaderRegisterCompanyState && currentRequests.length === 0) {
                store.dispatch(setLoaderRegisterCompany(false));
            }
            return response;
        },
        error => {
            const loaderState = store.getState().utils.loader;
            const loaderRegisterCompanyState = store.getState().utils.loaderRegisterCompany;
            currentRequests.pop();
            if (loaderState) {
                store.dispatch(showLoader(false));
            }
            if (loaderRegisterCompanyState) {
                store.dispatch(setLoaderRegisterCompany(false));
            }
            if (error.response && error.response.status === 403) {
                localStorage.clearKey(LoginConstants.USER_TOKEN);
                localStorage.clearKey(LoginConstants.USER_DATA);
                localStorage.clearKey(LoginConstants.EMPLOYER_ID);
                localStorage.clearKey(LoginConstants.USER_ID);
            }
            if (
                error.response &&
                error.response.status === 401 &&
                (error.response.data.message === LoginConstants.TOKEN_TIMEOUT_MESSAGE ||
                    error.response.data.message === LoginConstants.REQUEST_WITHOUT_TOKEN)
            ) {
                store.dispatch(clearSession());
                store.dispatch(loginSession(true));
            }
            return Promise.reject(error);
        }
    );
    return instance;
};

const instance = createInstance();

export default instance;
