export const STEP_ONE = 1;
export const TWO_SMMLV = 2000000;
export const STEP_TWO = 2;
export const STEP_THREE = 3;
export const FIRST_CONTRACT = 0;
export const FIRST_WORK_CENTER = 0;
export const INTEGRAL_SALARY = 'Salario Integral';
export const YES = '1';
export const TERM = 'Término fijo';
export const YES_SPANISH = 'SI';
export const NO = 'NO';
export const STRING = 'string';
export const CONTRACT_TERM_ID = 'contract_term_id';
export const DEPARTMENT_ID = 'department_id';
export const POSITION_ID = 'position_id';
export const COST_CENTER_ID = 'cost_center_id';
export const FINISH_DATE = 'finish_date';
export const ENDOWMENT_VALUE = 'endowment_value';
export const SALARY_TYPE_ID = 'salary_type_id';
export const TRANSPORTATION_ASSISTANCE = 'transportation_assistance';
export const NEW_SALARY_DATE = 'start_date_new_salary';
export const PA = 'PA';
export const CD = 'CD';
export const CONTRACT_TYPE_ID_INDEPENDENT = 'BA3771F3-7847-442A-891D-3E72151496CE';
export const CONTRACT_TERM_ID_UNDEFINED = '0FB938C1-43F8-466B-A0E1-4695F85D7AFF';
export const RISK_CLASS_ID_CLASS_FIVE = '428398DD-31A4-426B-84D9-553B7A31BEA2';
export const RISK_CLASS_ID_CLASS_FOUR = '7201F62A-5BE3-4697-9191-155F7EB91D6B';
export const APPRENTICE_PRODUCTIVE_STAGE = 'D612CA66-2012-444B-9EA2-47D13D0E3A37';
export const APPRENTICE_LECTIVE_STAGE = 'C1983903-268F-486D-8576-0DC2A6392E59';
export const NAME = 'names';
export const SECOND_NAME = 'second_name';
export const LAST_NAME = 'last_names';
export const SECOND_LAST_NAME = 'second_last_name';
export const contractForMoreThanOneMonth = '56D2E75C-FFB1-4BE7-AE46-611DA00D9548';
export const APPRENTICES_IN_TRAINING_STAGE = 'C1983903-268F-486D-8576-0DC2A6392E59';
export const APPRENTICES_IN_PRODUCTIVE_STAGE = 'D612CA66-2012-444B-9EA2-47D13D0E3A37';
export const DEPENDENT_LINKED_TO_THE_SOCIAL_PROTECTION_FLOOR = '7CE5E2C1-2823-4080-9213-5E833F382BA8';
export const INDEPENDENT_LINKED_TO_THE_SOCIAL_PROTECTION_FLOOR = '3EF867A5-90A0-4CED-AECF-A5FFD13730A4';
export const PART_TIME_WORKER = '4950A347-AB27-46DC-8367-0D5D7DDFD9D3';
export const PRISON_WORKER = 'F2D6B314-88D2-4B1B-BC2A-51AF03C1CF9F';
export const TAXPAYER_DEPENDENT = 'B7824050-5966-47E8-9E2E-57952C3A6ED3';
export const PRIVATE_ESTABLISHMENT_TEACHER = '52169FCD-5D85-45F3-A2D4-CD641E5C7B30';
export const contractSENA = '4D4D8209-22E4-4614-A563-BB110C42A5E6';
export const contractUniversityPractice = '807223C6-DCFC-4833-906F-823EF133262B';
export const countryColombiaId = '4A0B28A8-4EC7-425B-8E5B-84AF3786EE83';
export const VETERAN_DEPENDENT_OF_THE_PUBLIC_FORCE = '8FAFAF2D-9821-49DA-9520-D18A7DADC2F5';
export const INDEPENDENT = '10116A8C-097D-4F6E-8FB0-89F5329A7656';
export const supportForSustaining = 'Apoyo de sostenimiento';
export const salary = 'Salario';
export const SUCCESSFULLY_COMPLETED = 'FINALIZADO CON EXITO';
export const EMPLOYMENT_CONTRACT = 'BC76919A-D021-4059-902B-998D351366CF';
export const GRADUATE_HEALTH_STUDENT_AND_RESIDENT = '6F5E22A5-65B3-4972-87F3-469B64CEE17C';
export const STUDENTS_SPECIAL_REGIME_LAW_789_OF_2002 = 'C36A9CAC-ABFA-43F7-8604-8AA2A3EEBB1C';
export const STUDENTS_CONTRIBUTE_ONLY_OCCUPATIONAL_RISK_SYSTEM = 'A5194FCE-E76C-4F57-88E8-38D5C880D295';
export const dynamicData = [
    'document_type',
    'administrator',
    'country',
    'country_state',
    'county_state_city',
    'compensation_box',
    'type_contributor',
    'type_funder',
    'ciius',
    'risk_class',
    'subtype_contributor',
];
export const contractTerm = [
    'Obra o labor',
    'Término fijo',
    'Contratista',
    'Aprendices de SENA en etapa lectiva',
    'Aprendices de SENA en etapa productiva',
    'Estudiantes (Régimen especial Ley 789 de 2002)',
    'Estudiantes aporte solo riesgos laborales',
];
export const contractType = [
    'Contratista',
    'Aprendices de SENA en etapa lectiva',
    'Aprendices de SENA en etapa productiva',
    'Estudiantes (Régimen especial Ley 789 de 2002)',
    'Estudiantes aporte solo riesgos laborales',
];
export const dynamicEmployee = ['contract_term', 'contract_type', 'salary_type', 'cost_center'];
export const daysOfWeek = [
    {
        value: '1',
        label: 'Lunes',
    },
    {
        value: '2',
        label: 'Martes',
    },
    {
        value: '3',
        label: 'Miércoles',
    },
    {
        value: '4',
        label: 'Jueves',
    },
    {
        value: '5',
        label: 'Viernes',
    },
    {
        value: '6',
        label: 'Sábado',
    },
    {
        value: '7',
        label: 'Domingo',
    },
];
export const dotationOptions = [
    {
        value: 'SI',
        label: 'SI',
    },
    {
        value: 'NO',
        label: 'NO',
    },
];
export const optionsPermanent = [
    {
        value: '1',
        label: 'SI',
    },
    {
        value: '2',
        label: 'NO',
    },
];
export enum TypeData {
    POSITION = 'POSITION',
    DEPARTMENT = 'DEPARTMENT',
    COST_CENTER = 'COST_CENTER',
}
export enum TypeAdministrator {
    EPS = 'EPS',
    ARL = 'ARL',
    PENSIONS = 'AFP',
    LAYOFFS = 'CESANTIA',
}
export enum DayWeek {
    MONDAY = '1',
    TUESDAY = '2',
    WEDNESDAY = '3',
    THURSDAY = '4',
    FRIDAY = '5',
    SATURDAY = '6',
    SUNDAY = '7',
}
export enum CodesDocumentTypes {
    CD = 'CD',
    CE = 'CE',
    PT = 'PT',
    TI = 'TI',
    PA = 'PA',
    SC = 'SC',
    PE = 'PE',
    CC = 'CC',
    RC = 'RC',
    NIT = 'NIT',
}
export enum SalaryTypesId {
    FIXED_SALARY = '6A8E0555-6861-4C5F-9FD0-663887768A58',
    VARIABLE_SALARY = '5D1AAE26-89CE-4952-97A5-CC7A5F7412B9',
    FULL_SALARY = '5D1AAE26-89CE-4952-97A5-CC7A5F7412B1',
}
export enum SelectTypeSalaryTooltipText {
    FULL_SALARY_TEXT = '',
    VARIABLE_SALARY_TEXT = 'Recuerda que, aunque el valor para el tipo de salario variable inicialmente puede ser cero, debes diligenciar el campo de valor antes de liquidar la nómina de tus empleados en la sección de Liquidación de nómina, en el campo salario básico donde haga falta el valor y solicite Registrar salario, lo que te retornara a este formulario para el respectivo registro',
    FIXED_SALARY_TEXT = 'Recuerda que el salario para los empleados debe tener una asignación como mínimo del  SMMLV del periodo en curso y para Aprendices SENA etapa lectiva debe ser del 50% y en etapa práctica el 100% del SMMLV',
}
export enum StepTwoValues {
    EPS_ID = 'eps_id',
    PENSION_FUND_ID = 'pension_fund_id',
    CESANTIAS_FUND_ID = 'cesantias_fund_id',
    COMPENSATION_BOX_ID = 'compensation_box_id',
    ARL_ID = 'arl_id',
}
export enum TypesDocumentIds {
    CC = '53BEDB25-850B-48E7-A7DC-0B0BC5627201',
    CE = '87C68557-FB44-4A6B-965D-7569AC1030E2',
    NIT = '50E64170-7F78-4A9C-80D6-10B0DB284228',
    TI = 'E5C162FC-A5B5-4834-9D68-B3F34D79F1F7',
    RC = 'B2AF83F0-1258-442F-BBAA-5133F882F1B2',
    PA = '19A9CAF2-3EC1-465C-B8F3-90F1A920544B',
    CD = '2DCA6DC8-1070-48DB-BC3F-AB3846E00FEB',
    SC = '539D1BF0-8FD4-49D1-8AA5-7DBAABF2BA99',
    PE = 'DB81BE81-16CC-4FD9-8DA9-E84720AF2BA5',
    PT = '4A742CCB-9F64-4F1E-BF9C-EEBCAC5BAFEB',
}
export enum TypesPayMethod {
    TRANSFERABLE_BANK_NOTE = 'F74B5EAC-D1E0-479E-85DB-41DE00CF50B7',
    BANK_CONSIGNMENT = '8E5EF89E-B227-4957-BDDC-18894ADE9909',
    DEBIT_BANK_TRANSFER = '3F104AAA-5377-46DE-8FEB-E015A20F0447',
}
export enum TypesPayMethodWithoutData {
    CASH = 'F885A1BB-B026-441B-B41D-FF5BB7B09C74',
    INSTRUMENT_NO_DEFINED = '50CF4F96-DC6D-4468-98D0-1942B3D4CD56',
}
export const localTypesDocument = ['CC', 'NIT', 'TI'];
export const internationalTypeOFDocuments = ['PA', 'CE', 'CD', 'SC', 'PE', 'PT'];
export const foreignerNotRequiredToPayPensionContributions = 'foreigner_not_required_to_pay_pension_contributions';
export const colombianResidentAbroad = 'colombian_resident_abroad';
export const filingDateInExt = 'filing_date_in_ext';
export const stateId = 'state_id';
export const countryId = 'country_id';
export const cityId = 'city_id';
export const address = 'address';
export const messageTooltipButtonValidate =
    'El botón validar te permite consultar y precargar datos previamente registrados en el portal de Seguridad Social PILA o en Bases de referencia, por ello, debes asegurarte en registrar correctamente el tipo y número de documento, si no carga ninguna información deberás diligenciar todos los campos';
export const caseOne = [0, 1, 3, 4, 5, 6, 9, 11, 12];
export const caseTwo = [0, 1, 3, 4, 5];
export const caseThree = [0, 2, 3, 4, 5, 6];
export const caseZero = [0];
export const caseSixteen = [0, 2, 3, 4, 5, 9];
export const caseEighteen = [0, 1, 3, 4, 5, 9];
export const caseTwentyTwo = [0, 1, 3, 4, 5, 6, 9];
export const caseThirty = [0, 1, 3, 4, 5, 6, 9];
export const caseThirtyOne = [0, 1, 3, 4, 5, 9];
export const caseThirtyTwo = [0, 1, 3, 4, 5, 9];
export const caseThirtyFour = [0, 2, 3, 4, 5, 6];
export const caseThirtyFive = [0, 2, 3, 4, 5, 6];
export const caseFortyFour = [0, 1, 3, 4, 5];
export const caseFortySeven = [0, 1, 3, 4, 5, 9];
export const caseFiftyOne = [0, 1, 3, 4, 5];
export const caseFiftyTwo = [0, 3, 4, 5];
export const caseFiftyThree = [0, 2, 3, 4, 5, 6, 9];
export const caseFiftyFive = [0, 1, 3, 4, 5, 9];
export const caseFiftySeven = [0, 2, 3, 4, 5, 6, 11, 12];
export const caseFiftyNine = [0, 2, 3, 4, 5, 6, 9];
export const caseSixtyEight = [0, 1, 3, 4, 5, 6, 9, 11, 12];
export const SEVERANCE_EXCEPTION = [
    '673CE116-B9D0-44B6-8225-110CF47EE52E',
    'C27BB92D-83A6-4E37-A993-1B8355B3FA9F',
    '8E968957-F435-4FBC-881D-3055DD4985CF',
    '59161274-A6AD-4F53-8730-A8F7C57A8796',
];

export const PENSIONS_EXCEPTION = ['CFB8EDBA-FFC8-4979-84FC-BEEF7D635D91'];
export const PERMANENT_SALARY_CHANGE = 'permanent_salary_change_id';
export const EMPLOYEE_EXIST_DOCUMENT_NUMBER =
    'El número de documento corresponde a un empleado previamente registrado en esta plataforma, verifique la información';

export const EMPLOYEE_EXIST_EMAIL =
    ' El correo electrónico corresponde a un empleado previamente registrado en esta plataforma y es un empleado activo, verifique la información';

export enum EMPLOYEE_PAYMENT_DATA_MESSAGES {
    ACCOUNT_NUMBER_ERROR = '* Número de cuenta inválido. Debe tener entre 7 y 20 caracteres numéricos',
    REQUIRED = '* Campo requerido',
}

export const CONTRACTS_CONTRIBUTOR = [
    {
        contributorIds: [APPRENTICE_LECTIVE_STAGE, APPRENTICE_PRODUCTIVE_STAGE],
        contractIds: [contractSENA],
    },
    {
        contributorIds: [GRADUATE_HEALTH_STUDENT_AND_RESIDENT, STUDENTS_CONTRIBUTE_ONLY_OCCUPATIONAL_RISK_SYSTEM],
        contractIds: [contractUniversityPractice],
    },
    {
        contributorIds: [contractForMoreThanOneMonth],
        contractIds: [CONTRACT_TYPE_ID_INDEPENDENT],
    },
    {
        contributorIds: [
            TAXPAYER_DEPENDENT,
            STUDENTS_SPECIAL_REGIME_LAW_789_OF_2002,
            PART_TIME_WORKER,
            VETERAN_DEPENDENT_OF_THE_PUBLIC_FORCE,
            PRIVATE_ESTABLISHMENT_TEACHER,
            PRISON_WORKER,
        ],
        contractIds: [EMPLOYMENT_CONTRACT],
    },
];
