import React from 'react';

export enum CurrentInformation {
    ENABLE_SERVICE = 'ENABLE_SERVICE',
    TRANSMISSION_SERVICE = 'TRANSMISSION_SERVICE',
    GENERATE_PRIMA = 'GENERATE_PAYMENT_DOCUMENT',
    GENERATE_CESANTIAS = 'GENERATE_CESANTIAS',
    CHECK_DETAIL = 'CHECK_DETAIL',
    CHECK_HISTORIC = 'CHECK_HISTORIC',
    CONFIRM_TRANSMISSION = 'CONFIRM_TRANSMISSION',
    ERROR_TRANSMISSION = 'ERROR_TRANSMISSION',
}

export const getModalInformation = (date?: string): { [key: string]: React.ReactElement } => {
    return {
        [CurrentInformation.ENABLE_SERVICE]: (
            <p>
                En este momento serás redirigido a nuestro portal de Nómina Electrónica para que realices la habilitación de este
                servicio. Una vez termines el proceso deberás regresar a este portal para iniciar tu proceso de transmisión. En
                caso de que tengas dudas acerca de este proceso te recomendamos ver nuevamente el video completo.
            </p>
        ),
        [CurrentInformation.TRANSMISSION_SERVICE]: (
            <p>
                En este momento serás redirigido a la sección de <strong>Transmitir nómina electrónica</strong> para realizar el
                proceso de Transmisión. En caso de que tengas dudas acerca de este proceso te recomendamos ver nuevamente el video
                completo.
            </p>
        ),
        [CurrentInformation.GENERATE_PRIMA]: (
            <p>
                <strong>
                    ¿Deseas que el documento soporte del pago de la nómina por concepto de prima de servicios, se genere
                    mensualmente de acuerdo con la provisión realizada o en las fechas de pago efectivo?
                </strong>
                &nbsp;Debes tener en cuenta las políticas contables al interior de la empresa.
            </p>
        ),
        [CurrentInformation.GENERATE_CESANTIAS]: (
            <p>
                <strong>
                    ¿Deseas que el documento soporte del pago de la nómina por concepto de cesantías, se genere mensualmente de
                    acuerdo con la provisión realizada o en las fechas de pago efectivo?
                </strong>
                &nbsp;Debes tener en cuenta las políticas contables al interior de la empresa.
            </p>
        ),
        [CurrentInformation.CHECK_DETAIL]: (
            <p>
                En este momento serás redirigido a nuestro portal de Nómina Electrónica para que verifiques el detalle de tu
                transmisión.
            </p>
        ),
        [CurrentInformation.CHECK_HISTORIC]: (
            <p>
                En este momento te vamos a redirigir al portal de Nómina Electrónica donde podrás visualizar el histórico de tus
                transmisiones.
            </p>
        ),
        [CurrentInformation.ERROR_TRANSMISSION]: (
            <p>
                En este momento no es posible realizar la transmisión de la nómina electrónica por favor inténtalo nuevamente más
                tarde.
            </p>
        ),
        [CurrentInformation.CONFIRM_TRANSMISSION]: (
            <p>
                Estás a punto de realizar la transmisión del periodo <span className="date">{date}</span>. Recuerda haber validado
                la información del resumen de la transmisión. Si estás seguro que la información está completa y actualizada haz
                clic en&nbsp;
                <strong>Confirmar.</strong>
            </p>
        ),
    };
};
