// Libraries
import React, { useEffect, useState } from 'react';
// Utils
import { getIconName } from '@utils/Text';
// Models
import { IIconProps } from './models/Icon';
// Styles
import './Icon.scss';

export const Icon: React.FC<IIconProps> = ({ onClick, className = '', name, alt = '', hoverIcon = name, type = 'png' }) => {
    const [iconName, setIconName] = useState<string>(getIconName(name));

    useEffect(() => {
        setIconName(getIconName(name));
    }, [name]);

    const image = require(`../../assets/icons/${iconName}.${type}`);

    return (
        <img
            role="img"
            src={image}
            alt={alt ? alt : name}
            onClick={onClick}
            className={`icon ${className} ${hoverIcon !== name ? 'icon--hover' : ''}`}
            onMouseOver={(): void => setIconName(getIconName(hoverIcon))}
            onMouseLeave={(): void => setIconName(getIconName(name))}
            data-testid="icon"
        />
    );
};
