import AxiosClient from '@api/axiosClient';
import { urls } from '@api/urls';
import {
    IActiveEmployeeApi,
    ICreateDetail,
    IEmployeeData,
    IEmployeesDelete,
    IPayloadContributor,
    IPayloadContributorBase,
    TExistEmployee,
} from '@models/Employee';

// Employee
export const apiGetEmployees = (employerId: string): Promise<unknown> => AxiosClient.get(urls.employees.get(employerId));
export const apiGetActiveEmployees = (data: IActiveEmployeeApi): Promise<unknown> =>
    AxiosClient.post(urls.employees.activeEmployees, data);
export const apiGetDetailEmployee = (employeeId: string): Promise<unknown> => AxiosClient.get(urls.employees.detail(employeeId));
export const apiPostEmployee = (employee: IEmployeeData): Promise<unknown> => AxiosClient.post(urls.employees.post, employee);
export const apiPutEmployee = <T>(employeeId: string, employee: IEmployeeData | T): Promise<unknown> =>
    AxiosClient.put(urls.employees.put(employeeId), employee);
export const apiDeleteEmployees = (employees: IEmployeesDelete): Promise<unknown> =>
    AxiosClient.post(urls.employees.delete, employees);
// Department
export const apiGetDepartments = (employerId: string): Promise<unknown> =>
    AxiosClient.get(urls.employees.departments(employerId));
export const apiPostDepartment = (data: ICreateDetail): Promise<unknown> => AxiosClient.post(urls.employees.department, data);
export const apiPutDepartment = (departmentId: string, data: ICreateDetail): Promise<unknown> =>
    AxiosClient.put(urls.employees.specificDepartment(departmentId), data);
export const apiDeleteDepartment = (departmentId: string): Promise<unknown> =>
    AxiosClient.delete(urls.employees.specificDepartment(departmentId));
// Position
export const apiGetPositions = (employerId: string): Promise<unknown> => AxiosClient.get(urls.employees.positions(employerId));
export const apiPostPosition = (data: ICreateDetail): Promise<unknown> => AxiosClient.post(urls.employees.position, data);
export const apiPutPosition = (positionId: string, data: ICreateDetail): Promise<unknown> =>
    AxiosClient.put(urls.employees.specificPosition(positionId), data);
export const apiDeletePosition = (positionId: string): Promise<unknown> =>
    AxiosClient.delete(urls.employees.specificPosition(positionId));
// Cost center
export const apiGetCostCenters = (employerId: string): Promise<unknown> =>
    AxiosClient.get(urls.employees.costCenters(employerId));
export const apiPostCostCenter = (data: ICreateDetail): Promise<unknown> => AxiosClient.post(urls.employees.costCenter, data);
export const apiPutCostCenter = (costId: string, data: ICreateDetail): Promise<unknown> =>
    AxiosClient.put(urls.employees.specificCost(costId), data);
export const apiDeleteCostCenter = (costId: string): Promise<unknown> => AxiosClient.delete(urls.employees.specificCost(costId));
// Contributor
export const apiGetContributor = (payload: IPayloadContributor): Promise<unknown> =>
    AxiosClient.post(urls.employees.contributor.getContributor, payload);

export const apiGetContributorBase = (payload: IPayloadContributorBase): Promise<unknown> =>
    AxiosClient.post(urls.employees.contributor.getContributorBase, payload);

export const apiPostExistByEmail = (employee: TExistEmployee): Promise<unknown> =>
    AxiosClient.post(urls.employees.existByEmail, employee);

export const apiPostExistByDocumentNumber = (employee: TExistEmployee): Promise<boolean> =>
    AxiosClient.post(urls.employees.existByDocumentNumber, employee);
