import React from 'react';
import './dueDiligenceNotificationBanner.scss';

interface IDueDiligenceNotificationProps {
    link: string;
}

export const DueDiligenceNotificationBanner: React.FC<IDueDiligenceNotificationProps> = ({ link }) => {
    return (
        <div className="banner-dueDiligence">
            <div className="banner">
                <p className="banner--text">
                    <span className="banner--bold">¡Es momento de actualizar nuevamente tus datos! </span> Para poder seguir
                    haciendo uso de nuestros servicios debes actualizar tus datos en nuestro formulario de Proceso de conocimiento
                    de cliente. Este proceso lo realizamos en cumplimiento de lo establecido por la Superintendencia de Sociedades
                    de Colombia y nos permite conocer con mayor certeza a nuestros clientes y así poder dar una mejor experiencia
                    en la cual velemos por la disminución de los riesgos de uso de nuestro servicio. Ten presente que de no
                    actualizarse esta información,{' '}
                    <span className="banner--bold">no podrás seguir haciendo uso de las funcionalidades de Nómina en Línea.</span>
                </p>

                <p className="banner--text" id="banner--tex_second">
                    Diligencia el formulario haciendo{' '}
                    <a href={link} target="_blank" rel="noopener noreferrer" className="banner--link">
                        clic aquí
                    </a>
                    . Una vez hayas actualizado la información inicia nuevamente sesión con tu usuario y contraseña en la <br />
                    pestaña actual de Nómina en Línea.
                </p>
            </div>
        </div>
    );
};
