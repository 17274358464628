// Libraries
import { useEffect, useState } from 'react';
// Hooks
import { useAppDispatch, useAppSelector } from '@hooks/redux';
// Redux
import { RootState } from '@redux/rootReducer';
import {
    deleteCostCenter,
    deleteDepartment,
    deletePosition,
    postCostCenter,
    postDepartment,
    postPosition,
    putCostCenter,
    putDepartment,
    putPosition,
} from '@redux/employee/employee.slice';
// Components
import { IOptionSelect } from '@components/input';
// Models
import { ICreateDetail } from '@models/Employee';
// Utils
import { formatOptionsCustom } from '@utils/Options';
// Constants
import { TypeData } from '@pages/employees/constants/Employees';

export const useCreateDetails = (): {
    createDepartment: boolean;
    createPosition: boolean;
    createCostCenter: boolean;
    listDepartments: IOptionSelect[];
    listPositions: IOptionSelect[];
    costCenter: IOptionSelect[];
    typeFunders: IOptionSelect[];
    departmentId: string | null;
    positionId: string | null;
    costCenterId: string | null;
    compensationId: string | null;
    modalDelete: boolean;
    editElementPosition: boolean;
    editElementCostCenter: boolean;
    editElementDepartment: boolean;
    handleActionElement: (type: TypeData, edit: boolean) => void;
    handleActionDepartment: (value: ICreateDetail) => Promise<void>;
    handleActionPosition: (value: ICreateDetail) => Promise<void>;
    handleActionCostCenter: (value: ICreateDetail) => Promise<void>;
    handleModalDelete: () => void;
    setSelectedElement: React.Dispatch<React.SetStateAction<{ id: string; type: TypeData } | null>>;
    handleDelete: () => void;
    arlIdEmployer: string | null;
    riskClassIdEmployer: string | null;
    ciiuEmployerId: string | null;
    municipalityEmployer: string | null;
    departmentEmployer: string | null;
    modalRepeatDetail: boolean;
    addressEmployer: string | null;
    setModalRepeatDetail: React.Dispatch<React.SetStateAction<boolean>>;
    companyExemptedFromParafiscalPaymentsEmployer: boolean;
} => {
    const dispatch = useAppDispatch();
    const {
        company: {
            information: {
                risk_class_id,
                arl_id,
                ciiu_code,
                state_id,
                city_id,
                address,
                compensation_box_id,
                company_exempted_from_parafiscal_payments,
            },
        },
        employee: { departments, positions, cost_center, department, position, cost_center_selected },
        utils: { types_funder },
    } = useAppSelector((state: RootState) => state);

    const [listDepartments, setListDepartments] = useState<IOptionSelect[]>([]);
    const [listPositions, setListPositions] = useState<IOptionSelect[]>([]);
    const [costCenter, setCostCenter] = useState<IOptionSelect[]>([]);
    const [typeFunders, setTypeFunders] = useState<IOptionSelect[]>([]);
    const [createDepartment, setCreateDepartment] = useState<boolean>(false);
    const [createPosition, setCreatePosition] = useState<boolean>(false);
    const [createCostCenter, setCreateCostCenter] = useState<boolean>(false);
    const [editElementPosition, setEditElementPosition] = useState<boolean>(false);
    const [editElementDepartment, setEditElementDepartment] = useState<boolean>(false);
    const [editElementCostCenter, setEditElementCostCenter] = useState<boolean>(false);
    const [modalDelete, setModalDelete] = useState<boolean>(false);
    const [selectedElement, setSelectedElement] = useState<{ id: string; type: TypeData } | null>(null);
    const [modalRepeatDetail, setModalRepeatDetail] = useState(false);

    /**
     * Format departments list
     */
    useEffect(() => {
        const newData: IOptionSelect[] = formatOptionsCustom(departments || [], 'name');
        setListDepartments(newData);
    }, [departments]);

    /**
     * Format types funder list
     */
    useEffect(() => {
        const newData: IOptionSelect[] = formatOptionsCustom(types_funder || [], 'name');
        setTypeFunders(newData);
    }, [types_funder]);

    /**
     * Format positions list
     */
    useEffect(() => {
        const newData: IOptionSelect[] = formatOptionsCustom(positions || [], 'name');
        setListPositions(newData);
    }, [positions]);

    /**
     * Format cost center list
     */
    useEffect(() => {
        if (cost_center?.length > 0) {
            const newData: IOptionSelect[] = formatOptionsCustom(cost_center, 'name');
            setCostCenter(newData);
        }
    }, [cost_center]);

    /**
     * Action to show input create element
     */
    const handleActionElement = (type: TypeData, edit: boolean): void => {
        switch (type) {
            case TypeData.DEPARTMENT:
                setCreateDepartment(!createDepartment);
                setEditElementDepartment(edit);
                break;
            case TypeData.POSITION:
                setCreatePosition(!createPosition);
                setEditElementPosition(edit);
                break;
            default:
                setCreateCostCenter(!createCostCenter);
                setEditElementCostCenter(edit);
                break;
        }
    };

    /**
     * Action to create or update a department
     * @param {ICreateDetail} data - Data from input
     */
    const handleActionDepartment = async (data: ICreateDetail): Promise<void> => {
        if (data?.name) {
            if (editElementDepartment && selectedElement) {
                await dispatch(putDepartment(selectedElement?.id, data));
                setEditElementDepartment(false);
            } else {
                if (departments.some(departament => departament.name.toLowerCase().includes(data.name.toLowerCase()))) {
                    setModalRepeatDetail(true);
                    return;
                }
                await dispatch(postDepartment(data));
            }
        }
        handleActionElement(TypeData.DEPARTMENT, false);
    };

    /**
     * Action to create or update a position
     * @param {ICreateDetail} data - Data from input
     */
    const handleActionPosition = async (data: ICreateDetail): Promise<void> => {
        if (data?.name) {
            if (editElementPosition && selectedElement) {
                await dispatch(putPosition(selectedElement?.id, data));
                setEditElementPosition(false);
            } else {
                if (positions.some(position => position.name.toLowerCase().includes(data.name.toLowerCase()))) {
                    setModalRepeatDetail(true);
                    return;
                }
                await dispatch(postPosition(data));
            }
        }
        handleActionElement(TypeData.POSITION, false);
    };

    /**
     * Action to create or update a cost center
     * @param {ICreateDetail} data - Data from input
     */
    const handleActionCostCenter = async (data: ICreateDetail): Promise<void> => {
        if (data?.name) {
            if (editElementCostCenter && selectedElement) {
                await dispatch(putCostCenter(selectedElement?.id, data));
                setEditElementCostCenter(false);
            } else {
                if (cost_center.some(costCenter => costCenter.name.toLowerCase().includes(data.name.toLowerCase()))) {
                    setModalRepeatDetail(true);
                    return;
                }
                await dispatch(postCostCenter(data));
            }
        }
        handleActionElement(TypeData.COST_CENTER, false);
    };

    /**
     * Action to open delete modal
     */
    const handleModalDelete = (): void => {
        setModalDelete(!modalDelete);
    };

    /**
     * Action main to delete data
     */
    const handleDelete = (): void => {
        switch (selectedElement?.type) {
            case TypeData.DEPARTMENT:
                selectedElement && dispatch(deleteDepartment(selectedElement.id));
                handleModalDelete();
                break;
            case TypeData.POSITION:
                selectedElement && dispatch(deletePosition(selectedElement.id));
                handleModalDelete();
                break;
            default:
                selectedElement && dispatch(deleteCostCenter(selectedElement.id));
                handleModalDelete();
                break;
        }
    };

    return {
        createDepartment,
        createPosition,
        createCostCenter,
        listDepartments,
        listPositions,
        costCenter,
        typeFunders,
        departmentId: department?.id || null,
        compensationId: compensation_box_id || null,
        positionId: position?.id || null,
        costCenterId: cost_center_selected?.id || null,
        modalDelete,
        editElementPosition,
        editElementCostCenter,
        editElementDepartment,
        handleActionElement,
        handleActionDepartment,
        handleActionPosition,
        handleActionCostCenter,
        handleModalDelete,
        setSelectedElement,
        handleDelete,
        arlIdEmployer: arl_id || null,
        riskClassIdEmployer: risk_class_id || null,
        ciiuEmployerId: ciiu_code || '',
        modalRepeatDetail,
        setModalRepeatDetail,
        municipalityEmployer: city_id || null,
        departmentEmployer: state_id || null,
        addressEmployer: address || null,
        companyExemptedFromParafiscalPaymentsEmployer: company_exempted_from_parafiscal_payments || false,
    };
};
