// Libraries
import React, { useState } from 'react';
import moment from 'moment';
// Models
import { IStepOneProps } from '../../../models/EmployeeComponents';
// Hooks
import { useEmployeeStack } from '@pages/employees/hooks/useEmployeeStack';
import { useDisabledFields } from '@pages/employees/hooks/useDisabledFields';
import { useChangeDetail, useChangeNameEmployee } from '@pages/employees/hooks/useChangeValues';
import { useValidate } from '@pages/employees/hooks/useValidate';
import { useStepOne } from '@pages/employees/hooks/useStepOne';
// Components
import { ButtonSectionIcon } from '@components/button';
import { CheckboxGreen, DatePickerBase, Input, SelectInput } from '@components/input';
import { Icon } from '@components/icon';
import { AddDetail } from '../add-detail/AddDetail';
// Constants
import {
    internationalTypeOFDocuments,
    messageTooltipButtonValidate,
    TypeData,
    TypesDocumentIds,
} from '@pages/employees/constants/Employees';
import { ToolTipTexts } from '@pages/employees/constants/Tooltips';
// Utils
import { existOption } from '@utils/Options';

export const StepOne: React.FC<IStepOneProps> = ({
    disabled,
    departments,
    positions,
    documentTypes,
    costCenter,
    createDepartment,
    createPosition,
    createCostCenter,
    editElementCostCenter,
    editElementDepartment,
    editElementPosition,
    values,
    handleActionElement,
    handleActionDepartment,
    handleActionPosition,
    handleActionCostCenter,
    setSelectedElement,
    handleModalDelete,
    validateTypeAndNumberDocument,
    edit,
    employee,
}) => {
    const { disabledFields: disabled_fields, validateContributor } = useEmployeeStack();
    useChangeDetail();
    useChangeNameEmployee(values);
    const { validateTypeDocumentNumber } = useValidate();
    const [isValidating, setIsValidating] = useState<boolean>(false);
    const { document_number, department_id, position_id, cost_center_id, document_type_id, colombian_resident_abroad, state_id } =
        values;
    const { cities, departmentsOptions, countryOptions } = useStepOne();
    const { disabledFieldsStepOne } = useDisabledFields(disabled, disabled_fields, validateContributor, edit, isValidating);

    return (
        <div className="form__container scroll--page">
            <div className="container_mb">
                <SelectInput
                    name="document_type_id"
                    label="Tipo de documento"
                    options={documentTypes?.filter(type => type.value !== TypesDocumentIds.NIT)}
                    disabled={disabled || edit}
                />
            </div>
            <div className="container_mb">
                <Input
                    name="document_number"
                    type={document_type_id && validateTypeDocumentNumber(document_type_id) ? 'alphanumeric' : 'number'}
                    label="N° de documento"
                    disabled={disabled || edit}
                    maxLength={16}
                    onKeyDown={(): void => {
                        setIsValidating(false);
                    }}
                />
            </div>
            {!edit && !employee && (
                <>
                    <div className="container_mb"></div>
                    <div className="container_mb">
                        <ButtonSectionIcon
                            iconName="searchGreen"
                            disabled={!document_number || !document_type_id}
                            onClick={(): void => {
                                validateTypeAndNumberDocument(values);
                                setIsValidating(!isValidating);
                            }}
                            type="button"
                            tooltip
                            textTooltip={messageTooltipButtonValidate}
                        >
                            Validar
                        </ButtonSectionIcon>
                    </div>
                </>
            )}
            <div className="container_mb">
                <Input name="names" label="Primer Nombre" disabled={disabledFieldsStepOne} maxLength={60} />
            </div>
            <div className="container_mb">
                <Input name="second_name" label="Segundo Nombre (opcional)" disabled={disabledFieldsStepOne} maxLength={60} />
            </div>
            <div className="container_mb">
                <Input name="last_names" label="Primer Apellido" disabled={disabledFieldsStepOne} maxLength={60} />
            </div>
            <div className="container_mb">
                <Input
                    name="second_last_name"
                    label="Segundo Apellido (opcional)"
                    disabled={disabledFieldsStepOne}
                    maxLength={60}
                />
            </div>
            <div className="container_mb">
                <DatePickerBase
                    name="birthdate"
                    label="Fecha de nacimiento"
                    placeholderText="DD/MM/AAAA"
                    disabled={disabledFieldsStepOne}
                    maxDate={moment().toDate()}
                />
            </div>
            <div className="container_mb">
                <Input name="email" label="Correo electrónico" disabled={disabledFieldsStepOne} />
            </div>
            {(document_type_id === TypesDocumentIds.CC ||
                document_type_id === TypesDocumentIds.NIT ||
                document_type_id === TypesDocumentIds.TI) && (
                <>
                    <div className="container_mb">
                        <CheckboxGreen
                            name="colombian_resident_abroad"
                            label="Colombiano residente en el exterior"
                            disabled={disabledFieldsStepOne}
                        />
                    </div>
                    <div className="container_mb">
                        <DatePickerBase
                            name="filing_date_in_ext"
                            label="Fecha de radicación en el ext."
                            placeholderText="DD/MM/AAAA"
                            disabled={disabled || disabled_fields || !colombian_resident_abroad || !validateContributor}
                            maxDate={moment().toDate()}
                        />
                    </div>
                </>
            )}
            {internationalTypeOFDocuments.some(
                type => (TypesDocumentIds as { [key: string]: string })[type] === document_type_id
            ) && (
                <CheckboxGreen
                    name="foreigner_not_required_to_pay_pension_contributions"
                    label="Extranjero No Obligado a Cotizar Pensiones"
                    disabled={disabledFieldsStepOne}
                />
            )}
            <div className="padding--link">
                {!createPosition && (
                    <div className="container__flex-field">
                        <SelectInput
                            name="position_id"
                            label="Cargo"
                            selectClassContainer={`container_mb ${
                                position_id && existOption(positions, position_id) ? 'select--max-width' : ''
                            }`}
                            options={positions}
                            disabled={disabledFieldsStepOne}
                            menuPlacement="top"
                            tooltip
                            textTooltip={ToolTipTexts.ROLE}
                        />
                        {position_id && existOption(positions, position_id) && (
                            <div className="action__container">
                                <Icon
                                    name="brownEditPencil"
                                    className="icon--action"
                                    onClick={(): void => {
                                        if (!disabledFieldsStepOne) {
                                            setSelectedElement({ id: position_id, type: TypeData.POSITION });
                                            handleActionElement(TypeData.POSITION, true);
                                        }
                                    }}
                                />
                                <Icon
                                    name="trashBrown"
                                    className="icon--action-delete"
                                    onClick={(): void => {
                                        if (!disabledFieldsStepOne) {
                                            setSelectedElement({ id: position_id, type: TypeData.POSITION });
                                            handleModalDelete();
                                        }
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}
                <AddDetail
                    name="position_id"
                    label="Cargo"
                    disabled={disabledFieldsStepOne}
                    onClick={(): void => handleActionElement(TypeData.POSITION, false)}
                    handleAdd={handleActionPosition}
                    handleCancel={(): void => handleActionElement(TypeData.POSITION, false)}
                    create={createPosition}
                    edit={editElementPosition}
                    message="cargo"
                    id={position_id}
                    options={positions}
                />
            </div>
            <div className="padding--link">
                {!createDepartment && (
                    <div className="container__flex-field">
                        <SelectInput
                            name="department_id"
                            label="Área"
                            selectClassContainer={`container_mb ${
                                department_id && existOption(departments, department_id) ? 'select--max-width' : ''
                            }`}
                            options={departments}
                            disabled={disabledFieldsStepOne}
                            menuPlacement="top"
                            tooltip
                            textTooltip={ToolTipTexts.DEPARTMENT}
                            classTooltipArea="max-width-tooltip"
                        />
                        {department_id && existOption(departments, department_id) && (
                            <div className="action__container">
                                <Icon
                                    name="brownEditPencil"
                                    className="icon--action"
                                    onClick={(): void => {
                                        if (!disabledFieldsStepOne) {
                                            setSelectedElement({ id: department_id, type: TypeData.DEPARTMENT });
                                            handleActionElement(TypeData.DEPARTMENT, true);
                                        }
                                    }}
                                />
                                <Icon
                                    name="trashBrown"
                                    className="icon--action-delete"
                                    onClick={(): void => {
                                        if (!disabledFieldsStepOne) {
                                            setSelectedElement({ id: department_id, type: TypeData.DEPARTMENT });
                                            handleModalDelete();
                                        }
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}
                <AddDetail
                    name="department_id"
                    label="Área"
                    disabled={disabledFieldsStepOne}
                    onClick={(): void => handleActionElement(TypeData.DEPARTMENT, false)}
                    handleAdd={handleActionDepartment}
                    handleCancel={(): void => handleActionElement(TypeData.DEPARTMENT, false)}
                    create={createDepartment}
                    edit={editElementDepartment}
                    message="área"
                    id={department_id}
                    options={departments}
                />
            </div>
            <div className="container_mb">
                {!createCostCenter && (
                    <div className="container__flex-field">
                        <SelectInput
                            name="cost_center_id"
                            label="Centro de costos"
                            selectClassContainer={`container_mb ${
                                cost_center_id && existOption(costCenter, cost_center_id) ? 'select--max-width' : ''
                            }`}
                            options={costCenter}
                            disabled={disabledFieldsStepOne}
                            menuPlacement="top"
                        />
                        {cost_center_id && existOption(costCenter, cost_center_id) && (
                            <div className="action__container">
                                <Icon
                                    name="brownEditPencil"
                                    className="icon--action"
                                    onClick={(): void => {
                                        if (!disabledFieldsStepOne) {
                                            setSelectedElement({ id: cost_center_id, type: TypeData.COST_CENTER });
                                            handleActionElement(TypeData.COST_CENTER, true);
                                        }
                                    }}
                                />
                                <Icon
                                    name="trashBrown"
                                    className="icon--action-delete"
                                    onClick={(): void => {
                                        if (!disabledFieldsStepOne) {
                                            setSelectedElement({ id: cost_center_id, type: TypeData.COST_CENTER });
                                            handleModalDelete();
                                        }
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}
                <AddDetail
                    name="cost_center_id"
                    label="Centro de costos"
                    disabled={disabledFieldsStepOne}
                    onClick={(): void => handleActionElement(TypeData.COST_CENTER, false)}
                    handleAdd={handleActionCostCenter}
                    handleCancel={(): void => handleActionElement(TypeData.COST_CENTER, false)}
                    create={createCostCenter}
                    edit={editElementCostCenter}
                    message="centro de costo"
                    id={cost_center_id}
                    options={costCenter}
                />
            </div>
            {!colombian_resident_abroad && (
                <>
                    <div className="container_mb">
                        <SelectInput
                            name="state_id"
                            label="Departamento"
                            options={departmentsOptions}
                            disabled={disabledFieldsStepOne}
                            menuPlacement="top"
                        />
                    </div>
                    <div className="container_mb">
                        <SelectInput
                            name="city_id"
                            label="Municipio"
                            options={cities.filter(({ country_state_id }) => country_state_id === state_id)}
                            disabled={disabledFieldsStepOne}
                            menuPlacement="top"
                        />
                    </div>
                    <div className="container_mb">
                        <Input name="address" label="Dirección" disabled={disabledFieldsStepOne} menuPlacement="top" />
                    </div>
                </>
            )}
            {colombian_resident_abroad && (
                <div className="container_mb">
                    <SelectInput
                        name="country_id"
                        label="País"
                        options={countryOptions}
                        disabled={disabledFieldsStepOne}
                        menuPlacement="top"
                        isSearchable
                    />
                </div>
            )}
        </div>
    );
};
