// Libraries
import React from 'react';
import ReactPlayer from 'react-player';
// Components
import { Button, ButtonSectionIcon } from '@components/button';
import { ModalInformationImportant } from '../../components';
// Pages
import { CurrentInformation } from '@pages/electronic-payroll/constants/InformationImportant';
import { useEnablePayroll } from '@pages/electronic-payroll/hooks/useEnablePayroll';
import { useModalInformationImportant } from '@pages/electronic-payroll/hooks/useModalInformationImportant';
import { useElectronicPayroll } from '@pages/electronic-payroll/hooks/useElectronicPayroll';
import { CurrentTabs } from '@pages/electronic-payroll/constants/Tabs';
// Utils
import { checkIsSupport } from '@utils/CheckSupport';
// Styles
import './EnablePayroll.scss';
// Assets
import banner from '@assets/logos/buy-electronic-payroll.png';
import { SHOW_FEATURES_VERSION_BETA } from '@constants/Plataform';
import { SOON } from '@constants/OtherTexts';

export const EnablePayroll: React.FC = () => {
    const { plan, activePlan, handleChangeTab, handleBuyPayroll, companyInformation } = useElectronicPayroll();
    const { hasElectronicPayroll, token, handleStartTransmit } = useEnablePayroll();
    const { showModalImportant, currentInformation, optionSelected, handleShowModalImportant, handleSelectTypeInformation } =
        useModalInformationImportant();

    return (
        <div className="enable-payroll">
            <ModalInformationImportant
                isOpen={showModalImportant}
                onRequestClose={handleShowModalImportant}
                handleBack={handleShowModalImportant}
                mainAction={(): void => {
                    if (currentInformation === CurrentInformation.TRANSMISSION_SERVICE) {
                        handleChangeTab(CurrentTabs.TRANSMIT_ELECTRONIC_PAYROLL);
                        handleStartTransmit();
                        handleShowModalImportant();
                    } else {
                        window.open(`${process.env.REACT_APP_ELECTRONIC_PAYROLL_DETAIL}${token}/1`, '_blank', 'noreferrer');
                        handleShowModalImportant();
                    }
                }}
                currentInformation={currentInformation}
                optionSelected={optionSelected}
                handleOption={(): void => {}}
            />
            {!plan.electronic_payroll ? (
                <div className="container__banner">
                    <img src={banner} alt="banner-electronic-payroll" />
                    <div className="container__banner--description">
                        <div className="text-description">
                            Para hacer uso de la herramienta de <span>Nómina Electrónica</span> te invitamos hacer la compra de un
                            nuevo plan en el que se incluya esta funcionalidad
                        </div>
                        <div className="button__container">
                            <ButtonSectionIcon
                                classButtonSectionIconContainer="prueba"
                                onClick={handleBuyPayroll}
                                iconName={SHOW_FEATURES_VERSION_BETA ? 'moneyLightGreen' : 'moneyLightGray'}
                                className={
                                    SHOW_FEATURES_VERSION_BETA ? 'button__container--active' : 'button__container--disabled'
                                }
                                disabled={!SHOW_FEATURES_VERSION_BETA}
                            >
                                {SHOW_FEATURES_VERSION_BETA ? 'Comprar' : SOON}
                            </ButtonSectionIcon>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container__tabs">
                    <div className="container__description">
                        <p className="description--text">
                            Bienvenido al módulo de Nómina Electrónica, a través del cual puedes detallar el resumen de la
                            transmisión de tu nómina electrónica. Una vez compruebes la información puedes confirmar la
                            transmisión.
                        </p>
                        <p className="description--text">
                            Es importante tener en cuenta que cualquier tema relacionado con la modificación, eliminación,
                            consulta de detalles e históricos de las transmisiones, debe ser manejado desde el portal de Nómina
                            Electrónica. Se recomienda mirar el video para obtener más información al respecto.
                        </p>
                        <div className="container__button">
                            <Button
                                disabled={!activePlan?.is_active || checkIsSupport(companyInformation)}
                                className={`${
                                    !activePlan?.is_active ? 'container__buttons--disabled' : 'container__buttons--active'
                                }`}
                                onClick={(): void => {
                                    handleSelectTypeInformation(
                                        CurrentInformation[
                                            hasElectronicPayroll
                                                ? CurrentInformation.TRANSMISSION_SERVICE
                                                : CurrentInformation.ENABLE_SERVICE
                                        ]
                                    );
                                    handleShowModalImportant();
                                }}
                            >
                                Transmitir nómina electrónica
                            </Button>
                        </div>
                    </div>
                    <div className="container__video">
                        <ReactPlayer
                            muted
                            playing
                            url="/videos/Nómina Electrónica_V3.mp4"
                            width="100%"
                            controls
                            data-testid="video-player"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
