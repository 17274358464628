import { IFormatExcel, IHeaderExcel } from '@models/Excel';
import { IUpcEmployee } from '@models/PayrollAdjuster';

/**
 * Reverse an array of objects
 */
export const reverseArrayOfObjects = <T>(data: T[]): T[] => {
    return data?.slice()?.reverse();
};

/**
 * This function return new array of objects with excel format for each row
 * @param {Array<[string, string]>} headerArray - array of strings
 * @returns {Array<[IFormatExcel, IFormatExcel]>}
 */
export const transformArrayToExcelHeader = (headerArray: Array<[string, string]>): Array<[IFormatExcel, IFormatExcel]> => {
    const result: Array<[IHeaderExcel, IFormatExcel]> = [];

    headerArray.forEach(element => {
        const value1 = element[0];
        const value2 = element[1];

        const firstPosition = { v: value1, t: 's', s: { font: { bold: true } } };
        const secondPosition = { v: value2 };
        result.push([firstPosition, secondPosition]);
    });

    return result;
};

/**
 * This function validate if the data is an array
 * @param {T[]} data - Array to validate
 * @returns {boolean}
 */
export const validateIsArray = <T>(data: T[]): boolean => Array.isArray(data);

export const range = (start: number, end: number, step = 1): number[] => {
    if (end === undefined && start !== undefined) {
        end = start;
        start = 0;
    }

    return Array(Math.ceil((end - start) / step))
        .fill(start)
        .map((x, y) => x + y * step);
};

export const mergeArraysUpc = (array1: IUpcEmployee[], array2: IUpcEmployee[]): IUpcEmployee[] => {
    const arrayMerge = array1.map(x => {
        const findElement = array2.find(y => y.id === x.id);
        if (!findElement) return x;
        return findElement;
    });

    const upcMissing = array2.filter(x => !arrayMerge.some(upc => upc.id === x.id));

    return arrayMerge.concat(upcMissing);
};
