// Routes
import { Routes } from '@components/paths';
// Utils
import { getRoute, getRouteName } from '@utils/Paths';
// Models
import { IItems, INavItems, IUseGetNavLinks } from '../models/Navbar';

export const useGetNavItems = (iconWhite?: boolean): IUseGetNavLinks => {
    /**
     * Generate nav links values.
     * @constructor
     * @returns {INavItems[]}
     */
    const generateNavItems = (): INavItems[] => {
        return [
            {
                name: getRouteName(Routes.HOME),
                route: getRoute(Routes.HOME),
                icon: iconWhite ? 'homeNavWhite' : 'homeNavGray',
                activeIcon: 'homeNavGreen',
            },
            {
                name: getRouteName(Routes.EMPLOYEES),
                route: getRoute(Routes.EMPLOYEES),
                icon: iconWhite ? 'employeesNavWhite' : 'employeesNavGray',
                activeIcon: 'employeesGreen',
            },
            {
                name: getRouteName(Routes.PAYROLL_ADJUSTER),
                route: getRoute(Routes.PAYROLL_ADJUSTER),
                icon: iconWhite ? 'payrollAdjusterNavWhite' : 'payrollAdjusterNavGray',
                activeIcon: 'payrollAdjusterGreen',
                subRoutes: [
                    getRoute(Routes.PREMIUM_SERVICES),
                    getRoute(Routes.SEVERANCE_PAY),
                    getRoute(Routes.INTEREST_SEVERANCE_PAY),
                    getRoute(Routes.PAYMENT_SLIPS),
                ],
            },
            {
                name: getRouteName(Routes.PILA),
                route: getRoute(Routes.PILA),
                icon: iconWhite ? 'stackNavWhite' : 'stackNavGray',
                activeIcon: 'stackNavGreen',
            },
            {
                name: getRouteName(Routes.ELECTRONIC_PAYROLL),
                route: getRoute(Routes.ELECTRONIC_PAYROLL),
                icon: iconWhite ? 'electronicPayrollNavWhite' : 'electronicPayrollNavGray',
                activeIcon: 'electronicPayrollNavGreen',
            },
            {
                name: getRouteName(Routes.REPORTS),
                route: getRoute(Routes.REPORTS),
                icon: iconWhite ? 'reportsNavWhite' : 'reportsNavGray',
                activeIcon: 'reportsGreen',
                subRoutes: [
                    getRoute(Routes.PILA_REPORT),
                    getRoute(Routes.PILA_REPORT_CERTIFICATION),
                    getRoute(Routes.PAYROLL_REPORT),
                ],
            },
        ];
    };

    /**
     * Generate profile links values.
     * @constructor
     * @returns {IItems[]}
     */
    const generateProfileItems = (): IItems[] => {
        return [
            {
                name: getRouteName(Routes.COMPANY),
                route: getRoute(Routes.COMPANY),
                icon: iconWhite ? 'buildingMenuWhite' : 'buildingMenu',
            },
        ];
    };

    return {
        generateNavItems,
        generateProfileItems,
    };
};
