// Libraries
import React, { useEffect } from 'react';
// Hooks
import { useActionsLogin } from '@pages/home/hooks/useActionsLogin';
// Components
import { Loader } from '@components/loader';
// Styles
import './OutsidePlatform.scss';

export const OutsidePlatform: React.FC = () => {
    const { handleLoginRedirect } = useActionsLogin();

    useEffect(() => {
        handleLoginRedirect();
    }, []);

    return (
        <div className="outside-platform">
            <Loader isOpen />
        </div>
    );
};
