// Libraries
import React from 'react';
// Styles
import './ModalRejectResponse.scss';

export const ModalRejectResponse: React.FC = () => {
    return (
        <div className="modal-reject-response">
            <div className="modal-reject-response--container">
                <div className="information">
                    <p>No podemos continuar el proceso de registro, por favor contáctanos para detalles del proceso.</p>
                </div>
            </div>
        </div>
    );
};
