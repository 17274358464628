// Libraries
import { useEffect } from 'react';
// Redux
import { RootState } from '@redux/rootReducer';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
// Api
import {
    getCheckEmployerStatus,
    getYearPeriodTransmit,
    setStartTransmit,
} from '@redux/electronic-payroll/electronicPayroll.slice';

export const useEnablePayroll = (): {
    hasElectronicPayroll: boolean;
    token: string;
    startTransmit: boolean;
    handleStartTransmit: () => void;
} => {
    const dispatch = useAppDispatch();
    const {
        electronicPayroll: { startTransmit, hasElectronicPayroll, token },
        session: { loginUser },
    } = useAppSelector((state: RootState) => state);

    useEffect(() => {
        if (loginUser?.lastLogin) {
            Promise.all([dispatch(getCheckEmployerStatus()), dispatch(getYearPeriodTransmit())]);
        }
    }, []);

    /**
     * Action to start transmit from electronic payroll
     */
    const handleStartTransmit = (): void => {
        dispatch(getCheckEmployerStatus(true));
        dispatch(setStartTransmit(true));
    };

    return {
        hasElectronicPayroll,
        token,
        startTransmit,
        handleStartTransmit,
    };
};
