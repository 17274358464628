// Libraries
import React from 'react';
// Components
import { Icon } from '@components/icon';
// Styles
import './InformativeCard.scss';
import { IInformativeCardProps } from './models/InformativeCard';

export const InformativeCard: React.FC<IInformativeCardProps> = ({ children, className, position, handleClose }) => {
    return (
        <div className={`informative-card ${className}`}>
            <div className="close--card">
                <span className="close" onClick={handleClose}>
                    x
                </span>
            </div>
            <div className="informative-card__body">
                <Icon name="informative" type="svg" className="icon--width" />
                <div className={`container--text ${position && `card--${position}`}`}>{children}</div>
            </div>
        </div>
    );
};
