// Libraries
import type { RefObject } from 'react';
import type { EventType } from '@models/EventType';

export const useClickAway = <T extends HTMLElement = HTMLElement>(
    ref: RefObject<T> | RefObject<T>[],
    handler: (event: MouseEvent | TouchEvent) => void,
    eventType: EventType = 'mousedown'
): void => {
    addEventListener(eventType, event => {
        const target = event.target as Node;
        if (!target || !target.isConnected) {
            return;
        }
        const isOutside = Array.isArray(ref)
            ? ref.every(r => r.current && !r.current.contains(target))
            : ref.current && !ref.current.contains(target);
        if (isOutside) {
            handler(event);
        }
    });
};
